import React, { useState, useContext, useReducer, useEffect, useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { toast } from "react-toastify";
import { List, ListItem, ListItemText, Box, TextField, Button, IconButton, useTheme } from "@mui/material";
import MessageDocument from "../Messages/document";
import getSocket from "../../helpers/socket.js";
import { FaUser, FaRobot } from "react-icons/fa";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	colFull: {
		width: '100%',
		display: 'flex'
	},
	colFullColumn: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
		width: '100%',
	},
	answerLoading: {
		minHeight: 200,
		maxHeight: 300,
		width: 300,
		margin: 20,
		borderRadius: 10,
		background: theme.palette.blackWhiteBackground,
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center'
	},
	answerBlock: {
		fontSize: 16,
		lineHeight: '27px',
		padding: 20,
		background: theme.palette.blackWhiteBackground,
		borderRadius: 10,
		overflow: 'auto'
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	circleLoading: {
		color: green[500],
		opacity: "70%"
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	messageSaved: {
		boxShadow: "0 1px 1px #b3b3b3",
		background: "#f5f5f5",
		padding: '5px 15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	cont: {
		fontStyle: "italic",
	},
	timestamp: {
		fontStyle: 'normal',
		fontSize: 11,
		color: '#666666'
	},
	boxMessages: {
		flexGrow: 1,
		overflowY: 'auto',
		border: `1px solid ${theme.palette.divider}`,
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		maxHeight: 600,
		...theme.scrollbarStylesSoft,
		background:theme.palette.blackWhiteBackground,
		borderRadius: 10
	},
	messageInputContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2),
	},
	inputField: {
		flexGrow: 1,
		borderRadius: 10
	},
	answerBlock: {
		margin: theme.spacing(1, 0),
	},
	messageLeft: {
		marginRight: 20,
		marginTop: 2,
		minWidth: 100,
		maxWidth: 600,
		height: "auto",
		display: "block",
		position: "relative",
		background: theme.palette.fancyBackground,
		"&:hover #messageActionsButton": {
			display: "flex",
			position: "absolute",
			top: 0,
			right: 0,
			opacity: 1
		},

		whiteSpace: "pre-wrap",
		//backgroundColor: "#ffffff",
		//color: "#303030",
		alignSelf: "flex-start",
		borderTopLeftRadius: 0,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		padding: 10,
		boxShadow: 0
	},
	messageRight: {
		marginLeft: 20,
		marginTop: 2,
		minWidth: 100,
		maxWidth: 600,
		height: "auto",
		display: "block",
		position: "relative",
		background: theme.mode === 'dark' ? '#010102': '#f5f4f4',
		"&:hover #messageActionsButton": {
			display: "flex",
			position: "absolute",
			top: 0,
			right: 0,
			opacity: 1
		},

		whiteSpace: "pre-wrap",
		//backgroundColor: "#dcf8c6",
		//color: "#303030",
		alignSelf: "flex-end",
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 0,
		padding: 10,
		border: 0
	},
}));

const reducer = (state, action) => {
	if (action.type === "LOAD_RESUME_PDFS") {
		const messages = action.payload;
		const newMessages = [];

		messages.forEach(message => {
			const messageIndex = state.findIndex(m => m.id === message.id);
			if (messageIndex !== -1) {
				state[messageIndex] = message;
			} else {
				newMessages.push(message);
			}
		});

		return [...newMessages, ...state];
	}

	if (action.type === "ADD_RESUME_PDF") {
		const newMessage = action.payload;

		state.push(newMessage);
		return [...state];
	}
};

const PromptResumeDocumentModal = ({ open, onClose, message, icon, handleAIMessage }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [answer, setAnswer] = useState('');
	const { getSettingValue } = useContext(SettingsContext);
	const apiKey = getSettingValue('keyOpenAi');
	const [loading, setLoading] = useState(false);
	const [messages, dispatch] = useReducer(reducer, []);
	const [inputMessage, setInputMessage] = useState('');
	const [currentChatId, setCurrentChatId] = useState(null);
	const messagesEndRef = useRef(null);

	const handleSendMessage = async () => {
		if (inputMessage.trim()) {
			let info = {
				type: 'user',
				body: inputMessage
			}
			dispatch({ type: "ADD_RESUME_PDF", payload: info });
		}
		setLoading(true);
		const response = await api.post('/ai/pdf', {
			apikey: await apiKey,
			userMessage: inputMessage,
			chatId: currentChatId
		});
		console.log(response, 'answered')

		setCurrentChatId(response.data.chatId);
		//setMessages((prevMessages) => [...prevMessages, response.data.reply]);

		let info = {
			type: 'ai',
			body: response.data.reply
		}
		dispatch({ type: "ADD_RESUME_PDF", payload: info });
		setLoading(false);
		setInputMessage('');
	};

	const handleClose = () => {
		onClose();
		setAnswer('');
	};


	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [messages]);





	//Socket

	useEffect(() => {
		const socket = getSocket();

		const connectEvent = () => {
			//console.log('Socket connected');
			//socket.emit("joinChatBox", ticketId);
		}

		const appMessageEvent = (data) => {
			//console.log('Received aipdf event:', data);
			if (data.action === "ADD_RESUME_PDF") {
				let info = {
					type: 'ai',
					body: data.content
				}
				dispatch({ type: "ADD_RESUME_PDF", payload: info });
			}
		}

		socket.on("connect", connectEvent);
		socket.on("aipdf", appMessageEvent);

		if (socket.connected) {
			connectEvent();
		}

		return () => {
			//  socket.emit("leaveChatBox", ticketId);
			socket.off("connect", connectEvent);
			socket.off("aipdf", appMessageEvent);
			//socket.disconnect();

		};
	}, [open]);




	async function resumeDocument() {
		console.log('was clicked to resume');
		if (message.mediaUrl && messages.length === 0) {
			setLoading(true);
			try {
				let data = {
					documentUrl: message.mediaUrl,
					apikey: await apiKey
				}

				const response = await api.post("/ai/pdf", data);
				//console.log(response, 'test pdfs');
				if (response.data) {
					//setMessages([...messages, response.data.reply]);
					//dispatch({ type: "ADD_RESUME_PDF", payload: response.data.reply });
					setCurrentChatId(response.data.chatId);
				} else {
					console.error("Invalid response data:", response.data);
				}
				setLoading(false);
			} catch (err) {
				setLoading(false);
				toast.error(i18n.t("backendErrors.ERR_OPENAI"));
			}
		}
	}


	return (
		<div className={classes.root}>

			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<div className={classes.colFull}>
					<div style={{ width: '100%' }}>
						<MessageDocument message={message} size="full" />
					</div>
					<div className={classes.colFullColumn}>
						<div className={classes.boxMessages}>

							<List>
								{messages.length === 0 ? (
									<>
										<ListItem key={0}>
											<ListItemText primary={loading ? 'Carregando informações...' : 'Nenhuma mensagem ainda.'} />
										</ListItem>
										{!loading &&
											<div style={{ display: 'flex', gap: 10, background: 'rgba(0,0,0,0.1)', borderRadius: 10, padding: '5%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
												<h5>Resuma os pontos principais do arquivo</h5>
												<Button variant="outlined" onClick={() => resumeDocument()}>RESUMIR DOCUMENTO</Button>
											</div>}
									</>
								) : (
									<>
										{messages.map((msg, index) => (
											<ListItem key={index}>
												{msg?.type === 'user' ? (
													<ListItemText
														className={classes.messageRight}
														primary={(
															<span  style={{
																color:theme.mode === 'dark' ? '#fff': '#000'
																}}>
																<IconButton>
																	<FaUser size={16} color={theme.mode === 'dark' ? '#fff': '#000'} />
																</IconButton> {msg.body}
															</span>
														)}
													/>
												) : (
													<ListItemText
														className={classes.messageLeft}
														primary={(
															<span style={{																
																color:theme.mode === 'dark' ? '#fff': '#000'
																}}>
																<IconButton>
																	<FaRobot size={16} color={theme.mode === 'dark' ? '#fff': '#000'} />
																</IconButton>
																{msg.body}
															</span>
														)}
													/>
												)}
											</ListItem>
										))}


										{loading &&
											<ListItem key={656}>
												<ListItemText primary={'Carregando informações...'} />
											</ListItem>}
									</>

								)}
								<div ref={messagesEndRef} />
							</List>

						</div>
						<Box className={classes.messageInputContainer}>
							<TextField
								className={classes.inputField}
								label="Faça uma pergunta"
								variant="outlined"
								value={inputMessage}
								style={{ borderRadius: 10 }}
								onChange={(e) => setInputMessage(e.target.value)}
								onKeyPress={(e) => {
									if (e.key === 'Enter') handleSendMessage(); // Send on pressing Enter
								}}
								disabled={loading || messages.length < 1}
							/>
							{messages.length === 0 ? (
								<Button variant="contained" color="primary" onClick={() => resumeDocument()} disabled={loading}>
									Iniciar
								</Button>) :
								<Button variant="contained" color="primary" onClick={handleSendMessage} disabled={loading}>
									Enviar
								</Button>}
						</Box>
					</div>
				</div>
			</Dialog>
		</div>
	);
};

export default PromptResumeDocumentModal