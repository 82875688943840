import React, { useEffect } from "react";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { Badge, Avatar, Typography, ListItemAvatar, ListItemText, ListItem, IconButton, Tooltip, Menu, MenuItem, Button } from '@mui/material';
import { i18n } from "../../../translate/i18n";
import MarkdownWrapper from "../../MarkdownWrapper";
import TicketTime from "../../TicketTime";
import ContactTag from "../../ContactTag";
import ConnectionIcon from "../../ConnectionIcon";
import { AiOutlineUser } from "react-icons/ai";

const useStyles = makeStyles(theme => ({
    userTag: {
        background: 'transparent',
        fontWeight: 700,
        borderRadius: 20,
        marginRight: 0,
        padding: 1,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 3,
        fontSize: "0.8em",
        whiteSpace: "nowrap"
    },
}));

const MessageBlock = (item) => {
    const classes = useStyles();
    const message = item?.message;
    useEffect(() => {
        console.log(message, 'message test')
    }, [])
    return (
        <ListItem dense button className={`${classes.listItem}`}>
            <div className={clsx(classes.message, classes.listItemTicket, {
                [classes.pendingTicket]: message.status === "pending",
            })} style={{ display: 'flex', width: '100%', gap: 10, alignItems: 'center' }}>

                <div>
                    <small>Atendimento</small>
                    <h4>#{message.ticketId}</h4>

                </div>
                <div style={{ display: 'flex', maxWidth: '50%', alignItems: 'center' }}>
             
                    <ListItemText
                        disableTypography
                        primary={
                            <div className={classes.contactNameWrapper}>
                                <Typography noWrap component="span" variant="body2" color="textPrimary">
                                    {message.contact ? message.contact.name : 'Mensagem'}
                                </Typography>
                                {message.status === "closed" && (
                                    <Badge className={classes.closedBadge} badgeContent={"Resolvido"} color="primary" />
                                )}
                            </div>
                        }
                        secondary={
                            <>
                                <span className={classes.secondaryContentWrapper}>
                                    <span className={classes.secondaryContentFirst}>

                                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                       
                                            <Typography
                                                className={classes.contactLastMessage}
                                                noWrap
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {message.body ? (
                                                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                                                ) : (
                                                    <br />
                                                )}
                                            </Typography>
                                        </div>
                                    </span>
                                </span>
                            </>
                        }
                    />
                </div>

                
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <span className={classes.ConnectionTime}>
                        {message.body && (
                            <Typography variant="body2" color="textSecondary">
                                {isSameDay(parseISO(message.updatedAt), new Date())
                                    ? format(parseISO(message.updatedAt), "HH:mm")
                                    : <TicketTime updatedAt={message.updatedAt} />}
                            </Typography>
                        )}
                    </span>
                    {message.user && (
                        <div className={classes.blockUser}>
                            <div className={classes.userTag}>
                                <AiOutlineUser /> {message.user.name}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ListItem>
    );
};

export default MessageBlock;
