import React, { useState, useEffect } from "react";
import TypebotComponent from "./typebot";
import makeStyles from '@mui/styles/makeStyles';
import Bot from "../../pages/Bots/old";
import TabPanel from "../../components/TabPanel";
import { i18n } from "../../translate/i18n";
import { Box, Tab, Tabs } from "@mui/material";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.fancyBackground,
        padding: 15,
        '& h2': {
            color: theme.palette.dark.main
        }
    },
    tabLabel: {
        color: theme.palette.text.primary,
    },
    mainTabs: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const Typebot = () => {
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [typebotVisible, setTypebotVisible] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchInfo = async () => {
                try {
                    
                    const { data } = await api.get("/info");
                    if (data.typebotEnabled) {
                        setTypebotVisible(true);
                    }
                } catch (err) {
                    toastError(err);
                }
            };
            fetchInfo();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    return (
        <div>
             <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={classes.mainTabs}>
                <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} aria-label="basic tabs example" className="tabBlock">
                    <Tab className={classes.tabLabel} label={i18n.t("bots.old")} />
                    {typebotVisible && <Tab className={classes.tabLabel} label={i18n.t("bots.typebot.title")} />}
                </Tabs>
            </Box>
            <TabPanel value={tab} name={0}>
                <Bot />
            </TabPanel>
            {typebotVisible && <TabPanel value={tab} name={1}>
                <TypebotComponent />
            </TabPanel>}
        </div>
    );
};

export default Typebot;