import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, Typography, IconButton, Avatar, Chip, Stack, Box, Badge } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CommentIcon from '@mui/icons-material/Comment';
import * as moment from "moment";
import api from "../../services/api";
import { useHistory } from 'react-router-dom';
import { FaTrash } from "react-icons/fa";

const useStyles = makeStyles(theme => ({
  iconOrder: {
    color: theme.palette.text.main
  },
  iconButtonRemove: {
    position: 'absolute',
    right: '5%',
    bottom: '15%'
  }
}));

function TaskListItem({ tasks, onHide }) {
  const classes = useStyles();
  const history = useHistory();

  const handleTypeNotification = (item) => {
    console.log(item, 'item test')
    if (item === 'create') {
      return 'Criado por'
    }
    if (item === 'transfer') {
      return 'Atribuido'
    }

    if (item === 'transfer') {
      return 'Atribuido'
    }
    if (item === 'comment') {
      return 'Comentário novo por'
    }
  }

  const formatDate = (value) => {
    return value ? moment(value).format("DD/MM/YYYY à[s] HH:mm") : '';
  }
  const setUnreadMessages = async (id) => {
    try {
      const response = await api.put(`/tasks/notification/${id}`);
    } catch (err) {
      console.log(err)
    }
  }
  const handleGoTask = async (id, isRead, taskId) => {
    let response;
    !isRead && setUnreadMessages(id);
    console.log(taskId, 'test taskId teste', isRead, response)
    history.push(`/task/${taskId}`)
    onHide();
  }

  const handleRemoveNotification = async (id) => {
    await api.delete(`/task/notification/${id}`);
    
  }

  return (
    <List className="taskListItem" sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {tasks && tasks.length >= 1 && tasks.map((task) => (
        <Accordion key={task.id} expanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
            onClick={() => handleGoTask(task.id, task.read, task.taskId)}>
            <Stack direction="column" spacing={2} alignItems="flex-start" style={{ width: '85%' }}
            >
              <Stack direction="row" alignItems="center"
                justifyContent="space-between" style={{ width: '100%', marginBottom: 0 }}>
                {/*<Avatar sx={{ bgcolor: 'primary.main' }}>{task.title[0]}</Avatar>*/}
                <Typography variant="subtitle4">{task.title}</Typography>
                {!task.read && <Badge badgeContent={1} color="primary"></Badge>}
              </Stack>
              <Typography variant="caption" color="text.secondary" style={{ margin: 0 }}>
                {formatDate(task.updatedAt)}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {task.user && task.toUser &&
              <Stack direction="column" spacing={1} alignItems="left">
                <Stack direction="row" spacing={1} alignItems="center">
                  <AssignmentIcon color="action" fontSize={'small'} />
                  <Typography variant="caption">
                    {handleTypeNotification(task.type)} {task.user.name}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip key={task.toUser.id} label={task.toUser.name} variant="outlined" size="small" />
                </Stack>
              </Stack>
            }
            {task.comments && (
              <Box mt={2}>
                {task.comments.map((comment, index) => (
                  <ListItem key={index} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Avatar>{comment.user[0]}</Avatar>
                    <Box ml={2}>
                      <Typography variant="body3" fontWeight="bold">{comment.user}</Typography>
                      <Typography variant="body3" color="text.secondary">{comment.timeAgo}</Typography>
                      <Typography variant="body3" mt={1}>{comment.message}</Typography>
                    </Box>
                  </ListItem>
                ))}
              </Box>
            )}
            {task.oldStatus && task.newStatus && (
              <Stack direction="row" spacing={1} alignItems="center" mt={2}>
                <CommentIcon color="action" />
                <Typography variant="body2">Status</Typography>
                <Chip label={task.branch} color="secondary" size="small" />
                <Chip label={task.status} color="success" size="small" />
              </Stack>
            )}

            <IconButton className={classes.iconButtonRemove} onClick={() => handleRemoveNotification(task.id)}>
              <FaTrash size={13} className={classes.iconOrder} />
            </IconButton>
          </AccordionDetails>
        </Accordion>
      ))}
    </List>
  );
}

export default TaskListItem;
