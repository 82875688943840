import { Chip, useTheme, Modal, Box } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { FaTimes } from "react-icons/fa";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import api from "../../services/api";
import HelpComponent from "./help";
import { IconButton, Paper, Typography, Divider, InputBase, Menu, MenuItem } from '@mui/material';


const useStyles = makeStyles(theme => ({
    blockModal: {
        background: theme.palette.fancyBackground
    },
    buttonModal: {
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    },
    assistant: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        zIndex: 9
    },
    boxImg: {
        width: 60,
        borderRadius: 100,
        height: 60,
        overflow: 'hidden',
        cursor: 'pointer',
        background: '#fff',
        boxShadow: '0px 8px 12px 0px rgba(0,0,0,0.2)',
        display: 'flex',
        alignItems: 'end'
    },
    boxModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        boxShadow: '0 0 1em rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        padding: 10,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            textAlign: 'center',
            height: '100%',
            minWidth: 'auto'
        }
    },
    listInline: {
        display: 'flex',
        listStyle: 'none',
        gap: 10,
        marginLeft: 0,
        paddingLeft: 0
    },
    boxContent: {
        padding: '5%', 
        maxWidth: 600,
        maxHeight: '40vh',
        ...theme.scrollbarStylesSoft,
        borderRadius: 10,
        marginTop: 10,
        overflow: 'auto'
    }
}));

const AiAssistant = ({ section, id, onChange, currentColor, handleClose, open }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const { getSettingValue } = useContext(SettingsContext);
    const [selectedColor, setSelectedColor] = useState(currentColor);
    const apiKey = getSettingValue('keyOpenAi');
    const [searchOn, setSearchOn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('');
    const [onboarding, setOnboarding] = useState([]);
    const [infoHelp, setInfoHelp] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [hideAssistant, setHideAssistant] = useState(false);
    const [modalIcon, setModalIcon] = useState(false);
    const [robot, setRobot] = useState('codinho.gif');

    const listIcons = [
        'codinho.gif',
        'codinho2.gif',
        'robot.gif',
        'robot2.gif',
        'robot3.gif',
        'robot4.gif',
    ]

    const handleSearchOn = () => {
        setSearchOn(!searchOn)
        setAnswer('');
        setMode('');
        setInfoHelp(null);
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null); // Close the menu
    };

    const handleHideAssistent = () => {
        handleSearchOn();
        setHideAssistant(true);
    };

    useEffect(() => {
        const handleOnboarding = async values => {
            try {
                setLoading(true);
                const response = await api.get(`/ai/assistant/onboarding/${section}`);
                if (response) {
                    setOnboarding(response.data.buttons)
                }
                setLoading(false);

            } catch (err) {
                setLoading(false);
                toast.error(i18n.t("backendErrors.ERR_OPENAI"));
            }
        };

        handleOnboarding();
    }, [searchOn])

    const handleAI = async values => {
        try {
            let data = {
                section,
                text: question,
                apikey: apiKey
            }
            setLoading(true);
            const response = await api.post("/ai/prompt", data);
            if (response) {
                setAnswer(response.data.choices[0].message.content)
            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            toast.error(i18n.t("backendErrors.ERR_OPENAI"));
        }
    };


    const handleHelp = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/ai/assistant/help/${section}`);
            console.log(response, 'help')
            if (response) {
                setInfoHelp(response.data)

            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            toast.error(i18n.t("backendErrors.ERR_OPENAI"));
        }
    };

    const handleStats = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/ai/assistant/stats/${section}/${id}`);
            //console.log(response.data, 'help', section, id)
            if (response) {
                setAnswer(response.data.choices[0].message.content)
            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            toast.error(i18n.t("backendErrors.ERR_OPENAI"));
        }
    };
    const handleClickChip = (item) => {
        if (item === 'question') {
            setMode(item);
        }
        if (item === 'help') {
            handleHelp();
        }

        if (item === 'stats') {
            handleStats();
        }
    }

    useEffect(() => {
        const getIconHelp = () => {
            const type = localStorage.getItem("iconHelp");
            if (!robot || type !== robot && type) {
                setRobot(type);
            }
        }
        getIconHelp();
    }, [])

    const handleChangeIcon = () => {
        setModalIcon(!modalIcon);
        setAnchorEl(null);
    }

    const handleSaveIcon = (item) => {
        setRobot(item);
        handleChangeIcon();
        localStorage.setItem("iconHelp", item);
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };


    return (
        <div className={classes.assistant}>
            <Modal
                open={modalIcon}
                onClose={(event, reason) => {
                    handleChangeIcon();
                }}>
                <Box className={classes.boxModal} display="flex" justifyContent="center" alignItems="center">
                    <ul className={classes.listInline}>
                        {listIcons.map((item) => (
                            <li key={item}>
                                <img
                                    src={`/icons/assistant/${item}`}
                                    style={{ maxHeight: 50 }}
                                    onClick={() => handleSaveIcon(item)}
                                    alt={item}
                                />
                            </li>
                        ))}
                    </ul>
                </Box>
            </Modal>
            {searchOn &&
                <div className={classes.boxInput}>
                    <Paper
                        component="form"
                        sx={{ p: '10px 5px', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', boxShadow: 'none' }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={handleChangeIcon}>Mudar Icone</MenuItem>
                            <MenuItem onClick={handleHideAssistent}>Sair do Assistente</MenuItem>
                        </Menu>
                        {infoHelp && <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                            <h5>Ajuda</h5>
                        </div>}
                        {answer && <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                            <h5>Resultado</h5>
                        </div>}
                        {mode !== 'question' && !infoHelp && !answer &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                <h5>Olá, sou seu assistante! Em que posso ajudar? </h5>
                                <div style={{ display: 'flex', gap: 4 }}>
                                    {onboarding && onboarding.length >= 1 && onboarding.map((item, index) => (
                                        <Chip label={item.label} key={index} onClick={() => handleClickChip(item.value)} />
                                    ))
                                    }
                                </div>
                            </div>
                        }
                        {mode === 'question' &&
                            <>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Me pergunte algo..."
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {e.preventDefault(); setQuestion(e.target.value)}}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleAI}>
                                    <SearchIcon />
                                </IconButton>
                            </>}
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: '10px' }}
                            onClick={handleSearchOn}
                            aria-label="directions">
                            <FaTimes />
                        </IconButton>
                    </Paper>
                    {loading &&
                        <Paper className={classes.boxContent}>
                            <div className={`loader ${theme.mode === 'dark' ? 'loader-dark' : 'loader-light'}`}></div>
                        </Paper>}
                    {answer &&
                        <Paper className={classes.boxContent}>
                            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: answer }} />
                        </Paper>}

                    {infoHelp && <HelpComponent infoHelp={infoHelp} />}
                </div>}
            {!hideAssistant &&
                <div className={classes.boxImg} onClick={handleSearchOn}>
                    <img src={`/icons/assistant/${robot}`} style={{ width: '100%' }} />
                </div>}
        </div>
    );
};

export default AiAssistant;
