import React, { Fragment, useState, useEffect } from 'react';
//import AsyncSelect from '../AsyncSelect';
//import { Checkbox, ListItemText } from "@mui/material";
import api from '../../services/api';
import { i18n } from "../../translate/i18n";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import BootstrapInput from '../Input/BootstrapInput';
import usePrevious from '../../hooks/usePrevious';
/*import { FaCirclePlay, FaEye, FaFileContract, FaLock, FaTags, FaUser } from "react-icons/fa6";
import { IoReloadCircleSharp } from "react-icons/io5";
import { FaCog } from "react-icons/fa";*/


const SelectTypeLogs = ({ onChange,  internalSelectedQueues, disabled, selectedInitial, i18n }) => {


    let label = 'Categoria';
    let initialValue;
    let statuses;
    let defaultValue = { selectedInitial };
    let multiple = true;
    let width = '100%';
    let renderOption;
    let dictKey;
    let renderTags;
    let limitOptions;
    let multiline = false;
    let disableCloseOnSelect;
    let small;
    let addFirstOption;
    let includeContactsCount;
    let selectFirstIfOnlyOne;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [query, setQuery] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const prevFilter = usePrevious({ pageNumber, query });
    const [internalValue, setInternalValue] = useState(initialValue || multiple ? [] : null);
    const loading = open && options && options.length === 0;
    let debounce = null;

    const labelOffset = -6;
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        let active = true;
        const delayDebounceFn = setTimeout(() => {

            const fetchOptions = async () => {
                let params = { pageNumber };
             
                const response = await api.get('/logs/category', { params });
                if (active) {
                    let data = response.data.categories;
                    // Check if initialValue is on the list
                    let localOptions;
                    if (dictKey) {
                        localOptions = data[dictKey];
                        //console.log('localdata',  data)
                    } else {
                        localOptions = data;
                    }

                    if (addFirstOption) {
                        localOptions = [addFirstOption, ...localOptions];
                    }

                    if (prevFilter && prevFilter.pageNumber != pageNumber) {
                        localOptions = [...options, ...localOptions];
                    }
                    setOptions(localOptions);
                }
            }
            fetchOptions();
            if (statuses) {
                setOptions(statuses);
            }
        }, 500);

        return () => {
            active = false;
            clearTimeout(delayDebounceFn);
        };
    }, [query, open, pageNumber]);

    useEffect(() => {
        setPageNumber(1);
    }, [query, open]);

    useEffect(() => {
        if (initialValue) {
            setInternalValue(initialValue);
            onChange(null, initialValue);
        }
    }, [initialValue]);


    useEffect(() => {
        if (renderOption) {
            //  console.log('options', options)
        }
    }, [renderOption]);


    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (options.length === 1 && selectFirstIfOnlyOne) {
            setInternalValue(options[0]);
            onChange(null, options[0]);
        }
    }, [options]);

    const onInputChange = (event, value) => {
        if (debounce) {
            clearTimeout(debounce);
        }
        debounce = setTimeout(() => {
            setQuery(value);
        }, 500);
    }

    const loadMore = () => {
        setPageNumber(pageNumber + 1);
    }

    const handleFilterOptions = (options, { inputValue }) => {
        return options.filter(option => {
            if (!inputValue) return true;
            if (option && option.key && inputValue && option.key.toLowerCase().includes(inputValue.toLowerCase())) {
                return true;
            }
            if (option && option.number && inputValue && option.number.toLowerCase().includes(inputValue.toLowerCase())) {
                return true;
            }
            return false;
        });
    }

    const fetchOptions = async () => {
        try {
            const response = await api.get(`/logs/category`);
            if (response.data.categories) {
                setOptions(response.data.categories)
            }
            //console.log(response.data.categories, 'test');
            return response.data;
        } catch (error) {
            console.error("Error fetching options:", error);
            return [];
        }
    };


    const handleInputChange = () => {
        return fetchOptions();
    };

    const handleChange = (event, changedUsers) => {
        console.log(changedUsers, 'foi')
        setInternalValue(changedUsers);
        if (onChange) {
            onChange(changedUsers);
        }
    };

    /*const renderIcon = (item) => {
        switch (item) {
            case 'auth':
                return <FaLock color="#c6c6c6" />;
            case 'views':
                return <FaEye color="#c6c6c6" />;
            case 'tags':
                return <FaTags color="#c6c6c6" />;
            case 'contact':
                return <FaFileContract color="#c6c6c6" />;
            case 'user':
                return <FaUser color="#c6c6c6" />;
            case 'setting':
                return <FaCog color="#c6c6c6" />;
            case 'permission':
                return <FaLock color="#c6c6c6" />;
            case 'actions':
                return <FaCirclePlay color="#c6c6c6" />;
            case 'schedule':
                return <IoReloadCircleSharp color="#c6c6c6" />;
            default:
                return null;
        }
    };*/

    return (
        <>



            <Fragment>

                <Autocomplete
                    multiple={multiple}
                    id={`autocomplete-${dictKey}`}
                    style={{ width: width || 500 }}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    onFocus={() => {
                        setFocused(true);
                    }}
                    onBlur={() => {
                        setFocused(false);
                    }}
                    disableCloseOnSelect={disableCloseOnSelect ? disableCloseOnSelect : undefined}
                    value={internalValue}
                    defaultValue={defaultValue}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    options={options}
                    loading={loading}
                    input={small ? <BootstrapInput /> : undefined}
                    onChange={(e, value) => handleChange(e, value)}
                    onInputChange={handleChange}
                    filterOptions={handleFilterOptions}
                    // renderOption={renderOption ? renderOption : undefined}
                    renderTags={renderTags ? renderTags : undefined}
                    ListboxProps={{
                        onScroll: (event) => {
                            if (loading) return;
                            const { scrollTop, scrollHeight, clientHeight } = event.target;
                            if (scrollTop + clientHeight === scrollHeight) {
                                loadMore();
                            }
                        }
                    }}
                    renderInput={(params) => (

                        <>
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        ...(!focused && { top: `${labelOffset}px` }),
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        padding: '2px 10px'
                                    },
                                    endAdornment: (
                                        <Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </Fragment>
                                    ),
                                }}

                            />
                        </>
                    )}
                />
            </Fragment>
        </>
    );
};

export default SelectTypeLogs;