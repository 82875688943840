import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "../../../context/Auth/AuthContext";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { i18n } from "../../../translate/i18n";
import toastError from "../../../errors/toastError";
import FormTask from "../FormTask";

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 'none',
        background: theme.palette.fancyBackground,
        ...theme.scrollbarStylesSoft,
        borderRadius: 10
    },
}));

const AddTaskTicketModal = ({ open, onClose, taskId, isModal, message, ticket, onSave, noteCreated, column, board }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);
    const [initialState, setInitialState] = useState({
        content: "",
        title: "",
        userId: user.id,
        ticketId: ticket?.id,
        contactId: message?.contact?.id || null,
        isAudio: false,
        medias: null,
        color: ''
    });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        onClose();
        setInitialState({});
    };


    return (
        <div className={classes.root}>
            {isModal ?
                <Dialog open={open} onClose={handleClose}
                    maxWidth={false}
                    scroll="paper"
                    classes={{ paper: classes.dialogPaper }}
                >
                    <div>
                        <DialogTitle id="form-dialog-title">
                            {taskId
                                ? `${i18n.t("addTaskModal.title.edit")}`
                                : `${i18n.t("addTaskModal.title.add")}`}
                        </DialogTitle>
                        <FormTask
                            ticket={ticket}
                            mainTaskId={taskId}
                            message={message}
                            onSave={onSave}
                            onClose={handleClose}
                            column={column}
                            board={board}
                        />
                    </div>
                </Dialog>
                : <FormTask />}
        </div>
    );
};

export default AddTaskTicketModal;