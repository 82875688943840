import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/Auth/AuthContext";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import {
    Avatar,
    Checkbox,
    DialogActions,
    DialogContent,
    Button,
    IconButton,
    CircularProgress,
    Dialog,
    DialogContentText,
    DialogTitle,
    AvatarGroup,
    Tooltip
} from "@mui/material";
import { MdOutlineLowPriority } from "react-icons/md";
import { Circle } from '@uiw/react-color';
import { i18n } from "../../../translate/i18n";
import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import MicRecorder from "mic-recorder-to-mp3";
import SelectUsers from "../../SelectUsers";
import EditableH1 from "./editableText";
import QuillEditorField from "./textEditor";
import CommentBox from "./commentBox";
import SelectBoard from "./selectBoard";
import SelectColumns from "./selectColumn";
import { DatePicker } from 'rsuite';
import { FaCalendarAlt, FaClock, FaTimes, FaUser } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { TbReload } from "react-icons/tb";
import { useHistory } from 'react-router-dom';
import TicketBlock from "./ticketBlock";
import MessageBlock from "./messageBlock";
import { AttachFile, UploadFile } from "@mui/icons-material";
import { it } from "date-fns/locale";
import UserAvatarGroup from "./avatarGroup";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });


const useStyles = makeStyles(theme => ({
    AddMargin: {
        marginTop: 10,
        marginBottom: 10
    },
    boxFiles: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    iconText: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        color: theme.palette.text
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column"
    },
    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },
    uploadInput: {
        display: "none"
    },
    textareaField: {
        marginRight: theme.spacing(1),

        width: '100%',
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    messageSaved: {
        boxShadow: "none",
        background: theme.palette.blackWhiteBackground,
        padding: '5px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 5,
        border: 0
    },
    cont: {
        fontStyle: "italic",
    },
    timestamp: {
        fontStyle: 'normal',
        fontSize: 11,
        color: theme.palette.text.main
    },
    label: {
        fontSize: 13
    },
    labelLink: {
        textDecoration: 'underline',
        cursor: 'pointer !important',
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        '& span': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        }
    },
    datePickerField: {
        width: '100%'
    },
    gridOptions: {
        gap: 5,
        //maxWidth: 320,
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        background: theme.mode === 'dark' ? 'rgba(255,255,255,0.02)' : 'rgba(0,0,0,0.05)',
        //padding: 15,
        boxShadow: 'inset 0px 0px 30px 0px rgba(0,0,0,0.05)',
        borderRadius: 15,
        border: 0
    },
    divOptions: {
        [theme.breakpoints.up('lg')]: {
            //  maxWidth: '30%',
        },
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        flexDirection: 'column',
        width: '100%'
    },
    gridColOne: {
        display: 'flex',
        padding: 15,
        borderRight: '2px solid rgba(0, 0, 0, 0.08)',
        gap: 10,
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    gridColTwo: {
        display: 'flex',
        padding: 15,
        borderRight: '2px solid rgba(0, 0, 0, 0.08)',
        gap: 10,
        flexDirection: 'column'
    },
    gridColLast: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'flex-end',
        padding: 15,
        gap: 10,
        flexDirection: 'column'
    },
    content: {
        width: '100%'
    },
    listAttachments: {
        display: 'flex',
        alignItems: 'center',
        listStyle: 'none',
        gap: 5
    },
    textLink: {
        textDecoration: 'underline',
        cursor: 'pointer !important',
        color: theme.palette.text.main,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        '& span': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        }
    },

}));

const ContactSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, "Titulo Muito Curto!")
        .max(200, "Titulo Muito Longo!")
        .required("Titulo é Obrigatório"),
    content: Yup.string().min(3, "Muito Pequeno!"),
});

const FormTask = ({ ticket, taskId, mainTaskId, message, onSave, onClose, column, board }) => {
    const [modeView, setModeView] = useState(false);
    const classes = useStyles();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);
    const fileRef = useRef(null);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [initialState, setInitialState] = useState({
        content: "",
        title: "",
        userId: user.id,
        userIds: null,
        ticketId: ticket?.id,
        contactId: message?.contact?.id || null,
        isAudio: false,
        medias: null,
        validateAt: null,
        color: '',
    });
    const [isAudio, setIsAudio] = useState(false);
    //const [selectedFile, setSelectedFile] = useState(null);
    const [boardId, setBoardId] = useState(board ? board : undefined);
    const [userInfo, setUserInfo] = useState(null);
    const [changeUser, setChangeUser] = useState(false);
    const [colorDefault, setColorDefault] = useState('');
    const [priorityColor, setPriority] = useState(false);
    const [changeContent, setChangeContent] = useState(false);
    const [changeDeadline, setChangeDeadline] = useState(false);
    const [selectedBoardId, setSelectedBoardId] = useState(null);
    const [selectedColumnId, setSelectedColumnId] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [messageInfo, setMessageInfo] = useState(null);
    const [ticketInfo, setTicketInfo] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const apiURL = process.env.REACT_APP_BACKEND_URL;

    //File
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleBoardChange = (boardId) => {
        setSelectedBoardId(boardId);
    };

    const handleColumnChange = (columnId) => {
        // console.log('test column', columnId)
        setSelectedColumnId(columnId);
    };

    const locale = {
        sunday: 'Dom',
        monday: 'Seg',
        tuesday: 'Ter',
        wednesday: 'Qua',
        thursday: 'Qui',
        friday: 'Sex',
        saturday: 'Sáb',
        ok: 'OK',
        today: 'Hoje',
        yesterday: 'Ontem',
        last7Days: 'Últimos 7 dias',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
        last30Days: 'Últimos 30 dias',
        thisMonth: 'Esse mês',
        lastMonth: 'Último mês',
        thisYear: 'Esse Ano',
        lastYear: 'Último Ano',
        formattedMonthPattern: 'MMM, YYYY',
        formattedDayPattern: 'DD MMM, YYYY'
    };


    //Edit Text
    const [isEditing, setIsEditing] = useState(false);
    const handleBlur = (onChange, value) => {
        setIsEditing(false);
        if (onChange) {
            onChange(value);
        }
    };
    //Edit Text

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);



    useEffect(() => {
        //console.log('passed in here', open, taskId)
        const getTask = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/task/${taskId}`);
                if (response.data?.task) {
                    // console.log(response, 'test')
                    setInitialState(response.data.task);
                    setUserInfo(response.data.task.users);
                    setTicketInfo(response.data.task.ticket);
                    setMessageInfo(response.data.task.message);
                    response.data.task.color && setColorDefault(response.data.task.color);
                    setModeView(true);
                    setAttachments(response.data.task.attachmentUrls)
                }

            } catch (err) {
                console.log(err)
            }
            setLoading(false);
        }

        taskId && !mainTaskId && getTask();


    }, [taskId])

    useEffect(() => {
        setInitialState(prevState => ({
            ...prevState,
            userId: user.id,
            ticketId: ticket?.id,
            contactId: message?.contact?.id || null,
        }));
    }, [message, user, ticket]);


    const showFilePicker = () => {
        fileRef?.current && fileRef.current.click();
    }

    /*const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setIsAudio(false);
    };*/

    const handleClose = () => {
        if (onClose) {
            onClose();
        } else {
            history.goBack()
        }
        setInitialState({});
    };

    const handleSaveTask = async (values) => {
        try {
            if (!taskId) {
                const formData = new FormData();
                //console.log(values, 'values', column, 'test column')
                formData.append('title', formik.values.title);
                formData.append('content', formik.values.content);
                formData.append('userIds', formik.values.userIds);
                selectedColumnId && formData.append('columnId', selectedColumnId);
                column && formData.append('columnId', column.toString());
                formData.append('boardId', selectedBoardId || boardId);
                ticket && ticket.id && formData.append('ticketId', ticket.id ? ticket.id : null);
                message?.contact?.id && formData.append('contactId', message.contact.id);
                message?.id && formData.append('messageId', message.id);
                formData.append('isAudio', isAudio)
                formData.append('color', formik.values.color);
                formData.append("deadline", formik.values.validateAt ? formik.values.validateAt : null);
                if (selectedFile) {
                    formData.append('medias', JSON.stringify(selectedFile));
                    // console.log('was clicked selected', selectedFile);
                }
                const { data } = await api.post(`/tasks/add`, formData);
                if (onSave) {
                    onSave(data);
                }
                handleClose();
                toast.success(String(i18n.t('addTaskModal.success')));

                if (ticket) {
                    console.log(data, 'createad')
                    history.push(`/task/${data.task.id}`)
                }
            } else {
                const formData = new FormData();
                console.log(values, 'values', column, 'test column')
                const [type, result] = Object.entries(values)[0];
                formData.append('type', type);
                formData.append('result', result);

                const { data } = await api.put(`/task/${taskId}`, formData);
                if (onSave) {
                    onSave(data);
                }
                //console.log(type, result, 'results', data)
            }
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };


    const handleRemove = async () => {
        ///console.log(laneId, 'test current')
        await api.delete(`/task/${taskId}`);
        handleClose();
        handleCloseConfirm();
        toast.success('Tarefa deletada com sucesso!');
    };

    const handleClickRemoveOpen = () => {
        setOpenConfirmDelete(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirmDelete(false);
    };


    const formatToISOString = (date) => {
        const pad = (number) => String(number).padStart(2, '0');
        return `${date.getUTCFullYear()}${pad(date.getUTCMonth() + 1)}${pad(date.getUTCDate())}T${pad(date.getUTCHours())}${pad(date.getUTCMinutes())}${pad(date.getUTCSeconds())}Z`;
    };

    const addEventToGoogleCalendar = () => {
        const baseUrl = "https://calendar.google.com/calendar/u/0/r/eventedit";
        const title = formik.values.title;
        const startDate = formatToISOString(new Date(formik.values.validateAt));// Format: YYYYMMDDTHHMMSSZ
        const endDate = formatToISOString(new Date(formik.values.validateAt));
        const details = formik.values.content;
        const location = "Onecode";

        const eventUrl = `${baseUrl}?dates=${startDate}/${endDate}&text=${title}&details=${details}`;

        window.open(eventUrl, "_blank");
    };


    const timestamp = message && new Date(message.createdAt);
    const formattedTime = message && timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedDate = message && timestamp.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const formattedDateTime = message && `${formattedDate} - ${formattedTime}`;

    const handleFinishTask = async () => {
        try {
            await api.put(`/task/${taskId}/done`);
            handleClose();
        } catch (err) {
            toast.error(`Quadro de tarefas sem coluna de finalização`);
        }
    };

    const formik = useFormik({
        initialValues: initialState,
        enableReinitialize: true,
        validationSchema: ContactSchema,
        onSubmit: async (values, actions) => {
            const errors = await formik.validateForm();

            console.log(errors, 'test err')

            if (Object.keys(errors).length > 0) {
                if (errors.title) {
                    toast.error(errors.title);
                } else if (errors.content) {
                    toast.error(errors.content);
                }
                actions.setSubmitting(false);
                return;
            }

            setTimeout(() => {
                handleSaveTask(values);
                actions.setSubmitting(false);
            }, 400);
        },
    });

    const handleUploadAttachment = async () => {
        try {
            const formData = new FormData();
            formData.append('media', selectedFile);
            taskId && formData.append('taskId', taskId);
            const response = await api.post('/task/upload/file', formData);

            console.log(response, 'test response')
            if (response.data) {
                toast.success('Arquivo anexado com sucesso!')
                setAttachments([...attachments, response.data.file])
                //`${apiURL}/public/${imageUrl}`;
            }
        } catch (error) {
            toast.error('Houve um erro ao anexar o arquivo.')
            console.error("Error handling image insertion:", error);
        }
    };

    const handleDeleteAttachment = async (file) => {
        try {
            const response = await api.delete(`/task/delete/file/${taskId}/${file}`);

           // console.log(response, 'test response')
            if (response.data) {
                toast.success('Arquivo removido com sucesso!')
                setAttachments(attachments.filter((attachment) => attachment !== file));
                //`${apiURL}/public/${imageUrl}`;
            }
        } catch (error) {
            toast.error('Houve um erro ao remover o arquivo.')
            console.error("Error handling image delete:", error);
        }
    };




    const { values, setFieldValue, handleSubmit, isSubmitting } = formik;


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (formik.dirty && taskId) {
                const changedValues = Object.keys(formik.values).reduce((acc, key) => {
                    const currentValue = formik.values[key];
                    const initialValue = formik.initialValues[key];

                    // Check for strict inequality, accounting for deep objects
                    if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
                        acc[key] = currentValue;
                    }
                    return acc;
                }, {});

                if (Object.keys(changedValues).length > 0) {
                    handleSaveTask(changedValues);
                }
            }
        }, 400);

        return () => clearTimeout(timeoutId);
    }, [formik.values]);


    return (
        <div className={classes.content}>
            <Dialog
                open={openConfirmDelete}
                onClose={handleCloseConfirm}
            >
                <DialogTitle>Confirmação de remoção</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você tem certeza de deletar a tarefa?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Cancelar</Button>
                    <Button onClick={handleRemove}>Deletar Tarefa</Button>
                </DialogActions>
            </Dialog>
            <FormikProvider value={formik}>
                <form onSubmit={handleSubmit}>
                    <DialogContent dividers style={{ overflow: 'initial' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                            {formik.touched.title && formik.errors.title && (
                                <div className="error text-danger">{formik.errors.title}</div>
                            )}
                            <div className={classes.AddMargin}
                                style={{
                                    width: '100%', display: 'flex',
                                    flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'
                                }}>

                                <Field
                                    name="title"
                                    component={EditableH1}
                                    variant="outlined"
                                    autoFocus
                                    fullWidth
                                    onChange={(e) => {
                                        setFieldValue("title", e.target.value);
                                        setChangeContent(true);
                                    }}
                                    InputProps={{
                                        style: {
                                            fontSize: "2.5rem",
                                            fontWeight: "bold",
                                            textWrap: "wrap",
                                        },
                                    }}
                                />


                                <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', gap: 10 }}>
                                    {taskId && <>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox onChange={handleFinishTask} label={"Finalizar Tarefa"} />
                                            <h5>Finalizar Tarefa</h5>
                                        </div>

                                        <span className={classes.labelLink}
                                            style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                                            onClick={handleClickRemoveOpen}>
                                            <FaTimes /> deletar tarefa
                                        </span>
                                    </>}
                                </div>
                            </div>


                            <div className={classes.gridOptions}>


                                <div className={classes.AddMargin} style={{
                                    width: '100%', display: 'grid', alignItems: 'flex-start', gap: 10, gripGap: 10,
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                                    gridAutoColumns: '1fr'
                                }}>

                                    <div className={classes.gridColOne}>

                                        {!changeUser ? (
                                            userInfo && userInfo.length >= 1 ? (
                                                <>
                                                    <UserAvatarGroup userInfo={userInfo} />

                                                    <span className={classes.labelLink} onClick={() => setChangeUser(true)}>
                                                        <FaUser /> modificar responsável
                                                    </span></>
                                            ) : (
                                                <span className={classes.labelLink} onClick={() => setChangeUser(true)}>
                                                    <FaUser /> adicionar responsável
                                                </span>
                                            )
                                        ) : (
                                            <div className={classes.divOptions}>
                                                <SelectUsers
                                                    selectedUsers={values.user}
                                                    label={'Responsável'}
                                                    onChange={(value) => {
                                                        const ids = value ? value.map((user) => user.id) : [];
                                                        setFieldValue('userIds', ids);
                                                    }}
                                                    disabled={undefined}
                                                    width={320}
                                                    outlined={undefined}
                                                    multiple={true}
                                                />
                                                {changeUser && (
                                                    <span className={classes.labelLink} onClick={() => setChangeUser(false)}>
                                                        cancelar
                                                    </span>
                                                )}
                                            </div>
                                        )}

                                    </div>

                                    <div className={classes.gridColTwo}>
                                        {/*<label className={classes.label}>Prazo</label>*/}
                                        {values.validateAt && !changeDeadline ? (
                                            <div className={classes.divOptions} style={{ justifyContent: 'space-between' }}>
                                                {new Date(values.validateAt).toLocaleDateString('pt-BR')} {new Date(values.validateAt).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                                                <span className={classes.labelLink} onClick={addEventToGoogleCalendar}>
                                                    <FaCalendarAlt /> marcar na agenda
                                                </span>
                                                <span className={classes.labelLink} onClick={() => setChangeDeadline(true)}>
                                                    <TbReload /> modificar prazo
                                                </span>
                                            </div>
                                        ) : (
                                            <>
                                                {changeDeadline ? (
                                                    <div className={classes.divOptions}>

                                                        <DatePicker
                                                            format="DD/MM/YYYY HH:mm"
                                                            label={i18n.t("scheduledMessageModal.form.date")}
                                                            onChange={(value) => { setFieldValue("validateAt", value); setChangeDeadline(false) }}
                                                            value={typeof values.validateAt === "string" ? new Date(values.validateAt) : values.validateAt}
                                                            className={classes.datePickerField}
                                                            container={document.body}
                                                            menuClassName={classes.datePickerFieldMenu}
                                                            calendarDefaultDate={typeof values.date === "string" ? new Date(values.date) : values.date}
                                                            locale={locale}
                                                            ampm={false}
                                                            ranges={[
                                                                {
                                                                    label: 'Agora',
                                                                    value: new Date()
                                                                }
                                                            ]}
                                                        />
                                                        <span className={classes.labelLink} onClick={() => setChangeDeadline(false)}>cancelar</span>
                                                    </div>
                                                ) : (
                                                    <span className={classes.labelLink} onClick={() => setChangeDeadline(true)}>
                                                        <FaClock /> adicionar prazo</span>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className={(message || ticket) ? classes.gridColTwo : classes.gridColLast}>
                                        {(taskId && !priorityColor) ?
                                            (!colorDefault ?
                                                <span className={classes.labelLink}
                                                    style={{ width: '100%', textAlign: 'right', display: 'block' }}
                                                    onClick={() => setPriority(true)}>adicionar prioridade</span>
                                                : (<div style={{ display: 'flex', alignItems: 'end', gap: 10, justifyContent: 'space-between', width: '100%', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                        <label className={classes.label}>Prioridade</label>
                                                        <div style={{ width: 24, height: 24, borderRadius: 100, background: colorDefault }}>
                                                        </div>
                                                    </div>
                                                    <span className={classes.labelLink} onClick={() => setPriority(true)}><MdOutlineLowPriority /> modificar prioridade</span>
                                                </div>)) :
                                            (<><label className={classes.label}>Prioridade</label>
                                                <Circle
                                                    colors={['#dfe6e9', '#ffeaa7',
                                                        '#55efc4', '#74b9ff', '#ff7675'
                                                    ]}
                                                    color={values.color}
                                                    onChange={(color) => {
                                                        setFieldValue('color', color.hex);
                                                    }}
                                                />
                                            </>)}
                                    </div>

                                    {(message || ticket) &&
                                        <div className={classes.gridColLast}>
                                            <div style={{ width: '100%' }}>
                                                {/*<label className={classes.label}>Quadro</label>*/}
                                                <SelectBoard
                                                    selectedBoard={values.boardId}
                                                    label={'Quadro de tarefas'}
                                                    onChange={handleBoardChange}
                                                    disabled={undefined}
                                                    width={undefined}
                                                    outlined={undefined}
                                                    multiple={undefined}
                                                />
                                            </div>
                                            {selectedBoardId &&
                                                <div style={{ width: '100%', marginTop: 10 }}>
                                                    {/*<label className={classes.label}>Bloco</label>*/}
                                                    <SelectColumns
                                                        boardId={selectedBoardId}
                                                        onChange={handleColumnChange}
                                                        label="Selecione o bloco"
                                                    />

                                                </div>}
                                        </div>
                                    }


                                </div>
                            </div>
                            <div className={classes.AddMargin}
                                style={{ display: 'flex', flexDirection: 'column', marginTop: 15, marginBottom: 15, }}>
                                {formik.touched.content && formik.errors.content && (
                                    <div className="error text-danger">{formik.errors.content}</div>
                                )}
                                {(!taskId || changeContent) ? <Field
                                    name="content"
                                    component={QuillEditorField}
                                    label={i18n.t("Descrição")}
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    taskId={taskId}
                                    className={classes.textareaField}
                                    size="small"
                                /> :
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0px' }}>
                                        <label style={{ fontWeight: 'bold' }}>Descrição</label>
                                        <div className={classes.content} dangerouslySetInnerHTML={{ __html: values.content }}>
                                        </div>
                                        <span className={classes.labelLink} onClick={() => setChangeContent(true)}>editar</span>
                                    </div>
                                }
                            </div>
                            {attachments && attachments.length > 0 && <div className={classes.AddMargin}>
                                <label style={{ fontWeight: 'bold' }}>Anexos</label>
                                <ul style={{marginLeft: 0, paddingLeft: 0}}>
                                    {attachments.map((item, index) => (
                                        <li key={index} className={classes.listAttachments}>
                                            <a className={classes.textLink} href={`${apiURL}/public/${item}`} target="_blank">{item}</a>
                                            <FaRegTrashCan style={{cursor: 'pointer'}} size={13} onClick={() => handleDeleteAttachment(item)}/>
                                        </li>)
                                    )}
                                </ul>
                            </div>}

                            <div className={classes.AddMargin}>

                                {selectedFile && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '1rem', wordBreak: 'break-word' }}>
                                            {selectedFile.name}
                                        </span>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleUploadAttachment()}
                                        >
                                            <UploadFile />
                                        </IconButton>

                                        <IconButton
                                            color="text"
                                            onClick={() => setSelectedFile(null)}
                                        >
                                            <FaTimes size={12} />
                                        </IconButton>
                                    </div>
                                )}
                                <div>

                                    <span
                                        className={classes.labelLink}
                                        onClick={handleUploadClick}
                                    >
                                        <Tooltip
                                            title="Max 5MB">
                                            <AttachFile sx={{ fontSize: 13 }} /> anexar arquivo
                                        </Tooltip>
                                    </span>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />

                                </div>

                            </div>
                            <div className={classes.AddMargin}
                                style={{ display: 'flex', flexDirection: 'column', marginTop: 15, marginBottom: 15, borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                {message && (
                                    <><label className={classes.label}>Sobre</label>
                                        <MessageBlock message={message} />
                                    </>)}

                                {(ticket || ticketInfo) && (
                                    <><label className={classes.label}>Sobre</label>
                                        <TicketBlock ticket={ticket || ticketInfo} />
                                    </>)}
                            </div>

                            {taskId && <CommentBox taskId={taskId} style={{ marginTop: 20 }} />}
                        </div>
                    </DialogContent>

                    {!taskId &&
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined"
                            >
                                {i18n.t("contactModal.buttons.cancel")}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                className={classes.btnWrapper}
                            >
                                {/*contactId
                            ? `${i18n.t("contactModal.buttons.okEdit")}`
                            :*/ `${i18n.t("contactModal.buttons.okAdd")}`}
                                {isSubmitting && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </Button>
                        </DialogActions>}

                </form>
            </FormikProvider >
        </div>
    )
}

export default FormTask;