import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Report from "../pages/Report";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import ShortcutMessages from "../pages/ShortcutMessages";
import ScheduledMessages from "../pages/ScheduledMessages";
import Queues from "../pages/Queues/";
import Tasks from "../pages/Tasks";
import Board from "../pages/Tasks/board";
import Tags from "../pages/Tags/";
import Notifications from "../pages/Notifications";
import Changelog from "../pages/Changelog";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import { SettingsProvider } from "../context/Settings/SettingsContext";
import Route from "./Route";
import Bots from "../pages/Bots";
import NotificationReceiver from "../components/Notifications/receiver";
import ContactImportPage from "../pages/Contacts/import";
import Tests from "../pages/Tests";
import Docs from "../pages/Docs";
import Logs from "../pages/Logs";
import { UserSettingsProvider } from "../context/Settings/UserSettingsContext";
import LoginTimeOut from "../pages/Login/timeout";
import Task from "../pages/Tasks/task";

const Routes = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<NotificationReceiver />
				<SettingsProvider>
					<UserSettingsProvider>
						<Switch>
							<Route exact path="/login" component={Login} />
							<Route exact path="/off" component={LoginTimeOut} />
							<Route exact path="/signup" component={Signup} />
							<Route exact path="/help" component={Docs}  />
							<WhatsAppsProvider>
								<LoggedInLayout>
									<Route exact path="/" checkSetting="showDashboard" component={Dashboard} isPrivate />
									<Route
										exact
										path="/tickets/:ticketId?"
										component={Tickets}
										isPrivate
									/>
									<Route
										exact
										path="/tickets/:ticketId/:messageId"
										component={Tickets}
										isPrivate
									/>
									<Route
										exact
										path="/connections"
										component={Connections}
										checkSetting="showConnections"
										isPrivate
									/>
									<Route
										path="/search/tickets/:term/:ticketId?"
										component={Tickets}
										isPrivate
									/>
									<Route exact path="/report" component={Report} isPrivate />
									<Route exact path="/contacts" component={Contacts} checkSetting="showContacts" isPrivate />
									<Route exact path="/contacts/import" component={ContactImportPage} checkSetting="showContacts" isPrivate />
									<Route exact path="/tags" component={Tags} checkSetting="showTags" isPrivate />
									<Route exact path="/shortcut-messages" component={ShortcutMessages} isPrivate />
									<Route exact path="/scheduled-messages" component={ScheduledMessages} isPrivate />
									<Route exact path="/users" component={Users} isPrivate />
									<Route exact path="/Settings" component={Settings} isPrivate />
									<Route exact path="/tasks" component={Tasks} isPrivate />
									<Route exact path="/tasks/:boardId" component={Board} isPrivate />
									<Route exact path="/task/:taskId" component={Task} isPrivate />
									<Route exact path="/Bots" component={Bots} isPrivate />
									<Route exact path="/Queues" component={Queues} isPrivate />
									<Route exact path="/notifications" component={Notifications} isPrivate />
									<Route exact path="/updates" component={Changelog} isPrivate />									
									<Route exact path="/tests" onlyAdmin={true} component={Tests} isPrivate />
									<Route exact path="/logs" component={Logs} isPrivate  />
									<Route exact path="/docs" component={Docs} isPrivate  />
								</LoggedInLayout>
							</WhatsAppsProvider>
						</Switch>
						<ToastContainer autoClose={3000} />
					</UserSettingsProvider>
				</SettingsProvider>
			</AuthProvider>
		</BrowserRouter>
	);
};

export default Routes;
