import React, { useState, useEffect, useContext } from "react";

import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { MdSettings } from "react-icons/md";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import MainContainer from "../../components/MainContainer";
import { Box, Button, Tab, Tabs } from "@mui/material";
import SettingBoolField from "../../components/Setting/boolField";
import SettingIntEnabledField from "../../components/Setting/intEnabledField";
import SettingIntField from "../../components/Setting/intField";
import SettingTextField from "../../components/Setting/textField";
import LogoUpload from "../../components/LogoUpload";
import { toast } from "react-toastify";

import Title from "../../components/Title";
import ApiKeys from "../ApiKeys";
import SettingSoundSelectionField from "../../components/Setting/soundSelectionField";
import SettingVoiceSelectionField from "../../components/Setting/soundVoiceSelectionField";
import getSocket from "../../helpers/socket";
import SettingSelectionField from "../../components/Setting/selectionField";

import ClockIcon from "@mui/icons-material/AccessTime";
import ForumIcon from "@mui/icons-material/Forum";
import ConfirmationModal from "../../components/ConfirmationModal";
import Webhooks from "../Webhooks";
import UserRoles from "../UserRoles";
import DraggableList from "../../components/UserList"
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import ForbiddenPage from "../../components/ForbiddenPage";
import { Can } from "../../components/Can";
import IntegrationIframes from "../IntegrationIframe";

const socket = getSocket();
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "top",
        //  padding: theme.spacing(4),
        padding: '0px 24px 24px 24px',
        [theme.breakpoints.down('md')]: {
            padding: '0px 5px 24px 5px',
        },
        minHeight: '100vh',
        backgroundColor: theme.palette.fancyBackground,
    },
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        overflow: 'hidden'
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
        '&>div': {
            width: "100%"
        },
        boxShadow: 'none'
    },
    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    tabLabel: {
        color: theme.palette.text.primary,
        fontWeight: 'bold'
    }
}));

const Settings = () => {
    const classes = useStyles();
    const { user } = useContext(AuthContext)
    const [settings, setSettings] = useState([]);
    const { isActive, getSettingValue } = useContext(SettingsContext);
    //const [meta, setMeta] = useState({});
    const [tab, setTab] = useState(0);
    const [procedureModalOpen, setProcedureModalOpen] = useState(false);
    const [procedure, setProcedure] = useState(null);
    const [procedureResult, setProcedureResult] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logoDark, setLogoDark] = useState(null);
    const enableQualifyTickets = isActive('enableQualifyTickets');

    const [items, setItems] = useState([]);

    const moveItem = (fromIndex, toIndex) => {
        const newItems = [...items];
        const [movedItem] = newItems.splice(fromIndex, 1);
        newItems.splice(toIndex, 0, movedItem);
        setItems(newItems);
    };


    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    const fetchSession = async () => {
        try {
            const { data } = await api.get("/settings");
            // console.log(data);
            setSettings(data.settings);
            //setMeta(data.meta);
        } catch (err) {
            console.log(err)
            toastError(err);
        }
    };

    useEffect(() => {
        fetchSession();
    }, []);

    useEffect(() => {
        const settingsEvent = data => {
            if (data.action === "update") {
                setSettings(prevState => {
                    const aux = [...prevState];
                    const settingIndex = aux.findIndex(s => s.key === data.setting.key);
                    aux[settingIndex].value = data.setting.value;
                    return aux;
                });
            }
        }

        socket.on("settings", settingsEvent);

        return () => {
            socket.off("settings", settingsEvent);
        };
    }, []);

    const handleProcedure = (procedure) => {
        setProcedureModalOpen(true);
        setProcedure(procedure);
    }

    const handleConfirmProcedure = async () => {
        if (procedure === 'clean-pending' || procedure === 'clean-open') {
            const { data } = await api.post(`/tickets/update-tickets`, {
                action: 'close',
                status: procedure === 'clean-pending' ? 'pending' : 'open',
                procedure: procedure
            });
            setProcedureResult(`${data.total} registro(s) processado(s)`);
        }
    }

    const handleLogo = async (event) => {

        const formData = new FormData();
        formData.append("logo", event);

        const response = await api.post(`settings/geral/logo/upload`,
            formData, { headers: { 'Content-Type': "multipart/form-data" } });

        if (response.data) {
            fetchSession();
            toast.success('Logo atualizada com sucesso!');
        } else {
            toast.error('Algo deu errado!');
        }

    }


    const handleLogoDark = async (event) => {

        const formData = new FormData();
        formData.append("logo", event)

        const response = await api.post(`settings/geral/logo/dark/upload`,
            formData, { headers: { 'Content-Type': "multipart/form-data" } });

        if (response.data) {
            fetchSession();
            toast.success('Logo atualizada com sucesso!');
        } else {
            toast.error('Algo deu errado!');
        }
    }

    const getUsers = async () => {
        try {
            const { data } = await api.get("/users/");
            //  console.log(data.users);

            const userList = getSettingValue('distributionList') ? getSettingValue('distributionList') : '';
            if (userList) {
                const order = JSON.parse(userList);
                const sortedUsers = order.map(userId => data.users.find(user => user.id === userId));
                setItems(sortedUsers);
            } else {
                setItems(data.users);
            }
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div className={classes.root}>
            <ConfirmationModal title="Atenção!" onClose={() => setProcedureModalOpen(false)} open={procedureModalOpen} onConfirm={handleConfirmProcedure}>
                Tem certeza que deseja realizar esse procedimento? Essa ação é irreversível.
            </ConfirmationModal>

            <MainContainer className={classes.mainContainer}>
                <Can
                    role={user.roleId}
                    perform="settings:show:page"
                    yes={() => (
                        <Paper
                            className={classes.mainPaper}
                            variant="outlined"
                        >
                            {/**  <Title>
                        <MdSettings size={24} />  {i18n.t("settings.title")}
                    </Title>*/}

                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.general")} />
                                    <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.appointments")} />
                                    <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.internal_chat")} />
                                    <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.permissions")} />
                                    <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.integration")} />
                                    <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.actions_by_batch")} />
                                    <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.ai")} />
                                    {/*<Tab 
                                        className={classes.tabLabel} 
                                        label={i18n.t("settings.tabs.distribution")}
                                        onClick={getUsers} 
                                    />*/}
                                </Tabs>
                            </Box>
                            <TabPanel value={tab} index={0}>
                                <Can
                                    role={user.roleId}
                                    perform="settings:general"
                                    yes={() => (<>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="groupMedia" settings={settings} label={i18n.t("settings.settings.groupMedia.name")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="showContacts" settings={settings} label={i18n.t("settings.settings.showContacts.name")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="showTags" settings={settings} label={i18n.t("settings.settings.showTags.name")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="showConnections" settings={settings} label={i18n.t("settings.settings.showConnections.name")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="showDeletedMessages" settings={settings} label={i18n.t("settings.settings.showDeletedMessages.name")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="showResolved" settings={settings} label={i18n.t("settings.settings.showResolved.name")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="showDashboard" settings={settings} label={i18n.t("settings.settings.showDashboard.name")} />
                                        </Paper>
                                        {1 == 2 && (<Paper className={classes.paper}>
                                            <SettingBoolField name="showAllTickets" settings={settings} label={i18n.t("settings.settings.showAllTickets.name")} />
                                        </Paper>)}
                                        {/*<Paper className={classes.paper}>
                                            <SettingBoolField name="showDisableSignOption" settings={settings} label={i18n.t("settings.settings.showDisableSignature.name")} />
                                        </Paper>*/}
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="userCanFinalizeTicketWithoutFeedback" settings={settings} label={i18n.t("settings.settings.showSurveyAfterFinish.name")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="enableCustomerPortfolio" settings={settings} label={i18n.t("settings.settings.showCustomerPortfolio.name")} helpText="Ao habilitar, contatos que estejam com atendentes associados iniciarão atendimento automaticamente com o atendente selecionado para cada um." />
                                        </Paper>

                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="notesVisibleAll" settings={settings} label={i18n.t("settings.settings.showMessagesNote.name")} helpText="Ao habilitar, todos usuários conseguem ver as notas nas mensagens." />
                                        </Paper>


                                        {/**<Paper className={classes.paper}>
                                            <LogoUpload setLogo={handleLogo} setLogoDark={handleLogoDark} />
                                    </Paper>**/}

                                    </>)}
                                    no={() => <ForbiddenPage />}
                                />
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <Can
                                    role={user.roleId}
                                    perform="settings:tickets"
                                    yes={() => (<>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="enableMarqueeContactName" settings={settings}
                                                label={i18n.t("Animação em nome de contato extenso nos Atendimentos versão desktop")}
                                            />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingSoundSelectionField name="notificationSound"
                                                settings={settings}
                                                label={i18n.t("settings.settings.notificationSound.name")} />
                                        </Paper>

                                        <Paper className={classes.paper}>
                                            <SettingIntEnabledField name="ticketTimeout" settings={settings}
                                                label={i18n.t("settings.settings.appointments.expiresTimeTicket.name")}
                                                helpText="minutos" />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingIntField name="ticketFeedbackTimeout" settings={settings}
                                                label={i18n.t("settings.settings.appointments.expiresTimeSurvey.name")}
                                                helpText={i18n.t("settings.settings.appointments.expiresTimeSurvey.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingIntEnabledField name="ticketBotTimeout" settings={settings}
                                                label={i18n.t("settings.settings.appointments.expiresTimeBot.name")}
                                                helpText={i18n.t("settings.settings.appointments.expiresTimeBot.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingTextField name="ticketTimeoutMessage" settings={settings}
                                                label={i18n.t("settings.settings.appointments.expiresMessage.name")}
                                                helpText={i18n.t("settings.settings.appointments.expiresMessage.helpText")} />
                                        </Paper>

                                        <Paper className={classes.paper}>
                                            <SettingSelectionField name="showContactHistory" settings={settings}
                                                label={i18n.t("settings.settings.appointments.showTicketsTo.name")} options={[
                                                    { label: i18n.t("settings.settings.appointments.showTicketsTo.options.all"), value: 'all' },
                                                    { label: i18n.t("settings.settings.appointments.showTicketsTo.options.admin"), value: 'admin' },
                                                    { label: i18n.t("settings.settings.appointments.showTicketsTo.options.queue"), value: 'queue' }
                                                ]}
                                                helpText={i18n.t("Não se aplica a atendimentos de grupos")}
                                            />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="ticketsByConnection"
                                                settings={settings} label={i18n.t("settings.settings.appointments.ticketsByConnection.name")}
                                                helpText={i18n.t("settings.settings.appointments.ticketsByConnection.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="historyByConnection" settings={settings}
                                                label={i18n.t("settings.settings.appointments.historyByConnection.name")}
                                                helpText={i18n.t("settings.settings.appointments.historyByConnection.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="historyByConnectionAdmin"
                                                settings={settings}
                                                label={i18n.t("settings.settings.appointments.historyByConnectionAdmin.name")}
                                                helpText={i18n.t("settings.settings.appointments.historyByConnectionAdmin.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="canDeleteTickets"
                                                settings={settings}
                                                label={i18n.t("settings.settings.appointments.canDeleteTickets.name")}
                                                helpText={i18n.t("settings.settings.appointments.canDeleteTickets.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="canDeleteMessages" settings={settings}
                                                label={i18n.t("settings.settings.appointments.canDeleteMessages.name")}
                                                helpText={i18n.t("settings.settings.appointments.canDeleteMessages.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="forceDownload" settings={settings}
                                                label={i18n.t("settings.settings.appointments.forceDownload.name")}
                                                helpText={i18n.t("settings.settings.appointments.forceDownload.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="showDownloadConfirmation" settings={settings}
                                                label={i18n.t("settings.settings.appointments.showDownloadConfirmation.name")}
                                                helpText={i18n.t("settings.settings.appointments.showDownloadConfirmation.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="blockNonDefaultConnections" settings={settings}
                                                label={i18n.t("settings.settings.appointments.blockNonDefaultConnections.name")}
                                                helpText={i18n.t("settings.settings.appointments.blockNonDefaultConnections.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="showLinks" settings={settings}
                                                label={i18n.t("settings.settings.appointments.showLinks.name")}
                                                helpText={i18n.t("settings.settings.appointments.showLinks.helpText")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="enableBotNotifications" settings={settings}
                                                label={i18n.t("settings.settings.appointments.enableBotNotifications.name")}
                                                helpText={i18n.t("settings.settings.appointments.enableBotNotifications.helpText")} />
                                        </Paper>

                                        <div>
                                            <h4>Grupos</h4>
                                            <hr />

                                            <Paper className={classes.paper}>
                                                <SettingBoolField name="enableGroupNotifications" settings={settings}
                                                    label={i18n.t("settings.settings.appointments.enableGroupsNotifications.name")}
                                                    helpText={i18n.t("settings.settings.appointments.enableGroupsNotifications.helpText")} />
                                            </Paper>

                                            <Paper className={classes.paper}>
                                                <SettingBoolField name="enableTransferGroups" settings={settings}
                                                    label={i18n.t("Transferir atendimento de grupo entre usuários")}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <SettingBoolField name="ignoreUserConnectionForGroups" settings={settings}
                                                    label={i18n.t("settings.settings.appointments.ignoreUserConnectionForGroups.name")}
                                                    helpText={i18n.t("settings.settings.appointments.ignoreUserConnectionForGroups.helpText")} />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <SettingBoolField name="enableInitGroupsInPending" settings={settings}
                                                    label={i18n.t("Iniciar atendimento de grupo transferido para usuário no aguardando")} />
                                            </Paper>

                                            <Paper className={classes.paper}>
                                                <SettingBoolField name="enableInitGroupWithHistoric" settings={settings}
                                                    label={i18n.t("Habiitar histórico de atendimento em grupos")}
                                                />
                                            </Paper>

                                            <h4>Qualificação de Atendimento</h4>
                                            <hr />

                                            <Paper className={classes.paper}>
                                                <SettingBoolField name="enableQualifyTickets" settings={settings}
                                                    label={"Habilitar qualificação de atendimentos"}
                                                    helpText={"Ativando essa opção, é possivel qualificar atendimentos realizados"} />
                                            </Paper>
                                        </div>

                                    </>)}
                                    no={() => <ForbiddenPage />}
                                />

                            </TabPanel>
                            <TabPanel value={tab} index={2}>
                                <Can
                                    role={user.roleId}
                                    perform="settings:chat"
                                    yes={() => (<>
                                        <Paper className={classes.paper}>
                                            <SettingBoolField name="chatEnabled" settings={settings}
                                                label={i18n.t("settings.settings.chat.enableInternalChat.name")} />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <SettingSoundSelectionField name="chatNotificationSound" settings={settings}
                                                label={i18n.t("settings.settings.chatNotificationSound.name")} />
                                        </Paper></>)}
                                    no={() => <ForbiddenPage />}
                                />
                            </TabPanel>
                            <TabPanel value={tab} index={3}>
                                <Can
                                    role={user.roleId}
                                    perform="settings:permissions"
                                    yes={() => (
                                        <Paper className={classes.paper}>
                                            <UserRoles />
                                        </Paper>)}
                                    no={() => <ForbiddenPage />}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={4}>
                                <Can
                                    role={user.roleId}
                                    perform="settings:integration"
                                    yes={() => (<>
                                        <Paper className={classes.paper}>
                                            <ApiKeys />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <Webhooks />
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <IntegrationIframes />
                                        </Paper></>)}
                                    no={() => <ForbiddenPage />}
                                />
                            </TabPanel>
                            <TabPanel value={tab} index={5}>
                                <Can
                                    role={user.roleId}
                                    perform="settings:batch"
                                    yes={() => (
                                        <>
                                            {procedureResult && <Paper className={classes.paper}>{procedureResult}</Paper>}
                                            <Paper className={classes.paper} title="Atendimentos">
                                                <Button className={classes.procedureButton} variant="contained" color="secondary"
                                                    onClick={() => handleProcedure('clean-pending')}><ClockIcon /> {i18n.t("settings.settings.batch.cleanPending.name")}
                                                </Button>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Button className={classes.procedureButton} variant="contained" color="primary"
                                                    onClick={() => handleProcedure('clean-open')}>  {i18n.t("settings.settings.batch.cleanOpen.name")}
                                                    <ForumIcon />
                                                </Button>
                                            </Paper>
                                        </>
                                    )}
                                    no={() => <ForbiddenPage />}
                                />
                            </TabPanel>
                            <TabPanel value={tab} index={6}>
                                <Can
                                    role={user.roleId}
                                    perform="settings:ai"
                                    yes={() => (
                                        <>
                                            <Paper className={classes.paper}>
                                                <SettingTextField name="keyOpenAi" settings={settings}
                                                    label={i18n.t("settings.settings.ai.enableKeyChatGpt.name")}
                                                    helpText={i18n.t("settings.settings.ai.enableKeyChatGpt.helpText")}
                                                />
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <SettingVoiceSelectionField name="voiceSound"
                                                    settings={settings}
                                                    label={i18n.t("settings.settings.voice.name")} />
                                            </Paper>
                                        </>
                                    )}
                                    no={() => <ForbiddenPage />}
                                />

                            </TabPanel>

                            <TabPanel value={tab} index={7}>
                                <Can
                                    role={user.roleId}
                                    perform="settings:distribution"
                                    yes={() => (
                                        <Paper className={classes.paper} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <SettingBoolField name="enableAutoDistribution" settings={settings}
                                                label={i18n.t("settings.settings.autoDistribution.enableDistribution.name")}
                                                helpText={i18n.t("settings.settings.autoDistribution.enableDistribution.helpText")} />
                                            {getSettingValue("enableAutoDistribution") === 'enabled' &&
                                                <>
                                                    <DraggableList items={items} setItems={setItems} />


                                                    <Paper className={classes.paper}>
                                                        <SettingIntField name="limitTicketsbyUser"
                                                            settings={settings}
                                                            label={i18n.t("settings.settings.autoDistribution.limitsTicketsDistribution.name")}
                                                            helpText={i18n.t("settings.settings.autoDistribution.limitsTicketsDistribution.helpText")} />
                                                    </Paper>
                                                </>
                                            }
                                        </Paper>
                                    )}
                                    no={() => <ForbiddenPage />}
                                />
                            </TabPanel>
                        </Paper>
                    )}
                    no={() => <>
                        <ForbiddenPage />
                    </>}
                />
            </MainContainer>
        </div>
    );
};

export default Settings;
