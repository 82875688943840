import React, { useEffect, useState, useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, InputAdornment, TextField } from "@mui/material";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { CheckBox } from "@mui/icons-material";
import Select from 'react-select';
import colourStyles from "../../constants/tagsColor";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
    selectStyle: {
        color: theme.mode === 'dark' ? '#fff' : '#000',
    },
    select: {
        marginBottom: 10,
        '& .basic-multi-select__control': {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
        },
        '& .basic-multi-select__menu': {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        '& .basic-multi-select__option': {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
            '&.basic-multi-select__option--is-selected': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
        },
        '& .basic-multi-select__single-value': {
            color: theme.palette.text.primary,
        },
    },
}));

const ResolveTicketModal = ({ open, onClose, onAccept, onAcceptFeedback, onAcceptNoEndMessage, ticket, noFeedback, motive, amount, notes, setMotive, setAmount, setNotes }) => {
    const classes = useStyles();
    const { info, isActive, getSettingValue } = useContext(SettingsContext);
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(false);
    const [dialogSurvey, setDialogSurvey] = useState(false);
    const enableQualifyTickets = isActive('enableQualifyTickets');


    useEffect(() => {
        console.log('ticket.', ticket)
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchPermissions = async () => {
                try {
                    const { data } = await api.get(`/tickets/${ticket.id}/permissions`);
                    setPermissions(data.permissions);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            fetchPermissions();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [ticket]);

    const handleClose = () => {
        onClose();
    }

    const handleSurveyTicket = () => {
        setDialogSurvey(!dialogSurvey);
    }

    const motives = [
        { value: 'loss', label: 'Perda', desc: 'Cliente desistiu do negócio devido ao preço do produto' },
        { value: 'earning', label: 'Ganho', desc: 'Cliente desistiu do negócio devido ao preço do produto' },
        { value: 'cancel', label: 'Cancelamento', desc: 'Cliente desistiu do negócio devido ao preço do produto' },
        { value: 'withdrawal', label: 'Desistência', desc: 'Cliente desistiu do negócio devido ao preço do produto' },
        //{ value: 'other', label: 'Outro motivo', desc: '' }
    ];

    const handleChangeType = (selectedOptions) => {
        setMotive(selectedOptions.value);
        console.log(selectedOptions);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
            <DialogTitle id="form-dialog-title">
                {i18n.t("resolveTicketModal.title")}
            </DialogTitle>
            <DialogContent dividers>
                <div className="flex flex-column" style={{ maxWidth: 400, margin: '0 auto' }}>
                    {enableQualifyTickets && <>
                        <Button
                            onClick={handleSurveyTicket}
                            color={`${dialogSurvey ? 'primary' : 'secondary'}`}
                            variant={`${dialogSurvey ? 'contained' : 'outlined'}`}
                            style={{marginBottom: 15}}
                        >Qualificar Atendimento</Button>
                        {dialogSurvey && (
                            <div className={`w-full block gap-4 rounded p-4`}>
                                <Select
                                    options={motives}
                                    onChange={handleChangeType}
                                    onRemove={handleChangeType}
                                    className={`basic-multi-select ${classes.select}`}
                                    classNamePrefix="basic-multi-select"
                                    placeholder="Selecione o motivo"
                                    menuPosition={''}
                                    defaultValue={motive}
                                    style={{ marginBottom: 10 }}

                                />

                                {motive === 'other' &&
                                    <FormControl className="mt-2" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount" size="small">Digite o motivo</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            label="Outro motivo"
                                            onChange={(e) => setAmount(e.target.value)}
                                            size="small"
                                        />
                                    </FormControl>
                                }

                                {/*motive === 'earning'  &&*/
                                    <FormControl className="mt-2" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Valor</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                            label="Valor"
                                            onChange={(e) => setAmount(e.target.value)}
                                            size="small"
                                        />
                                    </FormControl>
                                }

                                <TextField
                                    id="outlined-textarea"
                                    label="Informação Adicional"
                                    placeholder="Informação Adicional"
                                    multiline
                                    rows={4}
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </div>

                        )}</>}

                    <p className="w-full flex items-center font-bold my-2" style={{ textAlign: 'center' }}>
                        {ticket.status === 'group' ? i18n.t("resolveTicketModal.contentEndOnly")
                            : noFeedback ? i18n.t("resolveTicketModal.contentAutoFeedback")
                                : i18n.t("resolveTicketModal.content")}
                    </p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                >
                    {i18n.t("resolveTicketModal.buttons.cancel")}
                </Button>
                {ticket.status === 'group' &&
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={() => onAcceptNoEndMessage(ticket.id)}
                        color="primary"
                        disabled={loading}
                    >{i18n.t("resolveTicketModal.buttons.end")}</Button>
                }
                {ticket.status !== 'group' && <>
                    {permissions.haveEndMessage && permissions.canFinalizeWithoutFeedback && <Button
                        variant="contained"
                        type="submit"
                        onClick={() => onAcceptNoEndMessage(ticket.id)}
                        color="primary"
                        disabled={loading}
                    >{i18n.t("resolveTicketModal.buttons.endWithoutMessage")}</Button>}
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={() => onAccept(ticket.id)}
                        color="primary"
                        disabled={loading}
                    >
                        {!permissions.enabledFeedback || !permissions.canFinalizeWithoutFeedback ? i18n.t("resolveTicketModal.buttons.end") : i18n.t("resolveTicketModal.buttons.accept")}
                    </Button>
                    {permissions.enabledFeedback && permissions.canFinalizeWithoutFeedback &&
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            onClick={() => onAcceptFeedback(ticket.id)}
                        >
                            {i18n.t("resolveTicketModal.buttons.feedback")}
                        </Button>}
                </>}
            </DialogActions>
        </Dialog>
    )
}

export default ResolveTicketModal;