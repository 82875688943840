import React, { useState } from 'react';
import { Box, Typography, Avatar, AvatarGroup, LinearProgress, IconButton, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FaCheck, FaPencilAlt, FaTasks } from 'react-icons/fa';
import makeStyles from '@mui/styles/makeStyles';
import TaskBoardAddModal from '../TaskBoardModal';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    background: theme.palette.fancyBackground,
    width: '100%',
    padding: '0px !important'
  }
}));

const TaskBoard = ({ boardId, title, content, userId, queueId, userInfo, queueInfo, progress, totalTasks, totalCompletedTasks, onClose, hasPermissionEdit, hasPermissionDelete }) => {
  const classes = useStyles();
  const history = useHistory();
  const [editBoard, setEditBoard] = useState(false);


  const handleOpenEditBoard = () => {
    setEditBoard(true);
  }

  const handleCloseEditBoard = () => {
    setEditBoard(false);
    onClose();
  }

  const percentage = totalTasks > 0 ? (totalCompletedTasks / totalTasks) * 100 : 0;


  return (
    <>
      {editBoard &&
        <TaskBoardAddModal
          open={editBoard}
          boardId={boardId}
          onClose={handleCloseEditBoard}
          hasPermissionDelete={hasPermissionDelete}
        />}
      <Box
        sx={{
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          padding: '16px',
          width: '300px',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
          minHeight: 210
        }}
        className="boxTask"
        onClick={() => history.push(`tasks/${boardId}`)}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography variant="subtitle1"
            fontWeight="bold">{title}
          </Typography>
          {hasPermissionEdit &&
            <IconButton className={classes.buttonEdit}
              onClick={handleOpenEditBoard}
            ><FaPencilAlt size={14} /></IconButton>
          }
        </div>
        {content && content != 'undefined' &&
          <Tooltip title={content}>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                marginBottom: '16px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth: '230px',
              }}
            >
              {content}
            </Typography>
          </Tooltip>}
        <Box display="flex" alignItems="center"
          onClick={() => history.push(`tasks/${boardId}`)}
          justifyContent="space-between" sx={{ marginBottom: '8px' }}>
          <Typography variant="body2" fontWeight="bold">Progresso</Typography>
          <Typography variant="body2" fontWeight="bold">{percentage.toFixed(0)}%</Typography>
        </Box>
        <LinearProgress variant="determinate" value={percentage} sx={{ height: '8px', borderRadius: '4px', marginBottom: '16px' }} />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}><FaTasks /> {totalTasks} tarefas</span>
          <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}><FaCheck color='#2ecc71' /> {totalCompletedTasks} concluída(s)</span>
        </div>
        {(userId || queueId) &&
          (userInfo && userInfo.name ?
            <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
              {userInfo && userInfo.avatar ?
                <><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${userInfo.avatar}`} style={{ width: 24, height: 24 }} /></>
                : <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${userInfo.name}`} style={{ width: 24, height: 24 }} />
              }
              <Typography variant="body2" color="textSecondary" sx={{ marginLeft: '8px' }}>
                {userInfo.name}
              </Typography>
            </Box> :
            queueInfo &&
            <Typography variant="body2" color="textSecondary" style={{ marginTop: 10 }}>
              Setor: {queueInfo.name}
            </Typography>)
        }
      </Box>
    </>
  );
};

export default TaskBoard;
