import React, { useContext, useEffect, useReducer, useState } from "react";

import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Avatar,
    CardHeader
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import getSocket from "../../helpers/socket";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import ForbiddenPage from "../../components/ForbiddenPage";
import SelectUsers from "../../components/SelectUsers";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { CgList } from "react-icons/cg";
import { MdLocalPrintshop } from "react-icons/md";
import SelectTypeLogs from "../../components/SelectTypeLogs";
import moment from "moment";
import DateRangePicker from "../../components/DateRangePicker";
import { FaCirclePlay, FaDownload, FaEye, FaFileContract, FaLock, FaTags, FaUser } from "react-icons/fa6";
import { IoReloadCircleSharp } from "react-icons/io5";
import { FaCog } from "react-icons/fa";
import LogInfoModal from "../../components/LogInfoModal";
import SelectContacts from "../../components/SelectContacts";
import { useHistory } from "react-router-dom";
const socket = getSocket();

const useStyles = makeStyles(theme => ({
    mainContainer: {
        background: theme.palette.fancyBackground,

    },
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            marginTop: 0
        }
    },
    mainPaperTable: {
        flex: 1,
        overflow: 'auto',
        height: '68vh',
        ...theme.scrollbarStylesSoftBig,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        gap: 5,
        justifyContent: "center",
    },
    buttonsTicket: {
        height: 33,
        borderRadius: '5px!important',
        display: 'inline-flex',
        alignItems: 'center',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
    },
    fifty: {
        width: 200,
        [theme.breakpoints.down('md')]: {
            width: '45%'
        },
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_LOGS") {
        const logs = action.payload;
        const newContacts = [];

        logs && logs.length >= 1 && logs.forEach(log => {
            const contactIndex = state.findIndex(c => c.id === log.id);
            if (contactIndex !== -1) {
                state[contactIndex] = log;
            } else {
                newContacts.push(log);
            }
        });

        return [...state, ...newContacts];
    }


    if (action.type === "ADD_LOG") {
        const log = action.payload;
        return [log, ...state];
    }

    if (action.type === "UPDATE_LOG") {
        const log = action.payload;

        const contactIndex = state.findIndex(c => c.id === log.id);
        if (contactIndex !== -1) {
            state[contactIndex] = log;
        }
        return [...state];
    }

    if (action.type === "DELETE_LOG") {
        const logId = action.payload;

        const contactIndex = state.findIndex(c => c.id === logId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const LogsPage = () => {
    const history = useHistory();
    const classes = useStyles();
    const { user } = useContext(AuthContext)
    const [logs, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);

    const [logModalOpen, setlogModalOpen] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [openFilter, setOpenFilter] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [queueIds, setQueueIds] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [contactIds, setContactIds] = useState([]);
    const [categories, setCategories] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [forceReload, setForceReload] = useState(null);
    const [totalLogs, setTotalLogs] = useState(0);
    const [logType, setLogType] = useState('tickets');

    const handleLogType = (item) => {
        setLogType(item);
    }

    const goToTicket = (ticketId) => {
		history.push(`/tickets/${ticketId}`);
	}

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [userIds, contactIds, dateFrom, dateTo, categories, logType]);

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const formatDate = (value) => {
        return value ? moment(value).format("DD/MM/YYYY à[s] HH:mm") : '';
    }

    const fetchLogs = async () => {
        try {
     
            const url = logType === 'tickets' ? '/logs/tickets' : '/logs';
            const response = await api.get(url, {
                params: { pageNumber, dateFrom, dateTo, userIds, contactIds, categories },
            });
            //console.log(response, 'data')
            dispatch({ type: "LOAD_LOGS", payload: response.data.logs });
            setHasMore(response.data.hasMore);
            setTotalLogs(parseInt(response.data.count));
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    };

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            // if (user.profile !== "admin" && !settingIsActive("showContacts")) return;
            fetchLogs();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [pageNumber, queueIds, userIds, contactIds, user, dateFrom, dateTo, categories, logType, forceReload]);

    useEffect(() => {
        const logsEvent = data => {
            if (data.action === "create") {
               // console.log(data, 'log created')
                if (!logs.some(log => log.id === data.response.id)) {
                    dispatch({ type: "ADD_LOG", payload: data.response });

                    setTotalLogs(totalLogs + 1);
                }
            }

            if (data.action === "update") {
                //sconsole.log(data, 'log updated')
                dispatch({ type: "UPDATE_LOG", payload: data.log });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_LOG", payload: data.logId });
            }
        }

        socket.on("log", logsEvent);

        return () => {
            socket.off("log", logsEvent);
        };
    }, []);

    const handleOpenlogModal = (log) => {
        setlogModalOpen(true);
        setSelectedLog(log);
        // console.log(log,'test');
    };

    const handleCloseLogModal = () => {
        setlogModalOpen(false);
        setSelectedLog(null);
    };


    const handleScroll = e => {
        console.log('scrolling')
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            //console.log('loadmore')
            loadMore();
        }
    };

    const handlePrint = e => {
        window.print();
    }

    const handleDownload = async () => {
        //console.log('Download created');
        //const fileUrl = `${process.env.REACT_APP_BACKEND_URL}/var/log/jupiter/business.log`;

        const jsonContent = JSON.stringify(logs, null, 2);
        const blob = new Blob([jsonContent], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = logType === 'system' ? 'business.log':'tickets.log';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const renderIcon = (item) => {
        switch (item) {
            case 'auth':
                return <FaLock color="#a6a6a6" />;
            case 'views':
                return <FaEye color="#a6a6a6" />;
            case 'tags':
                return <FaTags color="#a6a6a6" />;
            case 'contact':
                return <FaFileContract color="#a6a6a6" />;
            case 'user':
                return <FaUser color="#a6a6a6" />;
            case 'setting':
                return <FaCog color="#a6a6a6" />;
            case 'permission':
                return <FaLock color="#a6a6a6" />;
            case 'actions':
                return <FaCirclePlay color="#a6a6a6" />;
            case 'schedule':
                return <IoReloadCircleSharp color="#a6a6a6" />;
            default:
                return null;
        }
    };

    return (
        <MainContainer className={classes.mainContainer}>

            {logModalOpen && logs && logs.length >= 1 && (
                <LogInfoModal
                    open={logModalOpen}
                    onClose={handleCloseLogModal}
                    log={selectedLog}
                    type={logType}
                    aria-labelledby="form-dialog-title"
                />
            )}

            <Can
                role={user.roleId}
                perform="log:show:page"
                yes={() => (
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        onScroll={handleScroll}
                    >
                        <MainHeader className={classes.mainHeaderBlock} style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Title><CgList size={24} /> {i18n.t("Logs")}</Title>


                                <div className="noprint" style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                    <span>{totalLogs} ite{totalLogs > 1 ? 'ns':'m'}</span>
                                    <Button
                                        variant={logType === 'tickets' ? "contained" : "outlined"}
                                        color="secondary"
                                        onClick={() => handleLogType('tickets')}
                                        className={classes.buttonsTicket}
                                        style={{ fontSize: '12px' }}
                                    >
                                        {i18n.t("Atendimentos")}
                                    </Button>
                                    <Button
                                        variant={logType === 'system' ? "contained" : "outlined"}
                                        color="secondary"
                                        onClick={() => handleLogType('system')}
                                        className={classes.buttonsTicket}
                                        style={{ fontSize: '12px' }}
                                    >
                                        {i18n.t("Sistema")}
                                    </Button>
                                </div>

                                <div  className="noprint" style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                                    <Can
                                        role={user.roleId}
                                        perform="log:create"
                                        yes={() => (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttonsTicket}
                                                style={{ fontSize: 13, gap: 10, background: '#000' }}
                                                onClick={handleDownload}
                                            >
                                                <FaDownload style={{ fontSize: 13 }} /> {i18n.t("Download")}
                                            </Button>)}
                                        no={() => <></>}
                                    />
                                    <Can
                                        role={user.roleId}
                                        perform="log:create"
                                        yes={() => (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.buttonsTicket}
                                                onClick={handlePrint}
                                                style={{ fontSize: 13, gap: 10 }}
                                            >
                                                <MdLocalPrintshop style={{ fontSize: 13 }} /> {i18n.t("Imprimir")}
                                            </Button>)}
                                        no={() => <></>}
                                    />

                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => setOpenFilter(!openFilter)}
                                        className={classes.buttonsTicket}
                                        style={{ fontSize: '12px' }}
                                    >
                                        {!openFilter ? <><AddCircleOutline style={{ fontSize: 13 }} />   {i18n.t("Abrir filtro")}</>
                                            : <><RemoveCircleOutline style={{ fontSize: 13 }} />   {i18n.t("Fechar filtro")}</>}
                                    </Button>
                                </div>
                            </div>
                            {openFilter &&
                                <MainHeaderButtonsWrapper
                                    className="d-flex flex-row flex-wrap"
                                    style={{ overflowX: 'auto', display: 'flex', flexWrap: 'wrap', width: '100%' }}
                                >
                                    <div style={{ width: 300 }}>
                                        <DateRangePicker disabled={loading} onChange={(p) => { setDateFrom(p[0]); setDateTo(p[1]) }} />
                                    </div>
                                    <div className={classes.fifty}>
                                        <SelectUsers
                                            className={classes.fifty}
                                            multiple
                                            onChange={(value) => setUserIds(value ? value.map(userItem => userItem.id) : [])}
                                            outlined></SelectUsers>
                                    </div>
                                    {logType === 'tickets' &&
                                        <div  style={{ minWidth: 300 }}>
                                            <SelectContacts
                                                className={classes.fifty}
                                                multiple
                                                onChange={(value) => setContactIds(value ? value.map(userItem => userItem.id) : [])}
                                                outlined></SelectContacts>
                                        </div>
                                    }
                                    {logType != 'tickets' && 
                                    <div style={{ width: 300 }}>
                                        <SelectTypeLogs
                                            onChange={(value) => { setCategories(value ? value.map(catItem => catItem.id) : []); }}
                                            selectedInitial={categories}
                                            outlined></SelectTypeLogs>
                                    </div>}
                                    {/*<Can
                                        role={user.roleId}
                                        perform="log:create"
                                        yes={() => (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttonsTicket}
                                                //onClick={handleDownload}
                                                style={{ fontSize: 13 }}
                                            >
                                                <FaDownload /> {i18n.t("Excluir Logs")}
                                            </Button>)}
                                        no={() => <></>}
                                    />*/}

                                </MainHeaderButtonsWrapper>
                            }
                        </MainHeader>
                        <Paper className={classes.mainPaperTable}
                            variant="outlined"
                            onScroll={handleScroll}
                        >
                            <Table size="small">
                                <TableHead>
                                    {logType === 'system' ?
                                        <TableRow>
                                            <TableCell align="center">
                                                {i18n.t("Data")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {i18n.t("Usuário")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {i18n.t("Email")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {i18n.t("Ação")}
                                            </TableCell>
                                            <TableCell align="center">
                                            </TableCell>
                                        </TableRow> :
                                        <TableRow>
                                            <TableCell align="center">
                                                {i18n.t("Data")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {i18n.t("Usuário")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {i18n.t("Email")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {i18n.t("Atendimento")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {i18n.t("Ação")}
                                            </TableCell>
                                            <TableCell align="center">
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableHead>
                                <TableBody>
                                    <>
                                        {logs.map((log, index) => (
                                            <TableRow key={log.id}>
                                                <TableCell align="center">{formatDate(log.createdAt)}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        aria-label="account of current user"
                                                        aria-controls="menu-appbar"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                        size="large">
                                                        <>
                                                            {log.user && log.user?.avatar ?
                                                                <><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${log.user.avatar}`} /></>
                                                                : log.user?.name ?
                                                                    <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${log.user.name}`} /> :
                                                                    ''
                                                            }
                                                        </>
                                                    </IconButton>
                                                    {log.user?.name ? log.user?.name : 'Anônimo'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className={classes.customTableCell}>
                                                        {log.user?.email ? log.user?.email : ''}
                                                    </div>
                                                </TableCell>
                                                {logType === 'tickets' && log.ticketId &&
                                                    <TableCell align="center">
                                                        <CardHeader
                                                            onClick={() => goToTicket(log.ticketId)}
                                                            style={{ cursor: "pointer" }}
                                                            titleTypographyProps={{ noWrap: true }}
                                                            subheaderTypographyProps={{ noWrap: true }}
                                                            avatar={<Avatar src={log.contact?.profilePicUrl} alt="contact_image" />}
                                                            title={`${log.contact?.name || '(sem contato)'} #${log.ticketId}`}
                                                        />
                                                    </TableCell>}
                                                <TableCell align="center">
                                                    {log.action?.section ? renderIcon(log.action.section) : ''} {log.desc}  {log?.additional ? ` ${log?.additional}` : ''}
                                                </TableCell>

                                                <TableCell align="center">
                                                    <FaEye
                                                        style={{ cursor: 'pointer' }}
                                                        color="#a6a6a6"
                                                        onClick={() => handleOpenlogModal(log)} />


                                                    {/*<Button
                                                        variant={"outlined"}
                                                        color="primary"
                                                        // onClick={() => handleLogType('tickets')}
                                                        className={classes.buttonsTicket}
                                                        style={{ fontSize: '12px' }}
                                                    >
                                                        {i18n.t("Resumir Atendimento")}
                                                    </Button>*/}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {loading && <TableRowSkeleton columns={logType === 'tickets' ? 5 : 4} />}
                                    </>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Paper>
                )
                }
                no={() => <>
                    <ForbiddenPage />
                </>}
            />
        </MainContainer >
    );
};

export default LogsPage;
