import React, { useState, useEffect, useReducer, useContext } from "react";
import FormControl from "@mui/material/FormControl";
import { Checkbox, Chip, ListItemText, Select } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";
import { DoneAll } from "@mui/icons-material";
import ButtonWithSpinner from "../ButtonWithSpinner";
import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { getLocalOptions } from "../../helpers/userOptions";
import { Typography } from "@mui/material";
import getSocket from "../../helpers/socket";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import useCountPaused from '../../hooks/countMessages';
import { useTicketCounts } from '../../context/Tickets/Count';
import { Can } from "../Can";

const useStyles = makeStyles(theme => ({
    ticketsListWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    GridticketsListWrapper: {
        maxHeight: 'fit-content',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        overflowY: 'auto',
        bottom: 0,
    },
    ticketsList: {
        flex: 1,
        overflowY: "scroll",
        ...theme.scrollbarStylesSoft,
        borderTop: "2px solid rgba(0, 0, 0, 0.12)",
    },


    ticketsCheckbox: {
        position: "sticky",
        bottom: 0,
        padding: 10,
        boxShadow: '0px 3px 45px 0px rgba(0,0,0,0.31)',
        left: 0,
        zIndex: 9,
        width: '100%'
    },

    ticketsListHeader: {
        color: "rgb(67, 83, 105)",
        zIndex: 2,
        backgroundColor: "white",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        lineHeight: '18px',
    },

    ticketsCount: {
        fontWeight: "normal",
        color: "rgb(104, 121, 146)",
        marginLeft: "8px",
        fontSize: "14px",
    },
    checkInputs: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputCheck: {
        width: '75%',
        height: 40
    },
    buttonCheck: {
        width: '25%',
        height: 40,
        fontSize: 10,
        borderRadius: 15
    },
    gridInputCheck: {
        width: '50%',
        height: 40
    },
    gridButtonCheck: {
        width: '50%',
        height: 40,
        fontSize: 10,
        borderRadius: 15
    },
    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}));

let fixedTickets = [];

const ticketSort = (a, b) => {
    const aInFixedTickets = fixedTickets.indexOf(a.id) > -1;
    const bInFixedTickets = fixedTickets.indexOf(b.id) > -1;
    if (aInFixedTickets && !bInFixedTickets) {
        return -1;
    }
    if (!aInFixedTickets && bInFixedTickets) {
        return 1;
    }
    if (aInFixedTickets == bInFixedTickets) {
        if (a.unreadMessages > 0 && b.unreadMessages == 0) {
            return -1;
        }
        if (a.unreadMessages == 0 && b.unreadMessages > 0) {
            return 1;
        }
    }
    return 0;
}

const ticketSortAsc = (a, b) => {
    if (a.createdAt < b.createdAt) {
        return -1;
    }
    if (a.createdAt > b.createdAt) {
        return 1;
    }
    return 0;
}

const getUnreadmessages = (ticket, user) => {
    if (ticket.status != 'group') {
        return ticket.unreadMessages;
    }

    if (!ticket.metas) {
        return ticket.unreadMessages;
    }

    for (const meta of ticket.metas) {
        if (meta.type == 'unreadMessages' && meta.userId == user.id) {
            ticket.unreadMessages = parseInt(meta.value);
        }
    }
    return ticket.unreadMessages;
}

const reducer = (state, action) => {
    const options = getLocalOptions();
    fixedTickets = options.fixedTickets;
    const sortDir = action.sortDir;
    const user = action.user;
    const setInternalCount = action.setInternalCount;
    if (action.type === "LOAD_TICKETS") {
        const newTickets = action.payload;

        newTickets.forEach(ticket => {
            getUnreadmessages(ticket, user);


            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    //This is about to put on the top unread messages
                    //state.unshift(state.splice(ticketIndex, 1)[0]);
                }


                if (ticket.unreadMessages > 0 && ticket.status === 'paused') {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(ticket);
            }
        });

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;
        action.status !== 'group' && getUnreadmessages(ticket, user);

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
        } else {
            if (action.status === action.payload.status) {
                state.unshift(ticket);
            }
            if (setInternalCount) setInternalCount(prevState => prevState + 1);
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        const ticket = action.payload;
        getUnreadmessages(ticket, user);
        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            if (action.status === action.payload.status) {
                state.unshift(ticket);
            }
            if (setInternalCount) setInternalCount(prevState => prevState + 1);
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_CONTACT") {
        const contact = action.payload;
        const ticketIndex = state.findIndex(t => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }
        return [...state];
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
            if (setInternalCount) setInternalCount(prevState => prevState - 1);
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "DELETE_TICKET_BY_CONTACT") {
        const contactId = action.payload;
        const ticketIndex = state.findIndex(t => t.contactId == contactId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
            if (setInternalCount) setInternalCount(prevState => prevState - 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const TicketsList = ({
    status,
    searchParam,
    searchById,
    searchOnMessages,
    showAll,
    selectedQueueIds,
    selectedTagIds,
    title,
    style,
    sortDir,
    disableControls,
    selectedConnectionIds,
    selectedUserIds,
    selectedStatuses,
    dateFrom,
    dateTo,
    forceSearch,
    lastClientActivityMe,
    lastClientActivityClient,
    unreadMessagesSearchMe,
    unreadMessagesSearchClient,
    updateCountBadge,
    updateCount,
    updatePausedBadge,
    showBotsOptions,
    mode,
    //updateCounts
}) => {
    const classes = useStyles();
    const [pageNumber, setPageNumber] = useState(1);
    const [ticketsList, dispatch] = useReducer(reducer, []);
    const [showWhatsappConnection, setShowWhatsappConnection] = useState(false);
    const { user, permissions } = useContext(AuthContext);
    const { getSettingValue, info, isActive, hashKey } = useContext(SettingsContext);
    const [disableAnchor, setDisableAnchor] = useState(false);
    const [fixedTickets, setFixedTickets] = useState([]);
    const [internalCount, setInternalCount] = useState(0);
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectedActionCheckeds, setSelectedActionsCheckeds] = useState('');
    const { setCountPaused } = useCountPaused();
    const { handleUpdateCounts } = useTicketCounts();
    const [lastTicketBotAdded, setLastTicketBotAdded] = useState(null);
    const [lastTicketPendingAdded, setLastTicketPendingAdded] = useState(0);
    const [lastTicketOpenAdded, setLastTicketOpenAdded] = useState(0);
    const hasPermissionQueue = permissions.some((permission) => permission.key === "tickets:show:without:queue");
    const groupTransferredPermission = permissions.some((permission) => permission.key === "can:interact:with:transferred:groups");
    const userCanInteractWithAllGroups = permissions.some((permission) => permission.key === 'contacts:groups:see-all');

    useEffect(() => {
        dispatch({ type: "RESET", status, sortDir });
        setPageNumber(1);
    }, [status, dispatch, showAll, selectedQueueIds, sortDir, forceSearch, hashKey]);

    useEffect(() => {
        setDisableAnchor(!isActive('showLinks'));
    }, [hashKey]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchData = async () => {
                const { data } = await api.get("/whatsapp/show-whatsapp-connection");
                setShowWhatsappConnection(data.show);
            };
            fetchData();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        const options = getLocalOptions();
        setFixedTickets(options.fixedTickets || []);
        if (!options.fixedTickets || options.fixedTickets.length === 0 && status === 'group') {
            setTimeout(() => {
                // Para tentar carregar as opções caso ainda não tenham sido salvas no local storage
                const options = getLocalOptions();
                setFixedTickets(options.fixedTickets || []);
            }, 2000);
        }
    }, []);


    const { tickets, hasMore, loading, count } = useTickets({
        pageNumber,
        searchParam,
        searchOnMessages,
        searchById: searchById && Number(searchById),
        status,
        showAll,
        userIds: selectedUserIds && JSON.stringify(selectedUserIds),
        connectionIds: selectedConnectionIds && JSON.stringify(selectedConnectionIds),
        statuses: selectedStatuses && JSON.stringify(selectedStatuses),
        dateFrom,
        dateTo,
        queueIds: selectedQueueIds && JSON.stringify(selectedQueueIds),
        tagIds: selectedTagIds && JSON.stringify(selectedTagIds),
        isGroup: status === "group" ? true : null,
        sortDir: sortDir ? sortDir : "DESC",
        forceSearch,
        lastClientActivityClient,
        lastClientActivityMe,
        unreadMessagesSearchMe,
        unreadMessagesSearchClient,
        settingChange: hashKey
    });



    useEffect(() => {
        if (disableControls) {
            disableControls(loading);
        }
    }, [loading]);

    useEffect(() => {
        if (!status && !searchParam && (!selectedTagIds || selectedTagIds.length === 0) && forceSearch !== true && forceSearch !== false) return;

        // Filtrar tickets com status 'bot' se showBotsOptions for falso
        /* const filteredTickets = showBotsOptions 
             ? tickets 
             : tickets.filter(ticket => ticket.status !== 'bot');*/

        dispatch({
            type: "LOAD_TICKETS",
            payload: tickets,
            status: status,
            sortDir,
            user,
            setInternalCount
        });
    }, [tickets, fixedTickets]);


    const handleCheckboxChange = (itemId, isChecked) => {
        if (isChecked) {
            setCheckedItems(prevCheckedItems => [...prevCheckedItems, itemId]);
        } else {
            setCheckedItems(prevCheckedItems =>
                prevCheckedItems.filter(id => id !== itemId)
            );
        }
    };


    const changeStatusTickets = async () => {
        try {
            const response = await api.post(`/tickets/lot/status`, {
                items: checkedItems,
                action: selectedActionCheckeds
            });
            if (response && response.data && response.data.total) {
                // console.log(response, 'test')
                toast.success(`${response.data.total} atendimentos atualizados`);
                setSelectedActionsCheckeds(null);
                setCheckedItems([]);
            }

        } catch (err) {
            toastError(err);
            setSelectedActionsCheckeds(null);
        }
    };

    useEffect(() => {
        const socket = getSocket(true);
        const userCanInteractWithAllGroups = user.profile === "admin" || permissions.some((permission) => permission.key === 'contacts:groups:see-all');
        const blockNonDefaultConnections = isActive('blockNonDefaultConnections');
        const ignoreUserConnectionForGroups = isActive('ignoreUserConnectionForGroups');
        const userAllowedToGroup = (ticket) => userCanInteractWithAllGroups ? userCanInteractWithAllGroups : ticket.contact.allowUsers.some((item) => item.id == user.id);

        const shouldUpdateTicket = ticket => {
            return (!ticket.userId || ticket.userId === user?.id || showAll) &&
                (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1) &&
                (ticket.status != "group" || userAllowedToGroup(ticket)) &&
                (!blockNonDefaultConnections || (ticket.status == 'group' && ignoreUserConnectionForGroups) ||
                    !user?.whatsappId || ticket.whatsappId == user?.whatsappId);
        }

        const notBelongsToUserQueues = ticket =>
            ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

        const connectEvent = () => {
            if (status) {
                socket.emit("joinTickets", status);
            } else {
                socket.emit("joinNotification");
            }
        }

        const ticketEvent = data => {

            //console.log('which event Ticket Event', data)

            if (data.action === "updateUnread" && (!data.userId || data.userId === user.id)) {
                dispatch({
                    type: "RESET_UNREAD",
                    payload: data.ticketId,
                    status,
                    sortDir,
                    setInternalCount
                });
            }

            if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
                dispatch({
                    type: "UPDATE_TICKET",
                    payload: data.ticket,
                    status,
                    sortDir,
                    user,
                    setInternalCount
                });
            }

            if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
                dispatch({ type: "DELETE_TICKET", payload: data.ticket.id, status, sortDir, setInternalCount });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_TICKET", payload: data.ticketId, status, sortDir, setInternalCount });
            }

            if (data.action === "change-contact-allow-user") {
                if (user.profile !== 'admin' && data.userIds && data.userIds.indexOf(user.id) === -1) {
                    dispatch({ type: "DELETE_TICKET_BY_CONTACT", payload: data.contactId, status, sortDir, setInternalCount });
                }
            }
        }

        const userOptionsEvent = data => {
            setFixedTickets(data.options.fixedTickets);
            //console.log(data.options.fixedTickets, 'data');
        }

        const appMessageEvent = data => {

        if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
                dispatch({
                    type: "UPDATE_TICKET_UNREAD_MESSAGES",
                    payload: data.ticket,
                    status,
                    sortDir,
                    user,
                    setInternalCount
                });
            }
        }

        const contactEvent = data => {
            if (data.action === "update") {
                dispatch({
                    type: "UPDATE_TICKET_CONTACT",
                    payload: data.contact,
                    status,
                    sortDir,
                    setInternalCount
                });
            }
        }

        socket.on("connect", connectEvent);
        socket.on("ticket", ticketEvent);

        if (status === 'group') {
            socket.on(`user_options_${user.id}`, userOptionsEvent);
        }

        if (status) {
            socket.on("appMessage", appMessageEvent);
            socket.on("contact", contactEvent);
        }

        if (socket.connected) {
            connectEvent();
        }

        return () => {
            socket.disconnect();
        };
    }, [status, showAll, user, selectedQueueIds, sortDir, info, lastTicketBotAdded, lastTicketOpenAdded, lastTicketPendingAdded]);

    useEffect(() => {
        if (updateCountBadge) {
            let totalUnread = 0;
            ticketsList.map(ticket => {
                if (ticket.unreadMessages > 0) {
                    totalUnread++;
                }
            });
            updateCountBadge(totalUnread);
        }

        if (updatePausedBadge) {
            let totalPaused = 0;
            tickets.map(ticket => {
                if (ticket.unreadMessages && ticket.status == 'paused') {
                    totalPaused++;
                }
            });
            setCountPaused(totalPaused);
            updatePausedBadge(totalPaused);
        }

        if (updateCount) {
            {
                let totalCount = 0;
                ticketsList.map(ticket => {
                    const shouldDisplayTicket = (user.roleId === 1 || (hasPermissionQueue && ticket.status !== 'group')) ||
                        (user.roleId === 1 || ticket.status === 'pending' && ticket.isGroup && groupTransferredPermission) ||
                        ticket.queue;
                    shouldDisplayTicket && totalCount++;
                })
                updateCount(totalCount);
            }
        }
        // console.log('list here', ticketsList)


    }, [ticketsList]);

    let pageNumberInterval = null;

    const loadMore = () => {
        if (pageNumberInterval) {
            clearInterval(pageNumberInterval);
        }
        pageNumberInterval = setInterval(() => {
            setPageNumber(prevState => prevState + 1);
            clearInterval(pageNumberInterval);
        }, 100);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <div
            style={style}
            className={mode === 'drag' ? classes.GridticketsListWrapper : classes.ticketsListWrapper}
        >
            {title && <Typography>{title}</Typography>}
            <Paper
                square
                name="closed"
                elevation={0}
                className={`${classes.ticketsList} listTickets`}
                onScroll={handleScroll}
            >
                <List style={{ paddingTop: 0, overflowX: 'hidden' }}>
                    {ticketsList.length === 0 && !loading ? (
                        <div className={classes.noTicketsDiv}>
                            <span className={classes.noTicketsTitle}>
                                {i18n.t("ticketsList.noTicketsTitle")}
                            </span>
                            <p className={classes.noTicketsText}>
                                {i18n.t("ticketsList.noTicketsMessage")}
                            </p>
                        </div>
                    ) : (
                        <>
                            {ticketsList.map(ticket => {

                                const shouldDisplayTicket = (user.roleId === 1 || (hasPermissionQueue && !ticket.isGroup)) ||
                                    (user.roleId === 1 || ticket.status === 'pending' && ticket.isGroup && groupTransferredPermission) ||
                                    ticket.queue || ticket.status === 'group' && (
                                        user.roleId === 1 ||
                                        user.roleId != 1 &&
                                        (userCanInteractWithAllGroups || groupTransferredPermission ||
                                            !userCanInteractWithAllGroups && (ticket.contact.allowUsers &&
                                                ticket.contact.allowUsers.some((item) => item.id == user.id)))
                                    );


                                return shouldDisplayTicket && (
                                    <>
                                        <TicketListItem
                                            disableAnchor={disableAnchor}
                                            ticket={ticket}
                                            fixedTickets={fixedTickets}
                                            key={ticket.id}
                                            showWhatsappConnection={showWhatsappConnection}
                                            isChecked={checkedItems.includes(ticket.id)}
                                            onCheckboxChange={handleCheckboxChange}
                                            mode={mode}
                                        />
                                        {ticket.isGroup}
                                    </>
                                );
                            })}

                        </>
                    )}
                    {loading && <TicketsListSkeleton />}
                </List>
            </Paper>
            {checkedItems.length > 0 && (
                <Paper className={classes.ticketsCheckbox}>
                    {checkedItems.length} selecionad{checkedItems.length > 1 ? "os" : "o"}
                    <FormControl variant="outlined" className={classes.checkInputs} fullWidth>
                        <Select
                            displayEmpty
                            value={selectedActionCheckeds}
                            onChange={(e) => setSelectedActionsCheckeds(e.target.value)}
                            className={mode ? classes.gridInputCheck : classes.inputCheck}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                }
                            }}
                        >
                            <MenuItem value="" disabled>
                                Selecione uma opção
                            </MenuItem>
                            <MenuItem value="" disabled>
                                Selecione uma opção
                            </MenuItem>
                            {((user && user.roleId === 1) || permissions.some((permission) => permission.key === 'tickets:accept:any')) && status === "paused" ? (
                                <MenuItem key="open" value="open">
                                    <p>Retomar Atendimento{checkedItems.length > 1 ? 's': ''}</p>
                                </MenuItem>
                            ) : null}
                            {((user && user.roleId === 1) || permissions.some((permission) => permission.key === 'tickets:pause:any')) && status != "paused" ? (
                                <MenuItem key="paused" value="paused">
                                    <p>Pausar Atendimento{checkedItems.length > 1 ? 's': ''}</p>
                                </MenuItem>
                            ) : null}
                             {(user && user.roleId === 1) || permissions.some((permission) => permission.key === 'tickets:finalize:any') ? (
                                <MenuItem key="closed" value="closed">
                                    <p>Finalizar Atendimento{checkedItems.length > 1 ? 's': ''}</p>
                                </MenuItem>
                            ) : null}
                        </Select>
                        <ButtonWithSpinner
                            style={{ backgroundColor: '#2ECC71', boxShadow: 'none', color: '#000000', fontSize: '11px', fontWeight: 'bold', margin: '2px' }}
                            contentStyle={{ color: '#000000' }}
                            loading={loading}
                            startIcon={<DoneAll />}
                            size="small"
                            variant="contained"
                            disabled={!selectedActionCheckeds ? true : false}
                            className={mode ? classes.gridButtonCheck : classes.buttonCheck}
                            onClick={changeStatusTickets}
                        >
                            Confirmar
                        </ButtonWithSpinner>
                    </FormControl>
                </Paper>
            )}
        </div>
    );
};

export default TicketsList;
