import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import api from "../../../services/api";

const SelectColumns = ({ onChange, boardId, disabled, width, outlined, multiple, label }) => {
    const [columns, setColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(multiple ? [] : "");

    useEffect(() => {
        if (boardId) {
            const getColumns = async () => {
                try {
                    const response = await api.get(`/tasks/board/${boardId}/columns`);
                    console.log(response, 'test select', 'boardId', boardId)
                    setColumns(response.data);
                } catch (error) {
                    console.error("Error fetching columns:", error);
                }
            };
            getColumns();
        }
    }, [boardId]);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedColumns(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <FormControl fullWidth={!width} style={{ width: width || '100%' }} variant={"standard"}>
            <InputLabel>{label || "Selecione o bloco"}</InputLabel>
            <Select
                value={selectedColumns}
                onChange={handleChange}
                disabled={disabled || !boardId}
                multiple={false}
                label={label || "Selecione o bloco"}
            >
                {columns.map(column => (
                    <MenuItem key={column.id} value={column.id}>
                        {column.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectColumns;
