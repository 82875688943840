import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import AsyncSelect from "../../AsyncSelect";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { Circle } from '@uiw/react-color';
import { i18n } from "../../../translate/i18n";
import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/Auth/AuthContext";



const useStyles = makeStyles(theme => ({
    AddMargin: {
        marginTop: 10,
        marginBottom: 10
    },
    boxFiles: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    iconText: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        // color: '
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column"
    },
    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },
    uploadInput: {
        display: "none"
    },
    textField: {
        marginRight: theme.spacing(1),
        width: '100%'
    },
    textareaField: {
        marginRight: theme.spacing(1),
        width: '100%',
        minHeight: 100,
        background: 'transparent'
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    cont: {
        fontStyle: "italic",
    },
    timestamp: {
        fontStyle: 'normal',
        fontSize: 11,
        color: '#666666'
    }
}));

const BoardSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, "Muito Curto!")
        .max(200, "Muito Longo!")
        .required("Obrigatório"),
    content: Yup.string().min(3, "Muito Pequeno!"),
});

const TaskBoardAddModal = ({ open, onClose, boardId }) => {
    const history = useHistory();
    const classes = useStyles();
    const { user, permissions } = useContext(AuthContext);
    const isMounted = useRef(true);
    const [hex, setHex] = useState('');
    const [initialState, setinitialState] = useState({});
    const [boardTask, setBoardTask] = useState(initialState);
    const [starter, setStarter] = useState(true);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSelectType, setIsSelectType] = useState('');
    const hasPermissionDelete = user.roleId === 1 || permissions.some((permission) => permission.key === 'task:board:delete');
    
    const handleClickRemoveOpen = () => {
        setOpenConfirmDelete(true);
    };

    const handleType = (type) => {
        isSelectType === type ?
        setIsSelectType('')
        : setIsSelectType(type);
    }

    const handleCloseRemoveConfirm = () => {
        setOpenConfirmDelete(false);
    };


    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        !boardId &&
            setinitialState({
                title: "",
                content: "",
                userId: null,
                queueId: null,
                color: hex,
                user: null,
                queue: null
            })
    }, []);

    useEffect(() => {

        const getBoard = async () => {
            setLoading(true);
            //console.log('boardId', boardId)
            if (boardId) {
                const response = await api.get(`tasks/board/info/${boardId}`);
                //console.log(response, 'test');
                if (response.data) {
                    const boardData = response.data.board;
                    setinitialState({
                        title: boardData.title,
                        content: boardData.content,
                        userId: boardData.userId,
                        queueId: boardData.queueId,
                        user: boardData.user,
                        queue: boardData.queue
                    });
                    response.data.board.color && setHex(response.data.board.color);

                    response.data.board.user && handleType('user');
                    response.data.board.queue && handleType('queue');
                }
            }
            setLoading(false);
        }
        getBoard();

    }, [boardId])

    useEffect(() => {
        if (boardId && initialState) {
            setBoardTask(initialState);
        }
    }, [initialState, boardId]);

    const handleClose = () => {
        onClose();
        setBoardTask(initialState);
    };

    const handleSaveBoard = async (values) => {
        try {
            console.log(values, 'values')
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('content', values.content);
            formData.append('color', hex);
            values.userId && formData.append('userId', values.userId);
            values.queueId && formData.append('queueId', values.queueId);
            starter && formData.append('starter', starter)
            if (!boardId) {
                const { data } = await api.post(`/tasks/boards/add`, formData);
             //   console.log(data);
            } else {
                const { data } = await api.put(`/tasks/board/${boardId}`, formData);
               // console.log(data);
            }

            handleClose();
            toast.success(i18n.t(`Projeto ${boardId ? 'editado' : 'criado'} com sucesso!`));
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };

    const handleDeleteBoard = async () => {
        try {
            await api.delete(`/tasks/board/${boardId}`);
            handleCloseRemoveConfirm();
            handleClose();
            toast.success(i18n.t('Projeto deletado com sucesso!'));
            await history.push('/tasks')
        } catch (err) {
            console.log(err)
        }
    }

    const handleChangeColor = (color) => {
        setHex(color.hex)
    };


    return (
        <div className={classes.root}>
            <Dialog
                open={openConfirmDelete}
                onClose={handleCloseRemoveConfirm}
            >
                <DialogTitle>Confirmação de remoção</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Se você remover o bloco, removerá todos as tarefas deste bloco. Você tem certeza?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRemoveConfirm}>Cancelar</Button>
                    <Button onClick={handleDeleteBoard}>Remover Tarefas</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {boardId
                        ? i18n.t("Editar Projeto")
                        : i18n.t("Adicionar Projeto")}
                </DialogTitle>

                {loading ? <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >Carregando...</div> :
                    <Formik
                        initialValues={boardTask}
                        enableReinitialize={true}
                        validationSchema={BoardSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSaveBoard(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form>
                                <DialogContent dividers>
                                    <div className={classes.AddMargin}>
                                        <Field
                                            as={TextField}
                                            label={i18n.t("addTaskModal.form.title")}
                                            name="title"
                                            autoFocus
                                            error={touched.title && Boolean(errors.title)}
                                            variant="outlined"
                                            margin="dense"
                                            className={classes.textField}
                                        />
                                    </div>
                                    <div className={classes.AddMargin} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label className={classes.label}>Descrição</label>
                                        <Field
                                            as={TextareaAutosize}
                                            label={i18n.t("Descrição")}
                                            name="content"
                                            autoFocus
                                            variant="outlined"
                                            margin="dense"
                                            className={classes.textareaField}
                                        />
                                    </div>

                                    <div className={classes.AddMargin}>
                                        <label className={classes.label}>Cor</label>
                                        <Circle
                                            colors={['#dfe6e9', '#ffeaa7',
                                                '#55efc4', '#74b9ff', '#ff7675'
                                            ]}
                                            color={hex}
                                            onChange={(color) => {
                                                handleChangeColor(color)
                                            }}
                                        />
                                    </div>

                                    {/*<div>
                                        <div style={{display: 'flex',width: '100%', marginBottom: 20}}>
                                            <Button onClick={() => handleType('user')} variant={isSelectType === 'user' ? "outlined" : "container"}>
                                                Atendente
                                            </Button>


                                            <Button onClick={() => handleType('queue')} variant={isSelectType === 'queue' ? "outlined" : "container"}>
                                                Setor
                                            </Button>
                                        </div>

                                        {(isSelectType === 'user')  && <div style={{ marginTop: 10 }}>
                                            <AsyncSelect url="/users" dictKey={"users"}
                                                initialValue={values.user} width="100%" label="Atendente"
                                                onChange={(e, value) => { setFieldValue("userId", value ? value.id : null) }} />
                                        </div>}
                                        {(isSelectType === 'queue') && <div style={{ marginTop: 10 }}>
                                            <AsyncSelect url="/queue" dictKey={null}
                                                initialValue={values.queue} width="100%" label="Setor"
                                                onChange={(e, value) => setFieldValue("queueId", value ? value.id : null)} />
                                        </div>}
                                        </div>*/}


                                    {!boardId && <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={starter} onChange={() => setStarter(!starter)} />}
                                            label="Iniciar com quadro/tarefas demonstrativas" />
                                    </FormGroup>}


                                    {boardId && hasPermissionDelete && 
                                    <span style={{ fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }}
                                        onClick={handleClickRemoveOpen}>Deletar quadro de tarefas</span>}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="secondary"
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        {i18n.t("contactModal.buttons.cancel")}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {boardId
                                            ? i18n.t("Editar")
                                            : i18n.t("Adicionar")}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>}
            </Dialog>
        </div>
    );
};

export default TaskBoardAddModal;
