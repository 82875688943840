import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Button, useTheme, Tooltip, IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { IoArrowUpCircleOutline, IoArrowDownCircleOutline } from "react-icons/io5";
import makeStyles from '@mui/styles/makeStyles';
import formatTime from "../../helpers/formatTime";

const useStyles = makeStyles(theme => ({
  iconTitle: {
    color: theme.palette.dark.main,
    fontSize: 24,
    cursor: 'pointer'
  }
}));


const ChartComponent = ({ period, userIds, contactIds, statuses, queueIds, tagIds, endpoint, title, yAxisTitle, colorChart, setLoading, theme, unit }) => {
  const classes = useStyles();
  const [chartData, setChartData] = useState(null);
  const [internalLoading, setInternalLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [titleAxis, setYtitle] = useState('');
  const [allNames, setAllNames] = useState([]);
  const [openBoxChart, setOpenBoxChart] = useState(true);
  const themeApp = useTheme();

  const downloadExport = async () => {
    setExporting(true);
    try {
      const format = 'xlsx';
      const dateStart = moment(period[0]).format("YYYY-MM-DD");
      const dateEnd = moment(period[1]).format("YYYY-MM-DD");
      const contactIdsString = JSON.stringify(contactIds);
      const userIdsString = JSON.stringify(userIds);
      const queueIdsString = JSON.stringify(queueIds);
      const statusesString = JSON.stringify(statuses);
      const tagIdString = JSON.stringify(tagIds);
      const params = new URLSearchParams({
        dateStart,
        dateEnd,
        format,
        statuses: statusesString
      });
      if (userIds && userIds.length > 0) params.append('userIdsString', userIdsString);
      if (contactIds && contactIds.length > 0) params.append('contactIdsString', contactIdsString);
      if (queueIds && queueIds.length > 0) params.append('queueIdsString', queueIdsString);
      if (tagIds && tagIds.length > 0) params.append('tagIdsString', tagIdString);

      // Fetch the report
      const { data } = await api.get(`/report/export/tickets?${params.toString()}`, {
        responseType: 'arraybuffer', headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv'
        }
      });

      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `historico_atendimentos.${format}`;
      link.click();
      setExporting(false);
    } catch (err) {
      toastError(err);
      setExporting(false);
    }
  }

  const exportContacts = async () => {
    setExporting(true);
    try {
      const format = 'xlsx';
      const dateStart = moment(period[0]).format("YYYY-MM-DD");
      const dateEnd = moment(period[1]).format("YYYY-MM-DD");
      const contactIdsString = JSON.stringify(contactIds);
      const userIdsString = JSON.stringify(userIds);
      const queueIdsString = JSON.stringify(queueIds);
      const statusesString = JSON.stringify(statuses);
      const tagIdString = JSON.stringify(tagIds);
      const params = new URLSearchParams({
        dateStart,
        dateEnd,
        format,
        statuses: statusesString
      });
      if (userIds && userIds.length > 0) params.append('userIdsString', userIdsString);
      if (contactIds && contactIds.length > 0) params.append('contactIdsString', contactIdsString);
      if (queueIds && queueIds.length > 0) params.append('queueIdsString', queueIdsString);
      if (tagIds && tagIds.length > 0) params.append('tagIdsString', tagIdString);

      // Fetch the report
      const { data } = await api.get(`/report/export/contacts?${params.toString()}`, {
        responseType: 'arraybuffer', headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv'
        }
      });

      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `historico_contatos.${format}`;
      link.click();
      setExporting(false);
    } catch (err) {
      toastError(err);
      setExporting(false);
    }
  }


  useEffect(() => {
    let isMounted = true;
    setYtitle(yAxisTitle);
    const fetchData = async () => {
      try {
        setInternalLoading(true);
       // isMounted && console.log('queueIds', queueIds)
        const response = await api.get(endpoint, {
          params: {
            dateStart: moment(period[0]).format("YYYY-MM-DD"),
            dateEnd: moment(period[1]).format("YYYY-MM-DD"),
            contactIds: JSON.stringify(contactIds),
            userIds: JSON.stringify(userIds),
            queueIds: JSON.stringify(queueIds),
            statuses: JSON.stringify(statuses),
            tagIds: JSON.stringify(tagIds),
          },
        });

        if (response.data && isMounted) {
        //  endpoint === '/report/rush-hours' && console.log('received data', response);
          setChartData(response.data.data);
          setInternalLoading(false);
          setLoading(false);
          const names = response.data.data.length >= 1 ? response.data.data.map(item => item.name) : [];
          setAllNames(names);
        }
      } catch (err) {
        if (isMounted) {
          setInternalLoading(false);
          setLoading(false);
          toastError(err);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [period, userIds, contactIds, queueIds, tagIds, statuses, endpoint, colorChart]);

  return (
    <>
      <h2 style={{ fontSize: 20, margin: '0px 15px' }}>
        {title === 'Atendimentos' ? (
          <div className="block-title-chart" style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className="title-chart" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => setOpenBoxChart(!openBoxChart)}>
              Atendimentos
              <Tooltip title={`Este gráfico exibe o número de atendimentos com status abertos, pausados, pendentes e resolvidos de cada usuário.`}>
                <IconButton>
                  <FaInfoCircle size={15} />
                </IconButton>
              </Tooltip>
              {openBoxChart ? <IoArrowUpCircleOutline className={classes.iconTitle} /> : <IoArrowDownCircleOutline className={classes.iconTitle} />}
            </span>
            {openBoxChart && <Button variant="outlined" size="small" onClick={() => downloadExport('open')} startIcon={<img src="/icons/xlsx-icon.png" height={30} />}>
              Exportar Planilha
            </Button>}
          </div>
        ) : endpoint === '/report/ticket-response-time/' ?
          <div className="block-title-chart" style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className="title-chart" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => setOpenBoxChart(!openBoxChart)}>
              {title}
              <Tooltip title={`Este gráfico representa o tempo médio que um usuário levou a aceitar um atendimento do aguardando ou bot.`}>
                <IconButton>
                  <FaInfoCircle size={15} />
                </IconButton>
              </Tooltip>
              {openBoxChart ? <IoArrowUpCircleOutline className={classes.iconTitle} /> : <IoArrowDownCircleOutline className={classes.iconTitle} />}
            </span>
          </div>
          : endpoint === '/report/contact-numbers/' ? 
          <div className="block-title-chart" style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className="title-chart" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => setOpenBoxChart(!openBoxChart)}>
              {title}
              <Tooltip title={`Este gráfico é a quantidade leads "contatos" criados por cada usuário, em um determinado período de tempo.`}>
                <IconButton>
                  <FaInfoCircle size={15} />
                </IconButton>
              </Tooltip>
              {openBoxChart ? <IoArrowUpCircleOutline className={classes.iconTitle} /> : <IoArrowDownCircleOutline className={classes.iconTitle} />}
            </span>
            {openBoxChart && <Button variant="outlined" size="small" onClick={() => exportContacts()} startIcon={<img src="/icons/xlsx-icon.png" height={30} />}>
              Exportar Planilha
            </Button>}
          </div>
          : endpoint === '/report/rush-hours' ? 
          <div className="block-title-chart" style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className="title-chart" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => setOpenBoxChart(!openBoxChart)}>
              {title}
              <Tooltip title={`Este gráfico é a quantidade de mensagens enviadas/recebidas por hora.`}>
                <IconButton>
                  <FaInfoCircle size={15} />
                </IconButton>
              </Tooltip>
              {openBoxChart ? <IoArrowUpCircleOutline className={classes.iconTitle} /> : <IoArrowDownCircleOutline className={classes.iconTitle} />}
            </span>         
          </div>
          : (
            <span className="title-chart" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => setOpenBoxChart(!openBoxChart)}>
              {title}
              {openBoxChart ? <IoArrowUpCircleOutline className={classes.iconTitle} /> : <IoArrowDownCircleOutline className={classes.iconTitle} />}
            </span>
          )}
      </h2>


      {openBoxChart ?
        chartData && chartData.length > 0 ? (
          <div style={{ height: 500, width: '100%', display: 'flex' }}>
            <ResponsiveBar
              data={chartData}
              keys={allNames}
              indexBy="name"
              height={500}
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={{ scheme: theme }}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 0,
                tickRotation: -15,
                legendPosition: 'start',
                legendOffset: 2,
                truncateTickAt: 100
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                legend: `0`,
                format: value => value !== 0 ? unit === 'number' ? value:`${formatTime(value, true)}` : null,
                legendPosition: 'middle',
                legendOffset: -100,
              }}
              valueFormat={value =>  unit === 'number' ? value:`${formatTime(value, true)}`}
              labelSkipWidth={12}
              labelSkipHeight={12}
              theme={{
                text: {
                  fill: themeApp.mode === 'dark' ? '#ffffff' : '#000000'
                }
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 4,
                  itemWidth: 100,
                  itemHeight: 10,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={e => e.formattedValue + e.indexValue + "atendimentos"}
              tooltip={({
                id,
                value,
                color
              }) => <div style={{
                padding: 12,
                color,
                background: '#222222',
                textAlign: 'center'
              }}>
                  <span>{title}</span>
                  <br />
                  <strong>
                    {id}: {unit === 'number' ? value:`${formatTime(value)}` } {title === "Horários de Pico" && 'mensagens'}
                  </strong>
                </div>}
            />
          </div>
        ) : chartData && chartData.length === 0 ? (
          <div
            className="boxChart"
            style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
          >
            {/*<img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />*/}
            Nada encontrado
          </div>) : (
          <div
            className="boxChart"
            style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
          >
            <img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />
            Carregando dados...
          </div>
        )
        : <></>}
    </>
  );
};

export default ChartComponent;