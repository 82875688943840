import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/Auth/AuthContext";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import { Dialog } from "@mui/material";
import { FaAlignLeft, FaPlus } from "react-icons/fa";
import { i18n } from "../../../translate/i18n";
import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import AddTaskTicketModal from "../AddTaskTicketModal";
import FormTask from "../FormTask";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    titleH3: {
        fontSize: 15,
        display: 'flex',
        alignItems: 'center'
    },
    description: {
        fontSize: 18,
        marginBottom: 15
    },
    buttonTask: {
        background: 'transparent !important',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        padding: '5px 0px'
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    avatares: {
        display: 'flex'
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    dialogPaper: {
        width: '80%',
        maxWidth: 'none',
        background: theme.palette.fancyBackground,
        ...theme.scrollbarStylesSoft,
        borderRadius: 10
    }
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
    email: Yup.string().email("Invalid email"),
});



const TaskModal = ({ open, onClose, taskId, initialValues, onSave }) => {
    const classes = useStyles();
    const [actionTask, setNewTaskOpen] = useState(false);
    const [task, setTask] = useState(null)



    const handleClose = () => {
        onClose();
    };
    const handleOpenAddTask = () => {
        setNewTaskOpen(true);
    };

    const handleFinishTask = () => {
        onClose();
    };


    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose}
                maxWidth={false}
                scroll="paper"
                classes={{ paper: classes.dialogPaper }}

            >
                <div>
                    <FormTask
                        taskId={taskId}
                        onClose={handleClose}
                    />
                    <div className={classes.boxSubTask}>
                        <div style={{ padding: '5px 15px' }}>
                            <button className={classes.buttonTask} onClick={() => handleOpenAddTask()}>
                                <FaPlus /> Adicionar subtarefa
                            </button>
                        </div>

                        {actionTask ? (
                            <AddTaskTicketModal onClose={handleFinishTask} taskId={taskId} />
                        ) : null}
                    </div>
                    
                </div>
            </Dialog >
        </div >
    );
};

export default TaskModal;
