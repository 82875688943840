import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import api from "../../../services/api";
import TaskBoardAddModal from '../TaskBoardModal';

const SelectBoard = ({ onChange, disabled, width, outlined, multiple, label }) => {
    const [boards, setBoards] = useState([]);
    const [selectedBoardId, setSelectedBoardId] = useState(multiple ? [] : "");
    const [createBoard, setCreateBoard] = useState(false);

    const getBoards = async () => {
        try {
            const response = await api.get("/tasks/boards/list/select");
            setBoards(response.data.boards);
        } catch (error) {
            console.error("Error fetching boards:", error);
        }
    };


    const handleCreateBoard = () => {
        setCreateBoard(true);
    };

    const handleCloseCreateBoard = () => {
        setCreateBoard(false);
        getBoards();
    }

    useEffect(() => {
        getBoards();
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedBoardId(value);
        if (value === 'new') {
            handleCreateBoard();
        }
        if (onChange && value != 'new') {
            onChange(value);
        }
    };


    return (
        <>


            {createBoard &&
                <TaskBoardAddModal
                    open={createBoard}
                    onClose={handleCloseCreateBoard}
                />}
            <FormControl fullWidth={!width} style={{ width: width || '100%' }} variant={"standard"}>
                <InputLabel>{label || "Selecione o quadro"}</InputLabel>
                <Select
                    value={selectedBoardId}
                    onChange={handleChange}
                    disabled={disabled}
                    multiple={multiple}
                    label={label || "Selecione o quadro"}
                >
                    {boards.map(board => (
                        <MenuItem key={board.id} value={board.id}>
                            {board.title}
                        </MenuItem>
                    ))}

                    <MenuItem value={'new'}>
                        Criar Quadro de Tarefas
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export default SelectBoard;
