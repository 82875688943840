import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(theme => ({
    logo: {
        height: "50px",
        [theme.breakpoints.down('md')]: {
            width: "auto",
            height: "50px",
        }
    },
}));


const Logo = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { getSettingValue } = useContext(SettingsContext);

    const logo = getSettingValue('mainLogo');
    const darkLogo = getSettingValue('darkLogo');

    const isOneCodeDomain = 
    window.location.hostname.includes("onecode") || 
    window.location.hostname.includes("localhost");

    return (
        <>
            {isOneCodeDomain ? (

                theme.mode === 'dark' ? (
                    <img
                        className={classes.logo}
                        src="/logo-christmas/logo-white.png"
                        alt="OneCode Natal!"
                        style={{width: 'auto !important', height: '50px'}}
                    />
                ) : (
                    <img
                        className={classes.logo}
                        src="/logo-christmas/logo-black.png"
                        alt="OneCode Natal!"
                        style={{width: 'auto !important', height: '50px'}}
                    />
                )
            ) : (

                theme.mode === 'dark' && darkLogo ? (
                    <img
                        className={classes.logo}
                        src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${darkLogo}`}
                        //alt=""
                    />
                ) : theme.mode === 'light' && logo ? (
                    <img
                        className={classes.logo}
                        src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${logo}`}
                        //alt=""
                    />
                ) : (
                    <img
                        className={classes.logo}
                        src="/logo-black.png"
                       // alt="Default Logo"
                    />
                )
            )}
        </>
    );
};

export default Logo;
