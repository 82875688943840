import { Badge, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import getSocket from "../../helpers/socket";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { FaPlus, FaTasks, FaTrash } from "react-icons/fa";
import TaskNotification from "./Notification";
import TaskListItem from "./TaskListItem";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom";
import { GoSortDesc, GoSortAsc } from "react-icons/go";

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    avatars: {
        width: "60px",
        background: theme.palette.fancyBackground,
        padding: 0,
        overflowY: 'auto',
        ...theme.scrollbarStylesSoft,
        '&::-webkit-scrollbar': {
            width: '3px'
        }
    },
    avatar: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        cursor: "pointer",
        '&:hover': {
            background: theme.palette.total
        },
        '& img': {
            borderRadius: '50%',
            width: "48px",
            height: "48px"
        },
        position: "relative",
        marginTop: "5px",
        marginLeft: "6px",
        '&>span': {
            position: "absolute",
            bottom: 0,
            right: 10,
        }
    },
    floatScreen: {
        position: "fixed",
        bottom: 0,
        right: "70px"
    },
    floatScreenFull: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        marginTop: "50px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.05)",
        zIndex: 10,
        borderRadius: 20,
        minWidth: 320,
        maxWidth: 320
    },
    noRoomsText: {
        textAlign: "center",
        marginLeft: "20px",
        marginRight: "20px",
        padding: "20px"
    },
    mainContent: {
        minWidth: "270px",
        maxHeight: "80vh",
        overflowY: "auto",
        ...theme.scrollbarStylesSoft
    },
    toggleColapsed: {
        background: theme.palette.primary.main,
        marginLeft: 0,
        paddingLeft: "6px"
    },
    fullHeader: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        padding: "10px"
    },
    fullHeaderLeft: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        width: "20%",
        paddingLeft: 10
    },
    fullHeaderRight: {
        flex: 1,
        display: "flex",
        alignItems: "end",
        justifyContent: "end",
    },
    menuItemCollapsed: {

    },
    menuItemExpanded: {
        background: 'grey'
    },
    openedChatsContainer: {
        position: "fixed",
        bottom: 0,
        right: "30px",
        display: "flex",
        height: 455,
    },
    openedChat: {
        bottom: 0,
        marginLeft: 20,
        width: 300
    },
    minimizedChatsContainer: {
        position: "fixed",
        bottom: 0,
        right: "-100px",
        transition: "right 1s"
    },
    minimizedContainerAnchor: {
        right: 0,
        position: "fixed",
        bottom: 0,
        background: '#fff',
        border: "1px solid #ccc",
        borderRadius: "3px 0 0 3px"
    },
    title: {
        fontWeight: "bold",
        fontSize: "20px",
    },
    linkMore: {
        fontSize: "14px",
        textAlign: "center",
        cursor: 'pointer'
    },
    iconOrder: {
        color: theme.palette.text.main
    },
    buttonLink: {
        cursor: 'pointer',
        display: 'flex', 
        gap: 5, 
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            color: 'unset',
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
        }
    },
    menuItem: {
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        padding: '10px 15px',
        fontSize: '14px !important',
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            color: 'unset',
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        '&:focus': {
            color: 'unset',
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        '& span': {
            fontSize: '14px !important',
        },
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_TASKS") {
        const newTickets = action.payload;

        newTickets.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(ticket);
            }
        });

        return [...state];
    }

    if (action.type === "ADD_TASK") {
        const newTicket = action.payload;

        state.unshift(newTicket);

        return [...state];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }

    if (action.type === "UPDATE_TASK") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TASK_UNREAD_MESSAGES") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);

        console.log('state:', state, ticket, ticketIndex)
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TASK_LAST_MESSAGE") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].lastMessage = ticket.lastMessage;
            state[ticketIndex].updatedAt = ticket.updatedAt;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "DELETE_TASK") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        //console.log('state:', state, ticketId, ticketIndex)
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const TaskMain = ({ handleOpenMenuHeader }) => {
    const classes = useStyles();
    const history = useHistory();
    const [tasks, setTasks] = useReducer(reducer, []);
    const [collapsed, setCollapsed] = useState(true);
    const isEnabled = useState(true);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const [unreadMessagesByRoom, setUnreadMessagesByRoom] = useState({});
    const [orderByAsc, setOrderByAsc] = useState(true);
    const { user } = useContext(AuthContext);
    const { getSettingValue } = useContext(SettingsContext);



    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchList = async () => {
                const { data } = await api.get('/task/notifications/list');
                //console.log(data, 'rooms notif');
                setTasks({
                    type: "LOAD_TASKS",
                    payload: data.tasks
                })


                setUnreadMessages(data.tasks.filter(item => !item.read).length);
            }
            fetchList();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        const countTasks = () => {
            setUnreadMessages(tasks.filter(item => !item.read).length);
        }

        countTasks();
    }, [tasks])

    useEffect(() => {
        let total = 0;
        Object.keys(unreadMessagesByRoom).forEach(key => {
            total += unreadMessagesByRoom[key];
        });
        setUnreadMessages(total);
    }, [unreadMessagesByRoom]);

    const newTaskEvent = data => {
        console.log('here arrive newTaskNotification', data)
        setTasks({
            type: "ADD_TASK",
            payload: data.task
        });
    }

    const deleteAllTasks = data => {
        setTasks({
            type: "RESET"
        });
    }

    const deleteTaskEvent = data => {
        //console.log('here arrive deleteTaskNotification', data)
        setTasks({
            type: "DELETE_TASK",
            payload: data.taskId
        });
    }

    const readCountEvent = data => {
        console.log('here arrive readCountTaskNotification', data) //it arrived here
        setTasks({
            type: "UPDATE_TASK_UNREAD_MESSAGES",
            payload: data.task
        });
        setUnreadMessages(tasks.filter(item => !item.read).length);
    }

    const handleOpenTask = () => {
        if (tasks && tasks.length >= 1 && !collapsed) {
            setCollapsed(true)
        } else if (collapsed) {
            setCollapsed(false)
        } else {
            history.push('/tasks');
        }
    }


    useEffect(() => {
        const publicToken = window.localStorage.getItem('public-token');

        socket.on(`task:notification:new#${publicToken}`, newTaskEvent);
        socket.on(`task:notification:delete#${publicToken}`, deleteTaskEvent);
        socket.on(`task:notification:readCount#${publicToken}`, readCountEvent);
        socket.on(`task:notification:deleteAll#${publicToken}`, deleteAllTasks);

        return () => {
            socket.off(`task:notification:new#${publicToken}`, newTaskEvent);
            socket.off(`task:notification:delete#${publicToken}`, deleteTaskEvent);
            socket.off(`task:notification:delete#${publicToken}`, deleteAllTasks);
            socket.off(`task:notification:readCount#${publicToken}`, readCountEvent);
            socket.off(`task:notification:deleteAll#${publicToken}`, deleteAllTasks);
        }
    }, [user]);


    const handleGoTasks = () => {
        history.push('/tasks');
        setCollapsed(!collapsed);
    }

    const removeNotifications = async () => {
      //  console.log('was clicked')
        try {
        const response  = await api.delete(`/tasks/notifications`);
        console.log(response, 'test response')
        } catch (err) {
            console.log(err, 'test err')
        }
    }

    return (
        <React.Fragment>
            {isEnabled ? (
                <React.Fragment>
                    <TaskNotification notificationSound={getSettingValue("chatNotificationSound")}
                    //onClickOpen={handleRoomClick} 
                    />
                    <div className={classes.root}>
                        <span className={classes.menuItem} onClick={handleOpenTask}>
                            <Badge badgeContent={unreadMessages} color="secondary">
                                <FaTasks size={16} />
                            </Badge> {i18n.t("Tarefas")}
                        </span>

                        {!collapsed && tasks && tasks.length >= 1 && (
                            <div className={classes.floatScreenFull}>
                                <div className={classes.fullHeader}>
                                    <div className={classes.fullHeaderLeft}>
                                        <Typography variant="span" className={classes.linkMore}>Inbox</Typography>
                                    </div>

                                    <div className={classes.fullHeaderRight} style={{ gap: 5 }}>
                                        <Tooltip title="Remover todas notificações">
                                            <IconButton onClick={() => removeNotifications()}>
                                                <FaTrash size={13} className={classes.iconOrder} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={orderByAsc ? 'Ordernar Descendente' : 'Ordernar Ascendente'}>
                                            <IconButton>
                                                {!orderByAsc ?
                                                    <GoSortAsc size={18} className={classes.iconOrder} onClick={() => setOrderByAsc(!orderByAsc)} />
                                                    : <GoSortDesc size={18} className={classes.iconOrder} onClick={() => setOrderByAsc(!orderByAsc)} />}
                                            </IconButton>
                                        </Tooltip>

                                    </div>
                                </div>
                                <div className={classes.pageIndex}>
                                    <div className={classes.mainContent}>
                                        <div className={classes.rooms}>
                                            <TaskListItem tasks={tasks} onHide={() => setCollapsed(!collapsed)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.fullHeader}>
                                    <div className={classes.fullHeaderLeft}>
                                        <Typography variant="span" className={classes.linkMore}
                                            onClick={handleGoTasks}
                                        ><FaPlus size={13} /> Ver todos</Typography>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            ) : (<></>)}
        </React.Fragment>
    );
}

export default TaskMain;