import React, { useContext, useState } from "react";
import {
    Button, Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Menu, MenuItem, Input, IconButton, Tooltip
} from "@mui/material";
import api from "../../services/api";
import { toast } from "react-toastify";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaCheck, FaTrash } from "react-icons/fa";
import { IoReloadOutline } from "react-icons/io5";
import { AuthContext } from "../../context/Auth/AuthContext";


const CustomLaneHeader = ({ label, cards, title, laneId, selectedStatuses, setSelectedStatuses }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [changeTitle, setChangeTitle] = useState(false);
    const [newChangeTitle, setNewChangeTitle] = useState('');
    const { user, permissions } = useContext(AuthContext);
    const hasPermissionEditLane = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:kanban:rename");
    const hasPermissionDelete = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:kanban:delete");

    const handleClickOpen = () => {
        //console.log('was')
        setOpenConfirmDelete(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirmDelete(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRemove = async () => {
        //console.log(laneId, 'test current')
        let patternLanes = [
            'open',
            'bot',
            'pending',
            'paused',
            'group',
            'closed'
        ]
        if (!patternLanes.includes(laneId)) {
            const response = await api.delete(`/tickets/status/${laneId}`);
            if (response.status === 200) {
                setSelectedStatuses((prevStatuses) =>
                    prevStatuses.filter((status) => status !== laneId)
                );
            }
            handleClose();
            handleCloseConfirm();
            toast.success('Bloco deletado com sucesso!');
        } else {
            toast.error('Erro! Não é permitido excluir essa coluna');
        }
    };

    const handleLaneTitleChange = async () => {
        // console.log('test change Title', newChangeTitle);
        if (!newChangeTitle) {
            setChangeTitle(false);
            return;
        }
        await api.put(`/tickets/status/${laneId}`, {
            title: newChangeTitle
        });
        setChangeTitle(false);
        let newStatuses = selectedStatuses;
        setSelectedStatuses([]);
        setSelectedStatuses(newStatuses);

        //fetchTasks();
    };

    const handleLaneTitleReturn = async () => {
        let patternLanes = [
            'Em Atendimento',
            'Bot',
            'Finalizado',
            'paused',
            'group',
            'closed'
        ];
        if (patternLanes.includes(title)) {
            setChangeTitle(false);
            return;
        }
        const response = await api.put(`/tickets/column/${laneId}/title/return`);
        //console.log('test return Title', response);
        setNewChangeTitle(response.data.title);
        setChangeTitle(false);
        let newStatuses = selectedStatuses;
        setSelectedStatuses([]);
        setSelectedStatuses(newStatuses);

        //fetchTasks();
    };

    return (
        <div>



            <Dialog
                open={openConfirmDelete}
                onClose={handleCloseConfirm}
            >
                <DialogTitle>Confirmação de remoção</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Se você remover o bloco, OS ATENDIMENTOS VOLTARÃO PARA SUAS COLUNAS PADRÃO. Você tem certeza?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Cancelar</Button>
                    <Button onClick={handleRemove}>Remover Bloco</Button>
                </DialogActions>
            </Dialog>
            <header
                style={{
                    borderBottom: '2px solid #c5c5c5',
                    paddingBottom: 6,
                    marginBottom: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                {changeTitle ?
                    <div style={{ fontSize: 14, fontWeight: 'bold', display: 'flex' }}>
                        <Input defaultValue={title}
                            onChange={(e) => setNewChangeTitle(e.target.value)} />
                        <Button onClick={handleLaneTitleChange}><FaCheck /></Button>
                    </div> :
                    <div style={{ fontSize: 14, fontWeight: 'bold' }}
                        className={`${title.length >= 15 ? 'marquee' : ''} col-grid-ticket`}
                        onClick={() => (laneId != 'bot' && laneId != 'group' && hasPermissionEditLane) && setChangeTitle(true)}>
                        <span className="big-title-col">{title}</span>
                    </div>}
                <div style={{ display: 'flex', alignItems: 'center' }}>

                    <div style={{ textAlign: 'right', fontSize: 13 }}>
                        <div>

                            {(laneId != 'bot' && laneId != 'group' && hasPermissionEditLane) &&
                                <Tooltip title="Retornar Titulo">
                                    <IconButton
                                        className="icon-grid-reload"
                                        onClick={handleLaneTitleReturn}>
                                        <IoReloadOutline size={14} />
                                    </IconButton>
                                </Tooltip>
                            }
                            {(laneId != 'bot' && laneId != 'group' && hasPermissionDelete) &&
                                <>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        style={{ minWidth: 32 }}
                                    >
                                        <HiDotsHorizontal />
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleClickOpen} style={{ gap: 5 }}>
                                            <FaTrash size={11} /> Remover</MenuItem>
                                    </Menu>
                                </>
                            }
                        </div>
                    </div>



                    {cards &&
                        <Button variant="contained"
                            color="primary"
                            style={{ borderRadius: '100px', padding: 5, minWidth: 24, height: 24, boxShadow: 'none' }}>
                            {cards && cards.length}
                        </Button>}
                </div>
            </header>
        </div>
    )
}


export default CustomLaneHeader;