
import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import { FaPlus, FaTrash, FaPencilAlt, FaStopwatch } from "react-icons/fa";
import { toast } from "react-toastify";
import { i18n } from "../../../translate/i18n";
import toastError from "../../../errors/toastError";
import TaskModal from "../TaskModal";
import { useHistory } from 'react-router-dom';
import TicketBlock from "../FormTask/ticketBlock";
import UserAvatarGroup from "../FormTask/avatarGroup";

const useStyles = makeStyles(theme => ({
    buttonTask: {
        background: 'transparent !important'
    },
    blockTitleTask: {
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    },
    avatares: {
        display: 'flex'
    },
    Task: {
        padding: '15px 10px',
        border: '1px solid lightgrey',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,.04)',
        cursor: 'pointer',
        borderRadius: '5px',
        margin: '8px 0px',
        display: 'flex',
        transition: 'width 0.5s',
        backgroundColor: theme.mode === 'dark' ? 'rgba(0,0,0,0.5)' : '#fff', border: 0,
        "&:hover": {
            boxShadow: 'inset 0px 0px 26px 0px rgba(0,0,0,0.10)',
            backgroundColor: 'transparent !important'
        },
    },
    buttonActionsTask: {
        opacity: 0,
        transition: 'all ease 0.5s'
    },
    contDiv: {
        width: '100%',
    },
    descTask: {
        fontSize: 14,
        color: '#939292',
        height: 20,
        overflow: 'hidden',
        maxWidth: 230
    },
    spanTitleTask: {
        fontWeight: 100,
        fonSize: 13,
        color: '#eee'
    },
    mainPaper: {
        flex: 1,
        //padding: theme.spacing(1),
        borderRadius: 0,
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    helpMessage: {
        padding: "10px"
    },
    tag: {
        padding: 3,
        paddingLeft: 10,
        paddingRight: 10,
        color: "#FFF",
        fontWeight: "bold",
        borderRadius: 4,
        display: "inline-block"
    },
    initialMessage: {
        padding: 10,
        textAlign: 'center'
    },
    mainBox: {
        padding: 20,
        border: "1px solid #CCC",
        borderRadius: 10,
        margin: 20
    },
    options: {
        marginTop: 20
    },
    listItem: {
        listStyle: "none",
        border: "1px solid #CCC",
        padding: 5,
        margin: 5
    },
    listItems: {
        margin: 0,
        padding: 0
    },
    listItemNumber: {
        borderRadius: "50%",
        padding: 15,
        textAlign: "center",
        fontWeight: "bold",
        background: "#666",
        color: "#FFF",
        display: "inline-block",
        fontSize: "16px",
        marginRight: 20
    },
    listItemActionLabel: {
        display: "inline-block",
        marginLeft: 20,
        marginRight: 15
    },
    actions: {
        display: "inline-block"
    },
    cleanButtonWrapper: {
        textAlign: "right"
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    menuBlock: {
        '& .MuiMenu-paper': {
            boxShadow: '0 2px 4px 0 rgba(0,0,0,.04)'
        }
    }
}));

const TaskItem = (item, boardId, users) => {
    const classes = useStyles();
    const [taskModalOpen, setTaskModalOpen] = useState(false);
    const [task, setTask] = useState(item?.item);
    const [taskUser, setTaskUser] = useState(null);
    const history = useHistory();

    const handleOpenTask = () => {
        setTaskModalOpen(true);
    }
    const handleCloseTaskModal = () => {
        setTaskModalOpen(false);
    }

    /*useEffect(() => {
        if (item.users && item.users.length >= 1) {
            console.log('task in taskitem', users, 'item', item.users)
        }
    }, [])*/

    return (
        <>
            {task?.id && <TaskModal
                open={taskModalOpen}
                onClose={handleCloseTaskModal}
                aria-labelledby="form-dialog-title"
                taskId={task.id}

            />}
            <div className={classes.Task}
                style={{ borderLeft: `4px solid ${task?.cardColor ? task.cardColor : 'transparent'}` }}
                onClick={() => history.push(`/task/${task.id}`)}>
                <div className={classes.contDiv}>
                    <div className={classes.titleTask}>
                        <strong>{task?.title}</strong>
                    </div>
                    <div className={classes.descTask}>
                        <p
                            dangerouslySetInnerHTML={{ __html: task?.body }}
                        ></p>
                    </div>
                    <div style={{display: 'flex', paddingTop: 10, paddingBottom: 10}}>
                        {item && item?.users &&
                            <UserAvatarGroup userInfo={item.users}/>
                        }
                    </div>

                    {task.ticket && task.ticket.id ?
                        <TicketBlock item={{ ticket: task.ticket }} TaskItem={true} />
                        : <></>
                    }

                    {task.validateAt ?
                        <p style={{ display: 'flex', alignItems: 'center', gap: 5, fontSize: 12, marginTop: 10, paddingTop: 10, borderTop: '1px solid #rgba(0,0,0,0.5)' }}>
                            <FaStopwatch /> {new Date(task.validateAt).toLocaleDateString('pt-BR')} {new Date(task.validateAt).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                        </p> : <>
                        </>
                    }
                    <div className={classes.buttonActionsTask}>
                        {/* Your task actions like edit, delete */}
                        {/*<Button onClick={() => handleEditTask(task?.id)}>Edit</Button>
                        <Button onClick={() => handleRemoveTask(task?.id)}>Delete</Button>*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TaskItem;