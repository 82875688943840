import React, { useState, useContext, useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik } from 'formik';
import { Avatar, Box, Button, Tooltip, IconButton } from '@mui/material';
import { FaArrowCircleUp, FaTrash } from 'react-icons/fa';
import QuillEditorField from "./textEditor";
import { UserSettingsContext } from "../../../context/Settings/UserSettingsContext";
import { AuthContext } from '../../../context/Auth/AuthContext';
import api from "../../../services/api";
import moment from "moment";
import { FaTimes } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { toast } from "react-toastify";
import { AttachFile, UploadFile } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    commentContainer: {
        display: 'flex'
    },
    labelLink: {
        textDecoration: 'underline',
        cursor: 'pointer !important',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 15,
        gap: 5,
        '& span': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        }
    },
    textLink: {
        textDecoration: 'underline',
        cursor: 'pointer !important',
        color: theme.palette.text.main,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        '& span': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        }
    },
    listAttachments: {
        display: 'flex',
        alignItems: 'center',
        listStyle: 'none',
        gap: 5
    }
}));

const CommentBox = ({ taskId, userInfo }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { settings } = useContext(UserSettingsContext);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null); // Track which comment is being edited
    const [newCommentModified, setNewCommentModified] = useState('');
    const [currentTaskId, setCurrentTaskId] = useState(null);

    const [attachments, setAttachments] = useState([]);
    const apiURL = process.env.REACT_APP_BACKEND_URL;
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };


    const handleUploadAttachment = async () => {
        try {
            const formData = new FormData();
            formData.append('media', selectedFile);
            taskId && formData.append('taskId', taskId);
            const response = await api.post('/task/upload/file', formData);

            // console.log(response, 'test response')
            if (response.data) {
                toast.success('Arquivo anexado com sucesso!')
                setAttachments([...attachments, response.data.file])
                //`${apiURL}/public/${imageUrl}`;
            }
        } catch (error) {
            toast.error('Houve um erro ao anexar o arquivo.')
            console.error("Error handling image insertion:", error);
        }
    };



    const getComments = async () => {
        try {
            const response = await api.get(`/task/${taskId}/comments`);
            if (response.data) {
                setComments(response.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getComments();
        console.log(taskId)
        setCurrentTaskId(taskId)
    }, [taskId]);

    const handleAddComment = async () => {
        if (newComment.trim()) {
            const formData = new FormData();
            formData.append('media', selectedFile);
            formData.append('content', newComment);
            const response = await api.post(`/tasks/${taskId}/comments/add`, formData);
            if (response.data) {
                getComments();
                setNewComment('');
                setSelectedFile(null);
            }
        }
    };

    const handleEditClick = (comment) => {
        if (user.id != comment.userId) {
            return;
        }
        setEditingCommentId(comment.id);
        setNewCommentModified(comment.content);


    };

    const handleUpdateComment = async (id, e) => {
        e.preventDefault();
        try {
            const data = {
                newComment: newCommentModified,
            };
            const response = await api.put(`/task/comment/${id}`, data);

            console.log(response, 'respons, test')

            if (response.status === 200) {
                setEditingCommentId(null); // Close the editor after saving
                getComments(); // Refresh the comments
            }
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    const handleRemoveComment = async (id, e) => {
        e.preventDefault();
        try {
            const response = await api.delete(`/task/comment/${id}`);

            if (response.status === 200) {
                setComments(comments.filter((comment) => comment.id !== id));
            } else {
                console.error('Failed to remove comment');
            }
        } catch (error) {
            console.error('Error removing comment:', error);
        }
    };

    const formatDate = (value) => {
        return value ? moment(value).format("DD/MM/YYYY à[s] HH:mm") : '';
    };

    return (
        <div className={`commentBox ${classes.boxComments}`}>
            <div className={classes.commentList}>
                {comments && comments.length >= 1 && comments.map((comment, index) => (
                    <div key={index}
                        className="item-box"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                            margin: '0px 0px 10px 0px',
                            paddingBottom: 10,
                            borderBottom: '2px solid rgba(0,0,0,0.04)'
                        }}>
                        {comment.userId === user.id && (
                            <button className='remove-comment-button'
                                onClick={(e) => handleRemoveComment(comment.id, e)}>
                                <FaRegTrashCan />
                            </button>
                        )}
                        {editingCommentId !== comment.id ? (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} onClick={() => handleEditClick(comment)}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.content} dangerouslySetInnerHTML={{ __html: comment.content }}></div>
                                </div>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 10, paddingRight: 10 }}>
                                <Field
                                    name="comment"
                                    component={QuillEditorField}
                                    placeholder="Adicionar comentário"
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textareaField}
                                    size="small"
                                    taskId={taskId}
                                    // theme="bubble"
                                    value={newCommentModified}
                                    onChange={(value) => setNewCommentModified(value)}
                                />
                                <div className={classes.buttonContainer}>
                                    <Button variant="outlined" color="primary" className={classes.button}
                                        onClick={(e) => handleUpdateComment(comment.id, e)}>
                                        <FaArrowCircleUp />
                                    </Button>
                                </div>
                            </div>
                        )}

                        {comment.mediaUrl &&
                            <div className={classes.AddMargin}>
                                <label style={{ fontWeight: 'bold' }}>Anexo</label>
                                <ul style={{ marginLeft: 0, paddingLeft: 0 }}>

                                    <li key={index} className={classes.listAttachments}>
                                        <a className={classes.textLink} href={`${apiURL}/public/${comment.mediaUrl}`} target="_blank">{comment.mediaUrl}</a>
                                    </li>

                                </ul>
                            </div>}

                        <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                            {comment.user?.avatar ? (
                                <Avatar
                                    src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${comment.user?.avatar}`}
                                    style={{ width: 24, height: 24 }}
                                />
                            ) : (
                                <Avatar
                                    src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${comment.user?.name}`}
                                    style={{ width: 32, height: 32 }}
                                />
                            )}
                            <small>{comment.user?.name} - {formatDate(comment.updatedAt)}</small>
                        </span>
                    </div>
                ))}
            </div>

            <Box className={classes.commentContainer}>
                <div style={{ paddingTop: 10 }}>
                    {settings && settings.avatar ? (
                        <Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${settings.avatar}`} />
                    ) : (
                        <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${user.name}`} />
                    )}
                </div>
                <div style={{width: '90%', marginLeft: '10px', marginRight: '10px'}}>
                    <Field
                        name="comment"
                        component={QuillEditorField}
                        placeholder="Adicionar comentário"
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        className={classes.textareaField}
                        size="small"
                        taskId={taskId}
                        //theme="bubble"
                        value={newComment}
                        onChange={(value) => setNewComment(value)}
                    />

                    <div className={classes.AddMargin}>

                        {selectedFile ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '1rem', wordBreak: 'break-word' }}>
                                    {selectedFile.name}
                                </span>
                                <IconButton
                                    color="text"
                                    onClick={() => setSelectedFile(null)}
                                >
                                    <FaTimes size={12} />
                                </IconButton>
                            </div>
                        ) : (
                            <div>

                                <span
                                    className={classes.labelLink}
                                    onClick={handleUploadClick}
                                >
                                    <Tooltip
                                        title="Max 5MB">
                                        <AttachFile sx={{ fontSize: 13 }} /> anexar arquivo
                                    </Tooltip>
                                </span>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />

                            </div>)}

                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleAddComment}>
                        <FaArrowCircleUp />
                    </Button>
                </div>
            </Box>




        </div>
    );
};

export default CommentBox;