import React, { useState } from 'react';
import { Paper, Typography, List, ListItem, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const HelpComponent = ({ infoHelp }) => {
    const [expanded, setExpanded] = useState(null);

    const handleExpand = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    return (
        <div>
            {infoHelp &&  (
                <Paper style={{  width: '100%', width: 320 }}>
                    <List>
                        {infoHelp.links && infoHelp.links.map((link, index) => (
                            <React.Fragment key={index}>
                                <ListItem button onClick={() => handleExpand(index)}>
                                    <ListItemText primary={link.title} />
                                    {expanded === index ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                    <div style={{ padding: 16 }}>
                                        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: link.content }} />
                                        {link.image && (
                                            <img src={`/${link.image}`} alt="Help Content" style={{ maxWidth: '100%', marginTop: 8 }} />
                                        )}
                                        {link.video && (
                                            <video controls style={{ maxWidth: '100%', marginTop: 8 }}>
                                                <source src={`/${link.video}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                    </div>
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>
            )}
        </div>
    );
};

export default HelpComponent;
