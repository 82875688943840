import React, { useState, useEffect, useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { IconButton, Paper, useTheme, Modal, Box, Chip } from "@mui/material";
import { FaArrowLeft, FaCheck, FaPencilAlt, FaTrash } from "react-icons/fa";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import AddTaskTicketModal from "../../components/Tasks/AddTaskTicketModal";
import Board from "react-trello";
import getSocket from "../../helpers/socket";
import TaskItem from "../../components/Tasks/Task";
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { HiDotsHorizontal } from "react-icons/hi";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Input } from "@mui/material";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import TaskBoardAddModal from '../../components/Tasks/TaskBoardModal';
import NewLaneFormTask from "../../components/Tasks/TaskBoard/newBlock";
import AiAssistant from '../../components/AiAssistant';
import { AuthContext } from "../../context/Auth/AuthContext";
const socket = getSocket();

const useStyles = makeStyles(theme => ({
	mainPaperHeader: {
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
		background: theme.palette.backgroundColor,
		border: 0
	},
	mainPaper: {
		borderRadius: 0,
		overflowY: "scroll",
		background: 'transparent',
		...theme.scrollbarStylesSoft,
	},
	board: {
		...theme.scrollbarStylesSoft,
	},
	buttonAdd: {
		width: '100%',
		borderRadius: 5,
		border: '1px solid rgba(255,255,255,0.5)',
		fontWeight: 'bold',
		background: 'transparent'
	},
	spanText: {
		color: theme.palette.text.main,
		fontSize: 15,
		fontWeight: 'bold'
	}
}));

const Tasks = () => {
	const classes = useStyles();
	const { user, permissions } = useContext(AuthContext);
	const [taskModalOpen, setTaskModalOpen] = useState(false);
	const [selectedTaskId, setTaskId] = useState(0);
	const [addTaskModalOpen, setNewTaskModalOpen] = useState(false);
	const [addTaskColumnModalOpen, setNewTaskColumnModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [boardData, setBoardData] = useState({ lanes: [] });
	const themeApp = useTheme();
	const [selectedColumn, setSelectedColumn] = useState('');
	const modeTheme = themeApp.mode;
	const [isAddingLane, setIsAddingLane] = useState(false);
	const history = useHistory();
	const boardId = history.location.pathname.split("/")[2];
	const [boardInfo, setBoardInfo] = useState(null);
	const [openLaneFormModal, setOpenLaneFormModal] = useState(false);
	const [editBoard, setEditBoard] = useState(false);
	const hasPermissionEditProj = user.roleId === 1 || permissions.some((permission) => permission.key === 'task:board:edit');
	const hasPermissionAddColumn = user.roleId === 1 || permissions.some((permission) => permission.key === 'task:column:create');
	const hasPermissionAddTask = user.roleId === 1 || permissions.some((permission) => permission.key === 'task:create');

	const handleOpenEditBoard = () => {
		setEditBoard(true);
	}

	const handleCloseEditBoard = () => {
		fetchTasks();
		setEditBoard(false);
	}

	const handleAddLane = () => {
		setOpenLaneFormModal(true);
	}

	const handleCloseModalLane = () => {
		setOpenLaneFormModal(false);
	}

	const handleNewFormFinish = (data) => {
		//console.log(data, 'result')
		fetchTasks();
		handleCloseModalLane();
	}


	const fetchTasks = async () => {
		try {
			setLoading(true);
			const response = await api.get(`/tasks/board/view/${boardId}`);
			if (response.data && response.data.board) {

				//console.log(response.data, 'te')

				//console.log(JSON.stringify(response.data, null, 2), 'test in here');
				response.data?.info && setBoardInfo(response.data.info);

				const lanes = response.data?.board && response.data.board.map(column => ({
					id: column.id,
					title: column.title,
					label: column.tasks.length,
					type: column.type,
					editLaneTitle: true,
					style: {
						width: 280, backgroundColor: modeTheme === 'dark' ? 'rgba(0,0,0,0.5)' : '#e3e3e3',
						borderRadius: 10, color: modeTheme === 'dark' ? '#fff' : '#000'
					},
					detailStyle: { display: 'flex' },
					cards: column.tasks.map(task => ({
						id: task.id.toString(),
						label: task.title,
						title: task.title,
						draggable: true,
						customComponent: () => <TaskItem item={task} users={task.users} />,						//href: "/tickets/" + ticket.id,
						style: { whiteSpace: 'initial', backgroundColor: modeTheme === 'dark' ? 'rgba(0,0,0,0.5)' : '#fff', border: 0, display: 'flex' },
						currentPage: 1,
						cardStyle: {
							display: 'flex'
						},
						body: task.content,
						validateAt: task.validateAt,
						cardColor: `${task.color}`,
						user: task.user,
						users: task.users,
						ticket: task.ticket,
						labelStyle: {
							display: 'flex'
						},
						descriptionStyle: {
							display: 'flex'
						},
						detailStyle: {
							display: 'flex'
						}

					})),
				}));
				setBoardData({ lanes });
			}
			setLoading(false);
		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {
		fetchTasks();

	}, [modeTheme, history]);

	useEffect(() => {
		const tasksEvent = data => {
			//console.log('test, here', data)
			if (data.action === "update") {
				fetchTasks();
			}
			if (data.action === "create") {
				fetchTasks();
			}

			if (data.action === "delete") {
				fetchTasks();
			}
		};

		socket.on(`tasks#${boardId}`, tasksEvent);
		//console.log(boardData, 'test lnes')
		return () => {
			socket.off(`tasks#${boardId}`, tasksEvent);
		};


	}, [boardId]);

	const handleAddColumn = () => {
		setNewTaskColumnModalOpen(true);
		fetchTasks();

	};


	const handleSetIdTask = (id) => {
		setTaskId(id);
		setTaskModalOpen(true);
	};

	const handleOpenAddModalTask = (item) => {
		console.log(item);
		setSelectedColumn(item);
		item && setNewTaskModalOpen(true);
	};

	const handleCloseAddModalTask = () => {
		setNewTaskModalOpen(false);
	};

	const handleCloseColumnAddTask = () => {
		setNewTaskColumnModalOpen(false);
		fetchTasks();
	};

	const handleCardClick = (cardId) => {
		handleSetIdTask(cardId);
	};

	const handleLaneAdd = () => {
		handleAddColumn();
	};


	const handleLaneDragEnd = async (startPos, endPos, lane) => {
		try {

			//console.log('passed in here everything')
			const reorderedLanes = [...boardData.lanes];
			const [movedLane] = reorderedLanes.splice(startPos, 1);
			reorderedLanes.splice(endPos, 0, movedLane);
			const updatedLanes = reorderedLanes.map((item, index) => ({
				id: item.id,
				order: index,
			}));

			if (updatedLanes) {
				await api.post(`/tasks/${boardId}/columns/change`, {
					lanes: updatedLanes,
				});

			}
		} catch (err) {
			console.log(err)
		}
	};

	const handleCardMove = async (cardId, laneId, newLaneId) => {
		try {
			//console.log(cardId, laneId, newLaneId, 'test')
			await api.put(`/tasks/${cardId}/position/${newLaneId}`);
			toast.success('Atendimento atualizado com Sucesso!');

		} catch (err) {
			console.log(err);
		}
	};



	const handleUpdateTasks = (data) => {
		console.log(data, 'test')
		fetchTasks();
	}


	const CustomLaneHeader = ({ label, cards, title, laneId, type }) => {
		const [anchorEl, setAnchorEl] = React.useState(null);
		const open = Boolean(anchorEl)
		const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
		const [changeTitle, setChangeTitle] = useState(false);
		const [newChangeTitle, setNewChangeTitle] = useState('');
		const hasPermissionEditColumn = user.roleId === 1 || permissions.some((permission) => permission.key === 'task:column:edit');
		const hasPermissionDeleteColumn = user.roleId === 1 || permissions.some((permission) => permission.key === 'task:column:delete');

		const handleClickOpen = () => {
			//console.log('was')
			setOpenConfirmDelete(true);
		};

		const handleCloseConfirm = () => {
			setOpenConfirmDelete(false);
		};

		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleRemove = async () => {
			//console.log(laneId, 'test current')
			const response = await api.delete(`/tasks/column/${laneId}`);
			if (response.status === 200) {
				fetchTasks();
			}
			handleClose();
			handleCloseConfirm();
			toast.success('Coluna deletado com sucesso!');
		};

		const handleLaneTitleChange = async () => {
			//console.log('test', newTitle);
			if (newChangeTitle) {
				await api.put(`/tasks/column/${laneId}`, {
					id: laneId,
					title: newChangeTitle,
					//oldTitle: lanes.find(lane => lane.id === laneId).title,
				});
				setChangeTitle(false);

				fetchTasks();
			} else {
				toast.error('Título não pode está vazio!');
			}
		};


		const handleLaneDone = async () => {
			await api.put(`/tasks/column/${laneId}/type/done`);
			fetchTasks();
		};

		return (
			<div>
				<Dialog
					open={openConfirmDelete}
					onClose={handleCloseConfirm}
				>
					<DialogTitle>Confirmação de remoção</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Se você remover o bloco, removerá todos as tarefas deste bloco. Você tem certeza?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseConfirm}>Cancelar</Button>
						<Button onClick={handleRemove}>Remover Coluna</Button>
					</DialogActions>
				</Dialog>
				<header
					style={{
						borderBottom: '2px solid #c5c5c5',
						paddingBottom: 6,
						marginBottom: 10,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					{changeTitle ?
						<div style={{ fontSize: 14, fontWeight: 'bold', display: 'flex' }}>
							<Input defaultValue={title}
								onChange={(e) => setNewChangeTitle(e.target.value)} />
							<Button onClick={handleLaneTitleChange}><FaCheck /></Button>
						</div> :
						<div style={{ fontSize: 14, fontWeight: 'bold' }} onClick={() => hasPermissionEditColumn && setChangeTitle(true)}>
							{title}</div>}
					<div style={{ display: 'flex', alignItems: 'center' }}>

						<div style={{ textAlign: 'right', fontSize: 13 }}>
							<div>
								{type === 'completed' &&
									(<Chip label={'Finalizado'} color="success"
										style={{ marginRight: 5 }}
										variant="outlined" size="small" />)}
								{hasPermissionDeleteColumn &&
									<>
										{type !== 'completed' &&
											<Button
												id="basic-button"
												aria-controls={open ? 'basic-menu' : undefined}
												aria-haspopup="true"
												aria-expanded={open ? 'true' : undefined}
												onClick={handleClick}
												style={{ minWidth: 32 }}
											>
												<HiDotsHorizontal />
											</Button>}
										<Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
										>
											{/*type !== 'completed' &&
										<MenuItem onClick={() => handleLaneDone(laneId)} style={{ gap: 5 }}>
											<FaCheckCircle size={11} />
											Coluna de Finalizado
										</MenuItem>
									*/}
											{type !== 'completed' &&
												<MenuItem onClick={handleClickOpen} style={{ gap: 5 }}>
													<FaTrash size={11} /> Remover</MenuItem>
											}
										</Menu>
									</>
								}
							</div>
						</div>



						{cards &&
							<Button variant="contained"
								color="primary"
								style={{ borderRadius: '100px', padding: 5, minWidth: 24, height: 24, boxShadow: 'none' }}>
								{cards && cards.length}
							</Button>}
					</div>
				</header >
			</div >
		)
	}

	return (
		<>
			<Modal
				open={openLaneFormModal}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						handleCloseModalLane();
					}
				}}>
				<Box display="flex" className="boxesGridModal" justifyContent="center" alignItems="center">
					<NewLaneFormTask
						boardId={boardId}
						onCancel={handleCloseModalLane}
						order={boardData.lanes && boardData.length >= 1 ? boardData.lanes.length : 0}
						onSave={(data) => handleNewFormFinish(data)} />
				</Box>
			</Modal>
			<MainContainer className={classes.mainContainer}>

				<AddTaskTicketModal
					open={addTaskModalOpen}
					isModal={true}
					onClose={handleCloseAddModalTask}
					aria-labelledby="form-dialog-title"
					taskId={selectedTaskId}
					board={boardId}
					column={selectedColumn} />

				{editBoard &&
					<TaskBoardAddModal
						open={editBoard}
						boardId={boardId}
						onClose={handleCloseEditBoard}
					/>}
				<Paper
					className={classes.mainPaperHeader}
					variant="outlined"
				>
					<MainHeader className={classes.mainHeaderBlock} style={{ display: 'flex' }}>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
							<Title>
								<>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
											<span style={{
												borderRadius: 100,
												width: 20,
												height: 20,
												background: `${boardInfo?.color ? boardInfo.color : '#fff'}`
											}}></span>
											{boardInfo?.title}
											{hasPermissionEditProj && <IconButton className={classes.buttonEdit}
												onClick={handleOpenEditBoard}
											><FaPencilAlt size={14} /></IconButton>}
										</div>
										{boardInfo?.content && boardInfo?.content != 'undefined' &&
											<span className={classes.spanText}>{boardInfo.content}</span>}

									</div>
								</>
							</Title>


							<div style={{ display: 'flex', gap: 10 }}>
								{hasPermissionAddColumn &&
								<Button variant="outlined"
									color="primary"
									onClick={handleAddLane}
								>+ Adicionar Coluna
								</Button>
								}
								<Button
									variant="outlined"
									color="primary"
									className={classes.buttonsTicket}
									onClick={() => history.goBack()}
									style={{ maxWidth: 200, gap: 5 }}
								>

									<FaArrowLeft style={{ fontSize: 13 }} /> {i18n.t("Voltar")}



								</Button>

							</div>
						</div>
					</MainHeader>
				</Paper>
				<Paper className={`${classes.mainPaper} boardTaskBody`} variant="outlined">
					<Board
						data={boardData}
						draggable
						handleLaneDragEnd={handleLaneDragEnd}
						handleDragEnd={handleCardMove}
						onCardClick={handleCardClick}
						editable
						canAddLanes
						onLaneAdd={handleLaneAdd}
						style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)', borderRadius: 10 }}
						className={classes.board}
						components={{
							LaneHeader: ({ label, cards, title, id, type }) => (
								<CustomLaneHeader label={label} cards={cards} title={title} laneId={id} type={type} />
							),
							AddCardLink: ({ laneId }) => 
							hasPermissionAddTask && (<button className={classes.buttonAdd}
									onClick={() => handleOpenAddModalTask(laneId)}>
									Adicionar Tarefa
								</button>
							),
							Card: (item) => (
								<TaskItem item={item} boardId={boardId} users={item?.users} />
							),
							NewLaneSection: ({ t, onClick }) => (
								<></>
							),
							NewLaneForm: (props) => (
								<NewLaneFormTask {...props} order={boardData.lanes.length} boardId={boardId} onSave={(data) => handleNewFormFinish(data)} />
							)
						}} />
				</Paper>
			</MainContainer >
			<AiAssistant section="tasks" id={boardId} />
		</>
	);
};

export default Tasks;