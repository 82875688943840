import React, { useState, useEffect, useReducer, useRef, useContext } from "react";
import { toast } from "react-toastify";

import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton"; 
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { PiRobot } from "react-icons/pi";
import InputAdornment from "@mui/material/InputAdornment";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import BotModal from "../../components/Bots/modal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import getSocket from "../../helpers/socket";
import ForbiddenPage from "../../components/ForbiddenPage";
import { Can } from "../../components/Can";

const socket = getSocket();

const reducer = (state, action) => {
    if (action.type === "LOAD_BOTS") {
        const bots = action.payload;
        const newBots = [];

        bots.forEach(bot => {
            const botIndex = state.findIndex(u => u.id === bot.id);
            if (botIndex !== -1) {
                state[botIndex] = bot;
            } else {
                newBots.push(bot);
            }
        });

        return [...state, ...newBots];
    }

    if (action.type === "UPDATE_BOTS") {
        const bot = action.payload;
        const botIndex = state.findIndex(u => u.id === bot.id);

        if (botIndex !== -1) {
            state[botIndex] = bot;
            return [...state];
        } else {
            return [bot, ...state];
        }
    }

    if (action.type === "DELETE_BOT") {
        const botId = action.payload;

        const botIndex = state.findIndex(u => u.id === botId);
        if (botIndex !== -1) {
            state.splice(botIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    mainContainer: {
        background: theme.palette.fancyBackground,
    },
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        overflow: 'hidden'
    },
    mainPaperTable: {
        flex: 1,
        overflow: 'auto',
        height: '68vh',
        ...theme.scrollbarStylesSoft,
    },
    totalsText: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.softBackground,
        color: theme.palette.textPrimary
    },
    buttonsTicket: {
        height: 40,
        borderRadius: '5px!important',
        display: 'inline-flex',
        alignItems: 'center',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
    }

}));

const Typebots = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedBot, setSelectedBot] = useState(null);
    const [deletingBot, setDeletingBot] = useState(null);
    const [botModalOpen, setBotModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [countBots, setCountBots] = useState(0);
    const [bots, dispatch] = useReducer(reducer, []);
    const [countEnabled, setCountEnabled] = useState(0);
    const [isValidCredentials, setIsValidCredentials] = useState({});
    const [openingTypebot, setOpeningTypebot] = useState(false);
    const [typebotActive, setTypebotActive] = useState(false);
    const countBotsRef = useRef();

    countBotsRef.current = countBots;

    const { user } = useContext(AuthContext);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchBots = async () => {
                try {
                    const { data } = await api.get("/typebots/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_BOTS", payload: data.bots });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchBots();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const botEvent = data => {
            if (data.action === "create") {
                setCountBots(countBotsRef.current + 1);
            }
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_BOTS", payload: data.bot });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_BOT", payload: +data.botId });
            }
        }

        socket.on("bot", botEvent);

        return () => {
            socket.off("bot", botEvent);
        };
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchBots = async () => {
                try {
                    const { data } = await api.get(`/typebots/typebot/url`);
                    const url = data.url;
                    if (url) {
                        setTypebotActive(true);
                    } else {
                        setTypebotActive(false);
                    }
                } catch (err) {
                    toastError(err);
                }
            };
            fetchBots();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    const handleOpenBotModal = () => {
        setSelectedBot(null);
        setBotModalOpen(true);
    };

    const handleCloseBotModal = () => {
        setSelectedBot(null);
        setBotModalOpen(false);
    };

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditBot = bot => {
        setSelectedBot(bot);
        setBotModalOpen(true);
    };

    const handleToggleEnabled = async (bot) => {
        try {
            await api.put(`/typebots/${bot.id}`, { enabled: !bot.enabled });
            if (bot.enabled) {
                setCountEnabled(countEnabled - 1);
            } else {
                setCountEnabled(countEnabled + 1);
            }
        } catch (err) {
            toastError(err);
        }
    }

    const handleDeleteBot = async botId => {
        try {
            await api.delete(`/typebots/${botId}`);
            toast.success(i18n.t("bots.toasts.deleted"));
            setCountBots(countBots - 1);
            setCountEnabled(countEnabled - 1);
        } catch (err) {
            toastError(err);
        }
        setDeletingBot(null);
        setSearchParam("");
        setPageNumber(1);
    };

    let pageNumberInterval = null;

    const loadMore = () => {
        if (pageNumberInterval) {
            clearInterval(pageNumberInterval);
        }
        pageNumberInterval = setInterval(() => {
            setPageNumber(prevPageNumber => prevPageNumber + 1);
            clearInterval(pageNumberInterval);
        }, 300);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOpenTypebot = e => {
        setOpeningTypebot(true);
        (async() => {
            try {
                const { data } = await api.get(`/typebots/typebot/url`);
                const url = data.url;
                if (url) {
                    window.open(url, "_blank");
                } else {
                    toast.error(i18n.t("bots.toasts.typebotNotAvailable"));
                }
                setOpeningTypebot(false);
            } catch (err) {
                toastError(err);
                setOpeningTypebot(false);
            }
        })();
    }

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingBot &&
                    `${i18n.t("bots.confirmationModal.deleteTitle")} ${deletingBot.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteBot(deletingBot.id)}
            >
                {i18n.t("bots.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <BotModal
                open={botModalOpen}
                onClose={handleCloseBotModal}
                aria-labelledby="form-dialog-title"
                botId={selectedBot && selectedBot.id}
                initialValues={selectedBot}
            />

            {/**  <div className={classes.totalsText}>

            </div>*/}
            <Can
                role={user.roleId}
                perform="bots:show:page"
                yes={() => (
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        onScroll={handleScroll}
                    >
                        <MainHeader>
                            <Title><PiRobot size={24} /> {i18n.t("bots.typebot.title")}</Title>
                            <MainHeaderButtonsWrapper>

                                <TextField
                                    placeholder={i18n.t("bots.searchPlaceholder")}
                                    type="search"
                                    size="small"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "gray" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Can
                                    role={user.roleId}
                                    perform="bots:create"
                                    yes={() => (<>
                                        {typebotActive && <Button variant="contained" color="primary" onClick={handleOpenTypebot} disabled={openingTypebot}>
                                            { openingTypebot ? i18n.t("bots.buttons.opening_typebot") : i18n.t("bots.buttons.open_typebot") }
                                        </Button>}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleOpenBotModal}
                                            className={classes.buttonsTicket}
                                        >
                                            <AddIcon style={{ fontSize: 13 }} /> {i18n.t("bots.buttons.add")}
                                        </Button>
                                    </>)}
                                    no={() => <>
                                    </>}
                                />
                            </MainHeaderButtonsWrapper>
                        </MainHeader>
                        <Paper
                            className={classes.mainPaperTable}
                            variant="outlined"
                            onScroll={handleScroll}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            {i18n.t("bots.table.name")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("bots.table.enabled")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("bots.table.connection")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("bots.table.typebotBotUrl")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("bots.table.actions")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {bots.map(botItem => (
                                            <TableRow key={botItem.id}>
                                                <TableCell align="center">
                                                    {botItem.name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {botItem.enabled ? 'Habilitado' : 'Desabilitado'}
                                                </TableCell>
                                                <TableCell align="center">{botItem.connection && botItem.connection.name}</TableCell>
                                                <TableCell align="center">{botItem.typebotBotUrl}</TableCell>
                                                <TableCell align="center">
                                                    <Can
                                                        role={user.roleId}
                                                        perform="bots:edit:any"
                                                        yes={() => (
                                                        
                                                                botItem.enabled && <IconButton
                                                                    size="small"
                                                                    onClick={() => handleEditBot(botItem)}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            )}
                                                        no={() => <></>}
                                                    />

                                                    
                                                    <IconButton
                                                        size="small"
                                                        aria-label={botItem.enabled ? "Desabilitar" : "Habilitar"}
                                                        onClick={e => {
                                                            handleToggleEnabled(botItem);
                                                        }}
                                                    >
                                                        {botItem.enabled ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faLockOpen} />}
                                                    </IconButton>
                                                    <Can
                                                        role={user.roleId}
                                                        perform="bots:delete:any"
                                                        yes={() => (
                                                            <IconButton
                                                                size="small"
                                                                onClick={e => {
                                                                    setConfirmModalOpen(true);
                                                                    setDeletingBot(botItem);
                                                                }}
                                                            >
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        )}
                                                        no={() => <></>}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {loading && <TableRowSkeleton columns={5} />}
                                    </>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Paper>
                )}
                no={() => <>
                    <ForbiddenPage />
                </>}
            />
        </MainContainer>
    );
};

export default Typebots;
