import React, { useContext, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import makeStyles from '@mui/styles/makeStyles';
import ChartComponent from "./Chart";
import SelectTags from "../../components/SelectTags";
import SelectUsers from "../../components/SelectUsers";
import SelectQueues from "../../components/SelectQueues";
import SelectContacts from "../../components/SelectContacts";
import DateRangePicker from "../../components/DateRangePicker";
import * as moment from 'moment';
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import SelectStatus from "../../components/SelectStatus";
import ChartDailyTicketsComponent from "./ChartDailyTickets";
import ColorChartSelect from "../../helpers/colorChart";
import MultipleChartComponent from "./MultipleChart";
import ChartResponseTimeComponent from "./ChartResponseTime";
import ChartFeedback from "./ChartFeedback";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        padding: 10,
        overflow: 'hidden'
    },
    fixedHeightPaper: {
        //padding: theme.spacing(2),
        padding: 6,
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
        borderRadius: 0
    },
    highchartPaper: {
        height: 'auto',
        paddingLeft: '10px',
        marginBottom: 10,
        maxWidth: '100%',
        boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    blockListPaper: {
        height: 'auto',
        paddingLeft: '10px',
        paddingTop: '15px',
        paddingBottom: '15px',
        marginBottom: 10,
        maxWidth: '100%',
        rounded: 10,
        boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    filterContainer: {
        backgroundColor: '#FFF',
        marginBottom: 10
    },
    paper: {
        padding: theme.spacing(2),
        boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    wrapperSelect: {
        padding: 0
    },
    totalPaper: {
        fontSize: 20
    }
}));

const Report = () => {
    const classes = useStyles();
    const [period, setPeriod] = useState([moment().subtract(6, 'days').toDate(), moment().toDate()]);
    const [userIds, setUserIds] = useState([]);
    const [queueIds, setQueueIds] = useState([]);
    const [contactIds, setContactIds] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [loadingResponseTime, setLoadingResponseTime] = useState(false);
    const [loadingTicketTime, setLoadingTicketTime] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState('nivo');
    const { user, permissions } = useContext(AuthContext);
    const hasPermissionAll = permissions.some((permission) => permission.key === 'dashboard:show:reports:all');
    const hasPermissionQueue = permissions.some((permission) => permission.key === 'dashboard:show:reports:all:queue');

    const handleChangeUserIds = (values) => {
        setUserIds(values.map((item) => item.id));
    }

    const handleChangeQueueIds = (values) => {
       // console.log(values, 'test', 'queueIds')
        setQueueIds(values.map((item) => item.id));
    }
    const handleChangeContactIds = (values) => {
        setContactIds(values.map((item) => item.id));
    }
    const handleChangeTagIds = (values) => {
        setTagIds(values.map((item) => item.id));
    }

    const handleChangeStatuses = (values) => {
        setStatuses(values.map((item) => item.id));
    }


    const handleChangeSelectedTheme = (data) => {
        //console.log(data.target.value)
        setSelectedTheme(data.target.value)
        window.localStorage.setItem('selectedThemeChart', data.target.value);
    }


    let checkUserIds = userIds && userIds.length >= 1
        ? userIds
        : (hasPermissionAll || user.roleId === 1 || hasPermissionQueue)
            ? null
            : [user?.id];

    let checkQueueIds;
    if (queueIds && queueIds.length >= 1) {
        if (hasPermissionQueue || user.roleId === 1 || hasPermissionAll) {
            checkQueueIds = queueIds;

        } else {
            checkQueueIds = user.queues.map(queue => queue.id);
        }

    } else {
        checkQueueIds = null;
    }


    /* useEffect(() => {
         
         async function getReportUser() {S
             if (user && user.roleId === 1 || hasPermissionAll) {
                 setUserIds([]);
             } else if (user && user.queues && user.queues.length >= 1 && hasPermissionQueue) {
                     user.queues.forEach((values) => {
                         if (!queueIds.includes(values.id)) {
                             setQueueIds((prevQueueIds) => [...prevQueueIds, values.id]);
                         }
                     });                
             } else {
                 setUserIds([user?.id]);
             }
         }
     
         getReportUser();
         console.log('checkUserIds', userIds, 'checkQueueIds', queueIds, 'Reportt');
     }, [user, hasPermissionAll, hasPermissionQueue]);*/

    return (
        <div>
            <Container maxWidth={false} className={classes.container}>
                <Grid container className={classes.filterContainer}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>

                                {(hasPermissionAll || user.roleId === 1) ? (
                                    <>

                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <DateRangePicker
                                                    onChange={(p) => setPeriod(p)}
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectStatus outlined disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeStatuses} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectContacts disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeContactIds} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectQueues multiple={true} disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={(values) => handleChangeQueueIds(values)} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectUsers multiple={true} disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeUserIds} queueIds={queueIds} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectTags outlined disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeTagIds} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={12} style={{ padding: 0, margin: 0, marginTop: 10 }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <FormControl size="small" variant="outlined" style={{ maxWidth: 330 }}>
                                                    <ColorChartSelect selectedTheme={selectedTheme} handleSelectChange={handleChangeSelectedTheme} />
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        {user && user.queues && user.queues.length >= 1 && hasPermissionQueue ? (
                                            <Grid item xs={12} md={user.queues.length >= 3 ? 12 : 4}>
                                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, paddingBottom: 5, borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                                    <h5 style={{ marginBottom: 0 }}>Setores</h5>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                        {user.queues.map((values) => (
                                                            <h6 key={values.id} style={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: 4, textTransform: 'capitalize', marginBottom: 0 }}>
                                                                {/* <div style={{ width: 15, height: 15, background: values.color, borderRadius: 15 }}></div> */}
                                                                - {values.name}
                                                            </h6>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Grid>) : (
                                            <Grid item xs={12} md={4}>
                                                <h5 style={{ marginBottom: 0 }}>Usuário</h5>
                                                <h4 style={{ textTransform: 'capitalize' }}>{user.name}</h4>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={user.queues.length >= 3 && hasPermissionQueue ? 6 : 4}>
                                            <div className={classes.wrapperSelect}>
                                                <DateRangePicker
                                                    onChange={(p) => setPeriod(p)}
                                                    defaultValue={period}
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={user.queues.length >= 3 && hasPermissionQueue ? 6 : 4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectContacts
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                    onChange={handleChangeContactIds}
                                                />
                                            </div>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>


                <Can
                    role={user.roleId}
                    perform="dashboard:ticket-number"
                    yes={() => (
                        <MultipleChartComponent
                            contactIds={contactIds}
                            queueIds={checkQueueIds}
                            tagIds={tagIds}
                            userIds={checkUserIds}
                            statuses={statuses}
                            period={period}
                            endpoint="/report/tickets-reviews/"
                            title="Atendimentos Qualificados"
                            setLoading={setLoadingTickets}
                            yAxisTitle="Número de Atendimentos"
                            theme={selectedTheme}
                        />
                    )}
                    no={() => <></>}
                />



                <Grid item xs={12} md={12}>
                    <Can
                        role={user.roleId}
                        perform="dashboard:ticket-number"
                        yes={() => (
                            <Paper className={classes.highchartPaper}>
                                <ChartComponent
                                    contactIds={contactIds}
                                    queueIds={checkQueueIds}
                                    tagIds={tagIds}
                                    userIds={checkUserIds}
                                    statuses={statuses}
                                    period={period}
                                    endpoint="/report/ticket-number/"
                                    title="Atendimentos"
                                    setLoading={setLoadingTickets}
                                    yAxisTitle="Número de Atendimentos"
                                    theme={selectedTheme}
                                    unit="number"
                                />
                            </Paper>
                        )}
                        no={() => <></>}
                    />
                </Grid>


                <Grid item xs={12} md={12}>
                    {/*<Can
                        role={user.roleId}
                        perform="dashboard:contacts-chart"
                    yes={() => (*/}
                            <Paper className={classes.highchartPaper}>
                                <ChartComponent
                                    contactIds={contactIds}
                                    queueIds={checkQueueIds}
                                    tagIds={tagIds}
                                    userIds={checkUserIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/contact-numbers/"
                                    title="Novos Contatos"
                                    yAxisTitle="Contatos"
                                    theme={selectedTheme}
                                    subtitle="total"
                                    unit="number"
                                />
                            </Paper>{/*})}
                        no={() => <></>
                    />*/}
                </Grid>

                <Grid item xs={12} md={12}>
                    {/*<Can
                        role={user.roleId}
                        perform="dashboard:contacts-chart"
                        yes={() => (*/}
                            <Paper className={classes.highchartPaper}>
                                <ChartComponent
                                    contactIds={contactIds}
                                    queueIds={checkQueueIds}
                                    tagIds={tagIds}
                                    userIds={checkUserIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/rush-hours"
                                    title="Horários de Pico"
                                    yAxisTitle="Horários"
                                    theme={selectedTheme}
                                    subtitle="total"
                                    unit="number"
                                />
                            </Paper> {/*)}
                        no={() => <></>}
                        />*/}
                </Grid>


                <Grid item xs={12} md={12}>
                    {/*<Can
                        role={user.roleId}
                        perform="dashboard:contacts-chart"
                    yes={() => (*/}
                            <Paper className={classes.highchartPaper}>
                                <ChartFeedback
                                    contactIds={contactIds}
                                    queueIds={checkQueueIds}
                                    tagIds={tagIds}
                                    userIds={checkUserIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/tickets-feedback"
                                    title="Avaliações de Atendimentos do Cliente"
                                    yAxisTitle="Avaliações"
                                    theme={selectedTheme}
                                    subtitle="total"
                                    unit="number"
                                />
                            </Paper>{/*)}
                        no={() => <></>}
                />*/}
                </Grid>

                <Grid item xs={12} md={12}>
                    <Can
                        role={user.roleId}
                        perform="dashboard:ticket-response-time"
                        yes={() => (
                            <Paper className={classes.highchartPaper}>
                                <ChartComponent
                                    contactIds={contactIds}
                                    queueIds={checkQueueIds}
                                    tagIds={tagIds}
                                    userIds={checkUserIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/ticket-response-time/"
                                    title="Tempo médio de Aceite do Aguardando"
                                    yAxisTitle="Tempo médio de Resposta Atendimentos em minutos"
                                    theme={selectedTheme}
                                    subtitle="minutos"
                                    unit="time"
                                />
                            </Paper>)}
                        no={() => <></>}
                    />
                </Grid>


                <Grid item xs={12} md={12}>
                    <Can
                        role={user.roleId}
                        perform="dashboard:ticket-response-time"
                        yes={() => (
                            <Paper className={classes.highchartPaper}>
                                <ChartResponseTimeComponent
                                    contactIds={contactIds}
                                    queueIds={checkQueueIds}
                                    tagIds={tagIds}
                                    userIds={checkUserIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/message-response-time/"
                                    title="Tempo Médio de Resposta das Mensagens"
                                    yAxisTitle="Tempo médio de Resposta Mensagens em minutos"
                                    theme={selectedTheme}
                                    subtitle="minutos"
                                    unit="time"
                                />
                            </Paper>)}
                        no={() => <></>}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Can
                        role={user.roleId}
                        perform="dashboard:ticket-number"
                        yes={() => (
                            <Paper className={classes.highchartPaper}>
                                <ChartDailyTicketsComponent
                                    contactIds={contactIds}
                                    queueIds={checkQueueIds}
                                    tagIds={tagIds}
                                    userIds={checkUserIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/daily-ticket-number/"
                                    title="Atendimentos por dia"
                                    yAxisTitle="Numero de atendimentos"
                                    theme={selectedTheme}
                                    subtitle="total"
                                    unit="number"
                                />
                            </Paper>)}
                        no={() => <></>}
                    />
                </Grid>                
            </Container>
        </div >
    );
};

export default Report;


