import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
//import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import SelectWhatsapps from "../SelectWhatsapps";
import { Circle } from '@uiw/react-color';
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";
import AvatarUploader from "../AvatarUpload";
import TimeInOutLogin from "../TimeInOutLogin";
import UserRoleSelect from "../UserRoleSelect";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    modal: {
        borderRadius: 20,
    },
    flexis: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: 'wrap',
        overflow: 'auto',
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#DDDDDD",
        }
    },
    fifty: {
        width: "47%"
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    label: {
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        display: 'flex'
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    inputFile: {
        display: 'none',
    },
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Nome muito curto!")
		.max(50, "Nome muito longo!")
		.required("Nome é obrigatório!"),
	password: Yup.string()
		.required("Senha é obrigatória")
		.min(8, "A senha deve ter no mínimo 8 caracteres")
		.matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
		.matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
		.matches(/[0-9]/, "A senha deve conter pelo menos um número")
		.matches(/[@$!%*?&#]/, "A senha deve conter pelo menos um caractere especial"),
	email: Yup.string().email("Email inválido").required("Requerido"),
});

const UserModal = ({ open, onClose, userId }) => {
    const classes = useStyles();

    const initialState = {
        name: "",
        email: "",
        password: "",
        profile: "user",
    };

    //const { user: loggedInUser } = useContext(AuthContext);

    const [user, setUser] = useState(initialState);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [selectedUserRoleId, setSelectedUserRoleId] = useState(0);
    const [hex, setHex] = useState('#F44E3B');
    const [Oldhex, setOldHex] = useState('');
    const { settings, handleChangeSetting } = useContext(UserSettingsContext);
    const [avatar, setAvatar] = useState(null);
    const [isRestrictedTime, setIsRestrictedTime] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [times, setTimes] = useState({
        dom: { open: '00:00', close: '23:00', isClosed: true },
        seg: { open: '00:00', close: '23:00', isClosed: false },
        ter: { open: '00:00', close: '23:00', isClosed: false },
        qua: { open: '00:00', close: '23:00', isClosed: false },
        qui: { open: '00:00', close: '23:00', isClosed: false },
        sex: { open: '00:00', close: '23:00', isClosed: false },
        sab: { open: '00:00', close: '23:00', isClosed: true }
        
    });

    useEffect(() => {
        const fetchUser = async () => {
            if (!userId) return;
            try {
                const { data } = await api.get(`/users/${userId}`);
                setUser(prevState => {
                    return { ...prevState, ...data };
                });
                if (data.isTimeRestrict) {
                    setIsRestrictedTime(true);
                    setTimes(data.restrictedTime);
                }


                if (data.settings && data.settings.colorDefault) {
                    setOldHex(data.settings.colorDefault)
                }

                const userQueueIds = data.queues?.map(queue => queue.id);
                setSelectedQueueIds(userQueueIds);
                setSelectedUserRoleId(data.roleId);
            } catch (err) {
                toastError(err);
            }
        };
        fetchUser();
    }, [userId, open]);

	const handleClose = () => {
		setIsRestrictedTime(false);
		setTimes({
			seg: { open: '00:00', close: '23:00', isClosed: false },
			ter: { open: '00:00', close: '23:00', isClosed: false },
			qua: { open: '00:00', close: '23:00', isClosed: false },
			qui: { open: '00:00', close: '23:00', isClosed: false },
			sex: { open: '00:00', close: '23:00', isClosed: false },
			sab: { open: '00:00', close: '23:00', isClosed: true },
			dom: { open: '00:00', close: '23:00', isClosed: true }
		})
		setSelectedQueueIds([]);
		setSelectedUserRoleId(0);
		setUser(initialState);
		onClose();
	};

    const handleChangeColor = (color) => {
        setHex(color.hex)
    };

    const handleChangeRestrictedTime = (data) => {
        setIsRestrictedTime(data)
    }

	const handleChangeTimes = (data) => {
		setTimes(data)
	}

    const handleUpdateUser = async (userData) => {
        try {

            await api.put(`/users/${userId}`, {
                ...userData
            });
        } catch (err) {
            toastError(err);
        }
    }

    const handleSaveUser = async (values, actions) => {
        const userData = {
            ...values,
            queueIds: selectedQueueIds,
            roleId: selectedUserRoleId,
            isTimeRestrict: isRestrictedTime,
            restrictedTime: isRestrictedTime ? times : null,
            profile: selectedUserRoleId === 1 ? 'admin' : 'user',
            color: hex,
            oldColor: Oldhex
        };

        try {
            if (userId && selectedUserRoleId) {
                if (avatar) {
                    const formData = new FormData();
                    formData.append("avatar", avatar);

                    await api.post(`user/${userId}/avatar/upload`, formData, {
                        headers: { 'Content-Type': "multipart/form-data" },
                    });
                }

                handleChangeSetting('colorDefault', hex);
                window.localStorage.setItem("colorDefault", hex);

                await handleUpdateUser(userData);
            } else {
                if (selectedUserRoleId) {
                    await api.post("/users", userData);
                } else {
                    toast.error(i18n.t("Adicione o usuário a um Grupo de Permissões"));
                }
            }
        } catch (err) {
            toastError(err);
        }

        if (selectedUserRoleId) {
            actions.resetForm();
            actions.setSubmitting(false);
            handleClose();
            toast.success(i18n.t("userModal.success"));
            //window.location.reload();
        }
    };

	return (
		<div className={classes.root}>

			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="lg"
				fullWidth
				scroll="paper"
				className={classes.modal}
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values, actions);
						}, 400);
					}}
				>
					{({ touched, errors, setFieldValue, values }) => (
						<Form>
							<DialogContent className={`flexis ${classes.flexis}`} dividers>


								<div className={`fifty ${classes.fifty}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
									{/*<label className={classes.label}>Avatar</label>*/}

									<AvatarUploader setAvatar={setAvatar} avatar={user.settings ? user.settings.avatar : ''} />

									{/*avatar || (user.settings && user.settings.avatar) &&
										<Button variant="contained" style={{ background: '#000', color: '#fff', gap: 5 }}
											onClick={handleRemoveAvatar}>
											<FaTrash />
										</Button>
									*/}


									<div style={{ display: 'flex', marginTop: 10, flexDirection: 'column', alignItems: 'center' }}>
										<label className={classes.label}>Cor Padrão</label>
										<Circle
											colors={['#e25242', '#d63865', '#8f31aa', '#613cb0',
												'#4350af', '#4ca6ee', '#54b9d1', '#429388',
												'#67ad5b', '#97c25b', '#cfdb59', '#fcec60',
												'#f6c344', '#f19d38', '#ec6337', '#74574a',
												'#667c89'
											]}

											style={{ justifyContent: 'center' }}
											color={hex}
											onChange={(color) => {
												handleChangeColor(color)
											}}
										/>
									</div>
								</div>

								<div className={`fifty ${classes.fifty}`}>
									<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.name")}
											autoFocus
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.password")}
											type="password"
											name="password"
											error={touched.password && Boolean(errors.password)}
											helperText={touched.password && errors.password}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</div>
									<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.email")}
											name="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</div>
									<Can
										role={user.roleId}
										perform="users:create"
										yes={() => (
											<div className={classes.fieldLine} style={{ marginBottom: 15 }}>
												<QueueSelect
													selectedQueueIds={selectedQueueIds}
													onChange={values => setSelectedQueueIds(values)}
												/>
											</div>
										)}
										no={() => <>
										</>}
									/>
									<div className={classes.multFieldLine} style={{ paddingHorizontal: 0 }}>

										<Can
											role={user.roleId}
											perform="users:create"
											yes={() => (
												<div className={classes.fifty}>
													<UserRoleSelect
														selectedUserRoleId={selectedUserRoleId}
														userRoleId={selectedUserRoleId}
														onChange={value => setSelectedUserRoleId(value)}
													/>
												</div>
											)}
											no={() => (<></>)}
										/>

										<Can
											role={user.roleId}
											perform="users:create"
											yes={() => (
												<SelectWhatsapps
													label="Conexão padrão"
													outlined multiple={false}
													id="connection-selection"
													fullWidth
													style={{ width: '100%' }}
													selectedWhatsapps={values.whatsapp}
													onChange={(value) => setFieldValue("whatsappId", value ? value.id : null)}
													tooltip="Para ser usado nos grupos, quando mais de uma conexão está ativa. Nesse caso, as mensagens enviadas por esse atendente será enviada pela conexão selecionada e não pela padrão. Também será usada no caso de importação de contatos, caso a opção de abrir atendimentos para os contatos importados esteja ativada"
												/>)}
											no={() => (<></>)}
										/>

									</div>



								</div>
								<Can
									role={user.roleId}
									perform="users:create"
									yes={() => (
										<div className="" style={{ width: '100%' }}>
											<TimeInOutLogin
												isRestrictedTime={isRestrictedTime}
												times={times}
												setIsRestrictedTime={(data) => handleChangeRestrictedTime(data)}
												setTimes={(data) => handleChangeTimes(data)} />
										</div>
									)}
									no={() => (<></>)}
								/>


							</DialogContent>

							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>


								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
