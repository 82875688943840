import { FormControl } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import AsyncSelect from "../AsyncSelect";
import { i18n } from "../../translate/i18n";

const SelectUsers = ({ onChange, selectedUsers, disabled, width, outlined, multiple, queueIds, label }) => {
    const [internalSelectedUsers, setInternalSelectedUsers] = useState(multiple ? [] : null);
    const [selectedQueues, setSelectedQueues] = useState([]);
    const handleChange = (event, changedUsers) => {
        setInternalSelectedUsers(changedUsers);
        if (onChange) {
            onChange(changedUsers);
        }
    };

    useEffect(() => {
        if (queueIds) {
            setSelectedQueues(queueIds)
        }
    }, [queueIds])
    //console.log(queueIds, 'test select users')

    const renderSelect = () => {
        return (
            <AsyncSelect
                width="100%"
                url="/users"
                disabled={disabled}
                dictKey="users"
                initialValue={selectedUsers}
                multiple={multiple}
                onChange={handleChange}
                value={internalSelectedUsers}
                placeholder="Selecione os atendentes..."
                label={label ? label : i18n.t("dashboardHome.attendants")}
                disableCloseOnSelect={multiple}
                queueIds={selectedQueues}
            />
        );
    }

    return (
        outlined ?
            <FormControl fullWidth={!width} style={{ width: width || '100%' }} variant="outlined">
                <AsyncSelect
                    url="/users" placeholder="Selecione os atendentes..."
                    dictKey="users" onChange={handleChange}
                    multiple={multiple}
                    width="100%"
                    label={i18n.t("contactModal.title.attendants")}
                    disabled={disabled}
                    disableCloseOnSelect={multiple}
                    queueIds={selectedQueues}
                />
            </FormControl>
            :
            renderSelect()
    );
};

export default SelectUsers;