import React, { useEffect, useContext } from "react";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { Badge, Avatar, Typography, ListItemAvatar, ListItemText, ListItem, IconButton, Tooltip, Menu, MenuItem, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { AiOutlineUser } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { i18n } from "../../../translate/i18n";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import MarkdownWrapper from "../../MarkdownWrapper";
import TicketTime from "../../TicketTime";
import ContactTag from "../../ContactTag";
import ConnectionIcon from "../../ConnectionIcon";
import { SettingsContext } from "../../../context/Settings/SettingsContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    userTag: {
        background: 'transparent',
        fontWeight: 700,
        borderRadius: 20,
        marginRight: 0,
        padding: 1,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 3,
        fontSize: "0.8em",
        whiteSpace: "nowrap"
    },
    listItem: {
        padding: 0
    }
}));

const TicketBlock = (item, taskItem, ticketItem) => {
    const history = useHistory();
    const classes = useStyles();
    const ticket = item.item ? item.item.ticket : item.ticket;
    const { isActive } = useContext(SettingsContext);
	const marqueeActived = isActive('enableMarqueeContactName');

    useEffect(() => {
        //console.log('ticket', ticket, 'item', item)
    }, [])

    const goTicket = () => {
        history.push(`/tickets/${ticket.id}`)
    }
    return (
        ticket ?
            <ListItem dense button className={`${classes.listItem}`}>
                <div style={{ display: 'flex', width: '100%', justifyContent: ticketItem ? 'center': 'center' }} onClick={() => goTicket()}>

                    <div>
                        <small>Atendimento</small>
                        <h4>#{ticket.id}</h4>

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '50%' }}>
                        <ListItemAvatar style={{ marginRight: 10 }}>
                            <Avatar
                                src={ticket?.contact?.profilePicUrl}
                                style={{ width: 50, height: 50, border: `3px solid ${ticket.queue ? ticket.queue.color : '#f9f9f9'}` }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            disableTypography
                            primary={
                                <div className={`${classes.contactNameWrapper} ${ticket.contact?.name.length >= 15 && marqueeActived ? 'marquee' : ''}`} 
                                style={{overflow:'hidden'}}>
                                    <Typography noWrap component="span" variant="body2" color="textPrimary">
                                        {ticket.contact ? ticket.contact.name : '(Sem Contato)'}
                                    </Typography>
                                    {ticket.status === "closed" && (
                                        <Badge className={classes.closedBadge} badgeContent={"Resolvido"} color="primary" />
                                    )}
                                </div>
                            }
                            secondary={
                                !taskItem && <>
                                     <span className={classes.secondaryContentWrapper}>
                                        <span className={classes.secondaryContentFirst}>

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {ticket.lastMessage && (
                                                    <Tooltip title={ticket.lastMessage}>
                                                        <IconButton>
                                                            <FaEye size={14} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Typography
                                                    className={classes.contactLastMessage}
                                                    noWrap
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {ticket.lastMessage ? (
                                                        <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                                                    ) : (
                                                        <br />
                                                    )}
                                                </Typography>
                                            </div>
                                        </span>
                                    </span>
                                    <div className={classes.blockUser}>
                                        {ticket.queue && (
                                            <div style={{ display: 'flex', gap: 10 }}>
                                                <ContactTag tag={ticket.queue} />
                                            </div>
                                        )}
                                    </div>
                                </>
                            }
                        />
                    </div>
                    <div className="box-name-ticket" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <span className={classes.ConnectionTime}>
                            {ticket.lastMessage && !taskItem && (
                                <Typography variant="body2" color="textSecondary">
                                    {isSameDay(parseISO(ticket.updatedAt), new Date())
                                        ? format(parseISO(ticket.updatedAt), "HH:mm")
                                        : <TicketTime updatedAt={ticket.updatedAt} />}
                                </Typography>
                            )}
                        </span>
                        {ticket.user && !ticketItem && (
                            <div className={classes.blockUser}>
                                <div className={classes.userTag}>
                                    <AiOutlineUser /> {ticket.user.name}
                                </div>
                            </div>
                        )}
                        {!taskItem && <div style={{ color: '#666', fontSize: 11, display: 'flex', gap: 5, alignItems: 'center' }}>
                            {ticket.type && <ConnectionIcon connectionType={ticket.type} />}
                            {ticket.whatsapp?.name && <span style={{ textAlign: 'right' }}>{ticket.whatsapp.name}</span>}
                        </div>}
                    </div>
                </div>
            </ListItem> : <></>
    );
};

export default TicketBlock;
