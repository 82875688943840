import React, { useState, useEffect, useReducer, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme, Modal, Box, Button, IconButton } from "@mui/material";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { useHistory, useLocation } from 'react-router-dom';
import useTickets from "../../hooks/useTickets";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import TicketListItem from "../TicketListItem";
import TicketFilters from "./filters";
import { getLocalOptions } from "../../helpers/userOptions";
import getSocket from "../../helpers/socket";
import NewLaneFormTicket from "./newBlockTicket";
import Ticket from "../../components/Ticket/";
import ResolveTicketModal from "../MessagesList/ResolveTicketModal";
import CustomLaneHeader from "./laneHeader";
import TicketBlock from "../../components/Tasks/FormTask/ticketBlock";
import { FaArrowAltCircleRight, FaArrowCircleLeft, FaTimes } from "react-icons/fa";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1),
        ...theme.scrollbarStylesSoft,
    },
    board: {
        ...theme.scrollbarStylesSoft,
    },
    button: {
        background: "#10a110",
        border: "none",
        padding: "10px",
        color: "white",
        fontWeight: "bold",
        borderRadius: "5px",
    },
    buttonAdd: {
        width: '100%',
        minWidth: 250,
        borderRadius: 5,
        border: '1px solid rgba(255,255,255,0.5)',
        fontWeight: 'bold',
        background: 'transparent',
        margin: '0px 10px'
    },
    boxTransferedTicket: {
        position: 'fixed',
        bottom: 13,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        margin: '0 auto',
        left: 'calc(50% - 12.5%)',
        background: theme.mode === 'dark' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
        padding: 10,
        borderRadius: 13,
        color: theme.mode === 'dark' ? '#fff' : '#000',
    },
    boxTransferedTicketIconClose: {
        position: 'absolute',
        top: -10,
        right: -15,
        width: 24,
        minWidth: 24,
        height: 24,
        boxShadow: 'none',
        borderRadius: 100,
        padding: 0
    },
    paragraphTransfered: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        paddingTop: 5,
        paddingBottom: 5,
        borderTop: theme.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
        borderBottom: theme.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
    }
}));

let fixedTickets = [];

const ticketSort = (a, b) => {
    const aInFixedTickets = fixedTickets.indexOf(a.id) > -1;
    const bInFixedTickets = fixedTickets.indexOf(b.id) > -1;
    if (aInFixedTickets && !bInFixedTickets) {
        return -1;
    }
    if (!aInFixedTickets && bInFixedTickets) {
        return 1;
    }
    if (aInFixedTickets == bInFixedTickets) {
        if (a.unreadMessages > 0 && b.unreadMessages == 0) {
            return -1;
        }
        if (a.unreadMessages == 0 && b.unreadMessages > 0) {
            return 1;
        }
    }
    return 0;
}

const ticketSortAsc = (a, b) => {
    if (a.createdAt < b.createdAt) {
        return -1;
    }
    if (a.createdAt > b.createdAt) {
        return 1;
    }
    return 0;
}

const getUnreadmessages = (ticket, user) => {
    if (ticket.status != 'group') {
        return ticket.unreadMessages;
    }

    if (!ticket.metas) {
        return ticket.unreadMessages;
    }

    for (const meta of ticket.metas) {
        if (meta.type == 'unreadMessages' && meta.userId == user.id) {
            ticket.unreadMessages = parseInt(meta.value);
        }
    }
    return ticket.unreadMessages;
}


const reducer = (state, action) => {
    const options = getLocalOptions();
    fixedTickets = options.fixedTickets;
    const sortDir = action.sortDir;
    const user = action.user;
    const setInternalCount = action.setInternalCount;
    if (action.type === "LOAD_TICKETS") {
        const newTickets = action.payload;

        newTickets.forEach(ticket => {
            getUnreadmessages(ticket, user);


            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    //This is about to put on the top unread messages
                    //state.unshift(state.splice(ticketIndex, 1)[0]);
                }


                if (ticket.unreadMessages > 0 && ticket.status === 'paused') {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(ticket);
            }
        });

        /* if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
             state.sort(ticketSort);
         }*/

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        /* if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
             state.sort(ticketSort);
         }*/

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;
        getUnreadmessages(ticket, user);

        const updatedState = state.map(t =>
            t.id === ticket.id ? ticket : t
        );

        if (!updatedState.some(t => t.id === ticket.id)) {
            updatedState.unshift(ticket);
            if (setInternalCount) setInternalCount(prevState => prevState + 1);
        }

        if (fixedTickets && fixedTickets.length > 0) {
            updatedState.sort(ticketSort);
        }

        if (sortDir === 'ASC') {
            updatedState.sort(ticketSortAsc);
        }
        //console.log('test in update_ticket reducer', updatedState)
        return updatedState;


    }

    if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        const ticket = action.payload;

        // Debugging output
        // console.log('Action Payload:', ticket);
        // console.log('State before update:', state);

        // Find the ticket in the state
        const ticketIndex = state.findIndex(t => t.id === ticket.id);

        // console.log('Found ticketIndex:', ticketIndex, 'Ticket:', ticket);

        // If the ticket exists, update its unreadMessages
        if (ticketIndex !== -1) {
            const updatedState = [...state];
            updatedState[ticketIndex] = {
                ...updatedState[ticketIndex],
                lastMessage: ticket.lastMessage,
                unreadMessages: ticket.unreadMessages
            };

            // Optionally, handle sorting after updating
            if (fixedTickets && fixedTickets.length > 0) {
                updatedState.sort(ticketSort);
            }

            if (sortDir === 'ASC') {
                updatedState.sort(ticketSortAsc);
            }

            //console.log('Updated state:', updatedState);

            return updatedState;
        } else {
            // If ticket doesn't exist in the state, add it
            // console.log('Ticket not found, adding new ticket to state.');

            return [
                { ...ticket, unreadMessages: ticket.unreadMessages },
                ...state
            ];
        }
    }

    if (action.type === "UPDATE_TICKET_CONTACT") {
        const contact = action.payload;
        const ticketIndex = state.findIndex(t => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        /* if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
             state.sort(ticketSort);
         }*/
        return [...state];
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
            if (setInternalCount) setInternalCount(prevState => prevState - 1);
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "DELETE_TICKET_BY_CONTACT") {
        const contactId = action.payload;
        const ticketIndex = state.findIndex(t => t.contactId == contactId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
            if (setInternalCount) setInternalCount(prevState => prevState - 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const TicketsGridManager = () => {
    const classes = useStyles();
    const history = useHistory();
    const { info, isActive, hashKey } = useContext(SettingsContext);
    const [showBotList, setShowBotList] = useState(true);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const [sortAscending, setSortAscending] = useState(false);
    const { user, permissions } = useContext(AuthContext);
    const localStorageQueueIds = window.localStorage.getItem('selectedQueueIds') ? JSON.parse(window.localStorage.getItem('selectedQueueIds')) : null;
    const userQueueIds = localStorageQueueIds ? localStorageQueueIds : user && user.queues ? user.queues.map(q => q.id) : [-1];
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [disableAnchor, setDisableAnchor] = useState(false);
    const [fixedTickets, setFixedTickets] = useState([]);
    const [ticketsList, dispatch] = useReducer(reducer, []);
    const [searchParam, setSearchParam] = useState("");
    const [searchById, setSearchByTicketId] = useState(null);
    const [searchOnMessages, setSearchOnMessages] = useState(false);
    const [userIds, setUserIds] = useState([]);
    const [connectionIds, setConnectionIds] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const localStorageStatuses = window.localStorage.getItem('selectedModeList') ? JSON.parse(window.localStorage.getItem('selectedModeList')) : null;
    const [selectedStatuses, setSelectedStatuses] = useState(localStorageStatuses ? localStorageStatuses : ['pending', 'paused', 'open', 'closed', 'group', 'bot']);
    const [forceSearch, setForceSearch] = useState(false);
    const [unreadMessagesSearchMe, setUnreadMessagesSearchMe] = useState(false);
    const [unreadMessagesSearchClient, setUnreadMessagesSearchClient] = useState(false);
    const [lastClientActivityMe, setLastClientActivityMe] = useState(null);
    const [lastClientActivityClient, setLastClientActivityClient] = useState(null);
    const sortDir = sortAscending ? 'ASC' : 'DESC';
    const showAll = showAllTickets ? 'true' : 'false';
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState({ lanes: [] });
    const [resolveTicketModalOpen, setResolveTicketModalOpen] = useState(false);
    const { profile, queues } = user;
    const jsonString = user.queues.map(queue => queue.UserQueue.queueId);
    const [checkedItems, setCheckedItems] = useState([]);
    const [showWhatsappConnection, setShowWhatsappConnection] = useState(false);

    const [internalCount, setInternalCount] = useState(0);
    const [lastTicketBotAdded, setLastTicketBotAdded] = useState(null);
    const [lastTicketPendingAdded, setLastTicketPendingAdded] = useState(0);
    const [lastTicketOpenAdded, setLastTicketOpenAdded] = useState(0);
    const hasPermissionQueue = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:show:without:queue");
    const hasPermissionAccept = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:accept:any");
    const hasPermissionAcceptBot = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:bot:accept:any");
    const hasPermissionViewLastMessage = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:item:historic:list");
    const hasPermissionViewLastMessageBot = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:item:bot:historic:list");

    const themeApp = useTheme();
    const modeTheme = themeApp.mode;
    const [allStatus, setListStatus] = useState(null);
    const location = useLocation();
    const [openLaneFormModal, setOpenLaneFormModal] = useState(false);
    const [openModalTicket, setOpenModalTicket] = useState(false);
    const [motive, setMotive] = useState('');
    const [amount, setAmount] = useState(null);
    const [notes, setNotes] = useState('');
    const [lastDraggedCloseTicketId, setLastDraggedCloseTicket] = useState(null);


    const [lastTransferedTicket, setLastTransferedTicket] = useState(null);
    const [lastTransferedTicketUser, setLastTransferedTicketUser] = useState('');
    const [lastTransferedTicketQueue, setLastTransferedTicketQueue] = useState('');
    const [openInfoModalTicket, setOpenInfoModalTicket] = useState(false);

    const hasPermissionDrag = user.roleId === 1 || permissions.some((permission) => permission.key === "tickets:kanban:move");


    useEffect(() => {
        setOpenInfoModalTicket(true);
        //after 10seconds setOpenInfoModalTicket(false)
    }, [lastTransferedTicket])

    const handleshowAllTickets = () => {
        setUserIds([])
        setShowAllTickets(!showAllTickets)
    }

    const { tickets, hasMore, loading, count, listStatus } = useTickets({
        pageNumber,
        searchParam,
        searchOnMessages,
        searchById: searchById && Number(searchById),
        showAll,
        userIds: userIds && JSON.stringify(userIds),
        connectionIds: connectionIds && JSON.stringify(connectionIds),
        statuses: selectedStatuses && JSON.stringify(selectedStatuses),
        dateFrom,
        dateTo,
        queueIds: selectedQueueIds && JSON.stringify(selectedQueueIds),
        tagIds: selectedTagIds && JSON.stringify(selectedTagIds),
        isGroup: null,
        sortDir: sortAscending ? 'ASC' : 'DESC',
        forceSearch,
        lastClientActivityClient,
        lastClientActivityMe,
        unreadMessagesSearchMe,
        unreadMessagesSearchClient,
        settingChange: hashKey,
        mode: 'kanban'
    });

    useEffect(() => {
        listStatus && setListStatus(listStatus)
        // console.log(listStatus, 'test', 'list   ')
    }, [listStatus]);

    useEffect(() => {
        if (tickets) {
            dispatch({
                type: "LOAD_TICKETS",
                payload: tickets,
                sortDir,
                user,
                setInternalCount
            });
        }
    }, [tickets, sortDir, showAllTickets, pageNumber]);


    useEffect(() => {
        const match = /^\/tickets\/\d+$/.test(location.pathname);
        setOpenModalTicket(match);
    }, [location.pathname]);

    useEffect(() => {
        const localQueueIds = JSON.parse(window.localStorage.getItem('selectedQueueIds'));
        const selectedModeLocalList = JSON.parse(window.localStorage.getItem('selectedModeList'));
        if (localQueueIds) {
            setSelectedQueueIds(localQueueIds)
        }

        if (selectedModeLocalList && selectedModeLocalList.length >= 1) {
            setSelectedStatuses(selectedModeLocalList)
        }
    }, []);



    const handleNewFormFinish = (data) => {
        //console.log(data, 'result')
        let lists = [...selectedStatuses, data.id];
        setSelectedStatuses(lists)
        localStorage.setItem('selectedModeList', JSON.stringify(lists))
        handleCloseModalLane();
       // window.location.reload();
    }

    const cardStyle = (modeTheme) => ({
        whiteSpace: 'initial',
        backgroundColor: modeTheme === 'dark' ? 'rgba(0,0,0,0.5)' : '#fff',
        border: 0,
        borderRadius: 15
    });

    useEffect(() => {
        if (ticketsList && listStatus) {
            const filteredTickets = (statusId) => {
                return ticketsList.filter(ticket => {
                    // If ticket.gridStatus exists, use that for filtering, otherwise use ticket.status
                    const gridStatusMatch = ticket.gridStatus && ticket.gridStatus === statusId;
                    const statusMatch = !ticket.gridStatus && ticket.status === statusId;
                    return gridStatusMatch || statusMatch;
                });
            };

            const titleById = (statusId) => listStatus.find(item => item.id === statusId)?.title || '';

            const laneStyle = (modeTheme) => ({
                width: 280,
                backgroundColor: modeTheme === 'dark' ? 'rgba(0,0,0,0.5)' : '#e3e3e3',
                borderRadius: 10,
                color: modeTheme === 'dark' ? '#fff' : '#000',
                height: '80vh'
            });



            const createLane = (id, title, filteredTickets, modeTheme, selectedStatuses) => ({
                id,
                title,
                label: filteredTickets.length,
                editLaneTitle: true,
                disallowAddingCard: true,
                droppable: id === 'group' ? false : true,
                style: {
                    ...laneStyle(modeTheme),
                    display: selectedStatuses.includes(id) ? 'inline-flex' : 'none'
                },
                cards: filteredTickets.map(ticket => ({
                    id: ticket.id.toString(),
                    label: `Ticket nº ${ticket.id}`,
                    CustomComponent: (
                        <TicketListItem
                            disableAnchor={disableAnchor}
                            ticket={ticket}
                            fixedTickets={fixedTickets}
                            key={ticket.id}
                            showWhatsappConnection={showWhatsappConnection}
                            isChecked={checkedItems.includes(ticket.id)}
                            onCheckboxChange={handleCheckboxChange}
                            mode={"grid"}
                        />
                    ),
                    ticket: ticket,
                    updatedAt: ticket.updatedAt,
                    title: ticket.contact.name,
                    contact: ticket.contact,
                    status: ticket.status,
                    lastMessage: ticket.lastMessage,
                    user: ticket.user,
                    tags: ticket.tags,
                    queue: ticket.queue,
                    whatsapp: ticket.whatsapp,
                    type: ticket.type,
                    unreadMessages: ticket.unreadMessages,
                    draggable: true,
                    href: `/tickets/${ticket.id}`,
                    style: cardStyle(modeTheme),
                    currentPage: 1
                })),
            });


            const popularCards = () => {
                const lanes = listStatus.map(statusItem => {
                    // For each status (e.g., pending, bot, etc.)
                    const filteredTickets = ticketsList.filter(ticket =>
                        ticket.gridStatus === statusItem.id || (!ticket.gridStatus && ticket.status === statusItem.id)
                    );

                    return createLane(
                        statusItem.id,
                        statusItem.title || statusItem.id,
                        filteredTickets,
                        modeTheme,
                        selectedStatuses
                    );
                });
                setFile({ lanes });
            };

            popularCards();
        }
    }, [ticketsList, pageNumber, listStatus, showAll, selectedQueueIds, modeTheme, selectedStatuses, sortAscending]);


    useEffect(() => {
        const socket = getSocket(true);
        const botEvent = data => {
            if (data.type === "create" || data.type === "update") {
                if (data.bot.first) {
                    setShowBotList(data.enabledBotStatus);
                }
            }
        }
        socket.on("bot", botEvent);
        return () => {
            socket.off("bot", botEvent);
        };
    }, []);

    useEffect(() => {
        const socket = getSocket(true);
        const userCanInteractWithAllGroups = user.roleId === 1 || permissions.some((permission) => permission.key === "contacts:groups:see-all");
        const blockNonDefaultConnections = isActive('blockNonDefaultConnections');
        const ignoreUserConnectionForGroups = isActive('ignoreUserConnectionForGroups');
        const userAllowedToGroup = (ticket) => userCanInteractWithAllGroups ? true : ticket.contact.allowUsers.some((item) => item.id == user.id);

        const shouldUpdateTicket = ticket => {
            return (!ticket.userId || ticket.userId === user?.id || showAllTickets) &&

                (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1) &&

                (ticket.status != "group" || userAllowedToGroup(ticket)) &&

                (!blockNonDefaultConnections ||
                    (ticket.status == 'group' && ignoreUserConnectionForGroups) ||
                    !user?.whatsappId || ticket.whatsappId == user?.whatsappId);
        }

        const notBelongsToUserQueues = ticket =>
            ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

        const connectEvent = () => {
            socket.emit("joinNotification");
        }

        const ticketEvent = data => {
            // console.log(data.action, data, 'test ticket event')
            if (data.action === "updateUnread" && (!data.userId || data.userId === user.id)) {
                dispatch({
                    type: "RESET_UNREAD",
                    payload: data.ticketId,
                    //status: selectedStatuses,
                    sortDir,
                    setInternalCount
                });
            }


            if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
                if (data.type === 'transfer') {
                    setLastTransferedTicket(data.ticket);
                    setLastTransferedTicketUser(data.oldUser)
                    //setLastTransferedTicketQueue(data.queueName)
                    // setOpenInfoModalTicket(true);
                }

                ///console.log('test in here updating ticket', data)
                dispatch({
                    type: "UPDATE_TICKET",
                    payload: data.ticket,
                    //status: selectedStatuses,
                    sortDir,
                    user,
                    setInternalCount
                });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_TICKET", payload: data.ticketId, sortDir, setInternalCount });
            }
        }

        const appMessageEvent = data => {

            //console.log('which event', data, 'appMessageEvent')


            if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
                dispatch({
                    type: "UPDATE_TICKET_UNREAD_MESSAGES",
                    payload: data.ticket,
                    sortDir,
                    user,
                    setInternalCount
                });
            }
        }

        const contactEvent = data => {
            if (data.action === "update") {
                dispatch({
                    type: "UPDATE_TICKET_CONTACT",
                    payload: data.contact,
                    sortDir,
                    setInternalCount
                });
            }
        }
        connectEvent();
        socket.on("connect", connectEvent);
        socket.on("ticket", ticketEvent);
        /*
        if (status === 'group') {
            socket.on(`user_options_${user.id}`, userOptionsEvent);
        }*/
        socket.on("appMessage", appMessageEvent);
        socket.on("contact", contactEvent);


        if (socket.connected) {
            connectEvent();
        }

        //popularCards();

        return () => {
            socket.disconnect();
        };
    }, [showAllTickets, user, selectedQueueIds, sortDir, info, pageNumber, lastTicketBotAdded, lastTicketOpenAdded, lastTicketPendingAdded]);

    useEffect(() => {
        dispatch({ type: "RESET", sortDir });
        setPageNumber(1);
    }, [dispatch, sortDir, userIds, forceSearch, hashKey, showAllTickets, selectedQueueIds, forceSearch]);

    useEffect(() => {
        setDisableAnchor(!isActive('showLinks'));
    }, [hashKey]);

    const handleCheckboxChange = (itemId, isChecked) => {
        if (isChecked) {
            setCheckedItems(prevCheckedItems => [...prevCheckedItems, itemId]);
        } else {
            setCheckedItems(prevCheckedItems =>
                prevCheckedItems.filter(id => id !== itemId)
            );
        }
    };


    //Paginate Kanban
    const [lanePageNumbers, setLanePageNumbers] = useState({});
    const [loadingLanes, setLoadingLanes] = useState({});
    const [hasMoreLoading, setHasMore] = useState({});

    const paginate = async (requestedPage, laneId) => {
        if (loadingLanes[laneId]) {
            setHasMore((prev) => ({ ...prev, [laneId]: false }));
            return Promise.resolve([]);
        }

        // Set loading state for the lane
        setLoadingLanes((prev) => ({ ...prev, [laneId]: true }));
        try {
            const currentPage = lanePageNumbers[laneId] || 1;

            const { tickets: newTickets } = await fetchTicketsForLane({
                pageNumber: currentPage + 1,
                statuses: JSON.stringify([laneId]),
            });

            //console.log(newTickets, 'i received')

            if (newTickets.tickets && newTickets.tickets.length > 0) {
                //console.log(newTickets, 'i received new')
                updateLaneState(laneId, newTickets.tickets, currentPage + 1);
                return newTickets.tickets;
            } else {
                setHasMore((prev) => ({ ...prev, [laneId]: false }));
                return [];
            }
        } catch (error) {
            //console.error(`Failed to fetch tickets for lane ${laneId}:`, error);
            return [];
        } finally {
            setLoadingLanes((prev) => ({ ...prev, [laneId]: false }));
        }
    };

    const fetchTicketsForLane = async ({ pageNumber, statuses }) => {
        try {
            const { data: tickets, hasMore } = await api.get("/tickets", {
                params: {
                    searchById: searchById && Number(searchById),
                    searchParam,
                    pageNumber,
                    statuses,
                    showAll,
                    queueIds: selectedQueueIds && JSON.stringify(selectedQueueIds),
                    tagIds: selectedTagIds && JSON.stringify(selectedTagIds),
                    userIds: userIds && JSON.stringify(userIds),
                    connectionIds: connectionIds && JSON.stringify(connectionIds),
                    dateFrom,
                    dateTo,
                    isGroup: null,
                    sortDir: sortAscending ? 'ASC' : 'DESC',
                    searchOnMessages,
                    forceSearch,
                    lastClientActivityMe,
                    lastClientActivityClient,
                    unreadMessagesSearchMe,
                    unreadMessagesSearchClient,
                    mode: 'kanban'
                }
            });
            return { tickets, hasMore };
        } catch (err) {
            //setLoading(false);
            toastError(err);
            return { tickets: [], hasMore: false };
        }
    };



    const updateLaneState = (laneId, newTickets, nextPage) => {
        setFile((prevFile) => {
            const updatedFile = {
                ...prevFile,
                lanes: prevFile.lanes.map((lane) =>
                    lane.id === laneId
                        ? {
                            ...lane,
                            currentPage: nextPage,
                            cards: [...lane.cards, ...newTickets],
                        }
                        : lane
                ),
            };

            //  console.log("State before update:", prevFile);
            // console.log("Updated file:", updatedFile);

            return updatedFile;
        });

        setLanePageNumbers((prev) => ({ ...prev, [laneId]: nextPage }));
    };



    const handleLaneDragEnd = async (startPos, endPos, lane) => {
        try {
            const reorderedLanes = [...file.lanes];
            const [movedLane] = reorderedLanes.splice(startPos, 1);
            reorderedLanes.splice(endPos, 0, movedLane);
            const updatedLanes = reorderedLanes.map((item, index) => ({
                id: item.id,
                position: index,
            }));

            if (updatedLanes) {
                await api.post(`/tickets/columns/change`, {
                    lanes: updatedLanes,
                });

                // console.log(response, updatedLanes, 'teest')
            }
        } catch (err) {
            console.log(err)
        }
    };

    const handleLaneTitleChange = async (laneId, newTitle) => {
        //console.log('test', newTitle);
        await api.put(`/tickets/status/change/${laneId}`, {
            title: newTitle,
            //oldTitle: lanes.find(lane => lane.id === laneId).title,
        });

        setFile((prevFile) => {
            const updatedLanes = prevFile.lanes.map((lane) =>
                lane.id === laneId ? { ...lane, title: newTitle } : lane
            );
            localStorage.setItem('kanbanLanes', JSON.stringify(updatedLanes));

            return { lanes: updatedLanes };
        });
    };

    const handleUpdateTicketStatus = async (e, oldStatus, status, userId, closeNow) => {

        let infoTicket = {
            reason: motive,
            amount: amount,
            notes: notes
        }

        try {
            await api.put(`/tickets/${lastDraggedCloseTicketId.id}`, {
                status: status,
                userId: userId || null,
                closeNow,
                infoTicket
            });
            if (status === "open") {
                history.push('/tickets');
                setTimeout(() => {
                    history.push(`/tickets/${lastDraggedCloseTicketId.id}`); //ver depois
                }, 0);
            }
            else {
                history.push("/tickets");
            }

            setResolveTicketModalOpen(false);
        } catch (err) {
            toastError(err)
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchData = async () => {
                const { data } = await api.get("/whatsapp/show-whatsapp-connection");
                setShowWhatsappConnection(data.show);
            };
            fetchData();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        const options = getLocalOptions();
        setFixedTickets(options.fixedTickets || []);

    }, []);

    const handleCloseModalLane = () => {
        setOpenLaneFormModal(false);
    }


    const handleClean = () => {
        history.push(`/tickets/`);
        //setShowSearchBar(true);
        setSearchParam("");
        setSearchByTicketId(null);
        setUserIds([]);
        setSelectedTagIds([]);
        setConnectionIds([]);
        setDateFrom(null);
        setDateTo(null);
        window.localStorage.removeItem('selectedQueueIds');
        //setisSearched(false);
        //setSelectedStatuses([]);
        setSelectedQueueIds([]);
        //setInternalSelectedQueueIds([]);
        setForceSearch(prev => !prev);
    };

    const handleCloseModalTicket = () => {
        setOpenModalTicket(false);
        history.push('/tickets');
    }

    const handleAddLane = () => {
        //alert('test')
        setOpenLaneFormModal(true);
    }

    const handleResolveTicket = (e) => {
        if (user.roleId !== 1 && permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        //  console.log(lastDraggedCloseTicketId, 'last')
        handleUpdateTicketStatus(e, lastDraggedCloseTicketId.status, "closed", user?.id);
        let newStatuses = selectedStatuses;
        setSelectedStatuses([]);
        setSelectedStatuses(newStatuses);
    }

    const handleResolveTicketFeedback = (e) => {
        if (user.roleId !== 1 && permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        handleUpdateTicketStatus(e, lastDraggedCloseTicketId.status, "feedback", user?.id);
        let newStatuses = selectedStatuses;
        setSelectedStatuses([]);
        setSelectedStatuses(newStatuses);
    }

    const handleResolveTicketNoMessage = (e) => {
        if (permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        handleUpdateTicketStatus(e, lastDraggedCloseTicketId.status, "closed", user?.id, true);
        let newStatuses = selectedStatuses;
        setSelectedStatuses([]);
        setSelectedStatuses(newStatuses);
    }

    const handleCloseLastTransfered = () => {
        setLastTransferedTicket(null);
        setLastTransferedTicketUser('');
    }



    return (
        <>

            {(lastDraggedCloseTicketId && ticketsList && ticketsList.length >= 1) &&
                <ResolveTicketModal
                    open={resolveTicketModalOpen}
                    onClose={() => setResolveTicketModalOpen(false)}
                    onAccept={handleResolveTicket}
                    onAcceptFeedback={handleResolveTicketFeedback}
                    onAcceptNoEndMessage={handleResolveTicketNoMessage}
                    ticket={lastDraggedCloseTicketId}
                    motive={motive}
                    amount={amount}
                    notes={notes}
                    setMotive={setMotive}
                    setAmount={setAmount}
                    setNotes={setNotes}
                />
            }


            <Modal
                open={openModalTicket}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseModalTicket();
                    }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box display="flex" className="boxesGridModal" justifyContent="center" alignItems="center">
                    <Ticket closeModal={handleCloseModalTicket} />
                </Box>
            </Modal>

            <Modal
                open={openLaneFormModal}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseModalLane();
                    }
                }}>
                <Box display="flex" className="boxesGridModal" justifyContent="center" alignItems="center">
                    <NewLaneFormTicket onCancel={handleCloseModalLane} onSave={(data) => handleNewFormFinish(data)} />
                </Box>
            </Modal>
            <div style={{ width: '100%', zIndex: 9 }}>
                <TicketFilters
                    allStatus={allStatus}
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    searchById={searchById}
                    userIds={userIds}
                    setUserIds={setUserIds}
                    setSearchByTicketId={setSearchByTicketId}
                    selectedQueueIds={selectedQueueIds}
                    setSelectedQueueIds={setSelectedQueueIds}
                    searchParam={searchParam}
                    setSearchParam={setSearchParam}
                    selectedStatuses={selectedStatuses}
                    setSelectedStatuses={setSelectedStatuses}
                    showAllTickets={showAllTickets}
                    setShowAllTickets={handleshowAllTickets}
                    sortAscending={sortAscending}
                    setSortAscending={setSortAscending}
                    selectModeList={selectedStatuses}
                    setSelectModeList={setSelectedStatuses}
                    forceSearch={forceSearch}
                    setForceSearch={setForceSearch}
                    handleAddBlock={handleAddLane}
                    handleClean={handleClean}
                />

            </div>
            <div className={classes.root}>
                <Board
                    data={file}
                    draggable={hasPermissionDrag}
                    editable
                    canAddLanes
                    components={{
                        Card: (item) => (
                            <TicketListItem disableAnchor={disableAnchor}
                                ticket={item}
                                fixedTickets={fixedTickets}
                                key={item.id}
                                showWhatsappConnection={showWhatsappConnection}
                                isChecked={checkedItems.includes(item.id)}
                                onCheckboxChange={handleCheckboxChange}
                                mode={"grid"}
                                acceptPermission={hasPermissionAccept}
                                acceptPermissionBot={hasPermissionAcceptBot}
                                viewLastMessage={hasPermissionViewLastMessage}
                                viewLastMessageBot={hasPermissionViewLastMessageBot}
                            />
                        ),
                        LaneHeader: ({ label, cards, title, id }) => (
                            <CustomLaneHeader label={label} cards={cards} title={title} laneId={id}
                                selectedStatuses={selectedStatuses} setSelectedStatuses={setSelectedStatuses}
                            />
                        ),
                        NewLaneSection: ({ t, onClick }) => (
                            <></>
                        ),
                        NewLaneForm: (props) => (
                            <></>
                        ),
                    }}
                    handleLaneDragEnd={handleLaneDragEnd}
                    handleDragEnd={async(ticketId, sourceLaneId, targetLaneId) => {
                        let patternLanes = ['open', 'bot', 'pending', 'paused', 'group', 'closed'];


                        console.log(sourceLaneId, 'test', targetLaneId)
                        if (targetLaneId === 'closed') {
                            let ticket = ticketsList.filter(ticket => ticket.id === Number(ticketId))[0];
                            setLastDraggedCloseTicket(ticket);
                            setResolveTicketModalOpen(true);
                            return false; // Prevents the drag-drop operation for 'closed'
                        }
                    
                        if (targetLaneId === 'group' || sourceLaneId === 'group') {
                            return false; // Prevents drag-drop for 'group'
                        }
                    
                        if (!patternLanes.includes(targetLaneId) || !patternLanes.includes(sourceLaneId)) {
                            try {
                                const response = await api.put(`/tickets/${ticketId}/${sourceLaneId}/${targetLaneId}`);
                                //console.log(response, 'test')
                                if (response.status === 200) {
                                    toast.success('Atendimento atualizado com Sucesso!');
                                }
                            } catch (err) {
                                console.error(err);
                                return false;
                            }
                        } else {
                            try {
                                //VEEEEER AQUI AMANHA RETORNO ERRO
                                const response = await api.put(`/tickets/${ticketId}/${sourceLaneId}/${targetLaneId}`);
                                //console.log(response, 'test2')
                                if (response.status === 200) {
                                    console.log(response, 'response')
                                    toast.success('Atendimento atualizado com Sucesso!');
                                } 
                                else {
                                    return false;
                                }
                            } catch (err) {
                                console.error(err);
                                return false;
                            }
                        }
                    }}
                    style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)', borderRadius: 10 }}
                    className={classes.board}
                    onLaneUpdate={(laneId, data) => handleLaneTitleChange(laneId, data.title)}
                    onLaneScroll={(requestedPage, laneId) => paginate(requestedPage, laneId)}

                />

                {lastTransferedTicket && lastTransferedTicket.id && lastTransferedTicketUser !== user.name ?
                    <div className={`${classes.boxTransferedTicket} boxTransfered`}>
                        <h5 style={{ marginBottom: 10 }}>Um Atendimento foi transferido para {lastTransferedTicketQueue ? ' seu setor' : ' você'}!</h5>
                        <p className={classes.paragraphTransfered}>
                            {lastTransferedTicketQueue ? lastTransferedTicketQueue :
                                <>{lastTransferedTicketUser}  < FaArrowAltCircleRight /> {user.name}</>
                            }
                        </p>
                        <TicketBlock item={{ ticket: lastTransferedTicket }} taskItem={true} ticketItem={true} />
                        <Button variant="contained" color="error" className={classes.boxTransferedTicketIconClose}
                            onClick={handleCloseLastTransfered}
                        >
                            <FaTimes size={14} />
                        </Button>
                    </div>
                    : <></>
                }
            </div>
        </>
    );
};
export default TicketsGridManager;