import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import SelectWhatsapps from "../SelectWhatsapps";
import AsyncSelect from "../AsyncSelect";
import TimeInOutLogin from "../TimeInOutLogin";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const BotSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Nome muito curto!")
        .max(50, "Nome muito grande!")
        .required("Required"),
    typebotBotUrl: Yup.string().required("Required"),
});

const BotModal = ({ open, onClose, botId, initialValues }) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    const initialState = {
        name: "",
        connectionId: null,
        isRestrictedTime: false,
        apiUrl: "",
        typebotBotUrl: "",
        apiToken: "",
        apiBotId: "",
        priority: 1
    };

    const [bot, setBot] = useState(initialState);
    const [isRestrictedTime, setIsRestrictedTime] = useState(false);
    const [times, setTimes] = useState({
		seg: { open: '00:00', close: '23:00', isClosed: false },
		ter: { open: '00:00', close: '23:00', isClosed: false },
		qua: { open: '00:00', close: '23:00', isClosed: false },
		qui: { open: '00:00', close: '23:00', isClosed: false },
		sex: { open: '00:00', close: '23:00', isClosed: false },
		sab: { open: '00:00', close: '23:00', isClosed: true },
		dom: { open: '00:00', close: '23:00', isClosed: true }
	});

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (initialValues) {
                setBot(prevState => ({ ...prevState, ...initialValues }));
            }
            if (!botId) return;

            try {
                const { data } = await api.get(`/typebots/${botId}`);
                if (isMounted.current) {
                    setBot(data);
                    if (data.isRestrictedTime) {
                        setIsRestrictedTime(true);
                        setTimes(data.restrictedTime);
                    } else {
                        setIsRestrictedTime(false);
                    }
                }
            } catch (err) {
                toastError(err);
            }
        })();

        return () => {
            setBot({
                name: ""
            });
        };
    }, [botId, open, initialValues]);

    const handleClose = () => {
        onClose();
        setBot(initialState);
    };

    const handleSaveBot = async values => {
        try {
            const payload = {
                ...values,
                isRestrictedTime: isRestrictedTime,
                restrictedTime: times
            };
            if (botId) {
                await api.put(`/typebots/${botId}`, payload);
            } else {
                await api.post("/typebots", payload);
            }
            toast.success("Bot salvo com sucesso");
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} scroll="paper">
                <DialogTitle>
                    {botId
                        ? `${i18n.t("botModal.title.edit")}`
                        : `${i18n.t("botModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={bot}
                    enableReinitialize={true}
                    validationSchema={BotSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveBot(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <DialogContent dividers>
                                <Field
                                    as={TextField}
                                    label={i18n.t("botModal.form.name")}
                                    autoFocus
                                    name="name"
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                />
                                <SelectWhatsapps multiple={false} selectedWhatsapps={values.connection} outlined fullWidth onChange={value => setFieldValue("connectionId", value ? value.id : null)} />
                                <Field
                                    as={TextField}
                                    label={i18n.t("botModal.form.typebotBotUrl")}
                                    fullWidth
                                    name="typebotBotUrl"
                                    error={
                                        touched.typebotBotUrl && Boolean(errors.typebotBotUrl)
                                    }
                                    helperText={
                                        i18n.t("botModal.form.typebotBotUrlHelp")
                                    }
                                    variant="outlined"
                                    margin="dense"
                                />
                                <div className="" style={{ width: '100%' }}>
                                    <TimeInOutLogin
                                        label="Restrição de horários para o bot"
                                        isRestrictedTime={isRestrictedTime}
                                        times={times}
                                        setIsRestrictedTime={(data) => setIsRestrictedTime(data)}
                                        setTimes={(data) => setTimes(data)} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("botModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {botId
                                        ? `${i18n.t("botModal.buttons.edit")}`
                                        : `${i18n.t("botModal.buttons.add")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default BotModal;
