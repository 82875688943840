import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";

const EditableH1 = ({ field, form, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = () => {
    setIsEditing(false);
    field.onBlur({ target: { name: field.name } });
  };

  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.value);
  };

  return isEditing ? (
    <InputBase
      value={field.value}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus
      fullWidth
      inputProps={{
        style: {
          fontSize: "2.0rem",
          fontWeight: "bold",
          wordBreak: "break-word",
          whiteSpace: "normal",
          overflowWrap: "break-word",
        },
      }}
      {...props}
    />
  ) : (
    <Typography
      variant="h1"
      onClick={() => setIsEditing(true)}
      style={{
        cursor: "pointer",
        fontSize: "2.0rem",
        fontWeight: "bold",
      }}
    >
      {field.value || "Titulo da Tarefa"}
    </Typography>
  );
};

export default EditableH1;