import React, { useState, useEffect, useContext } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import { initUserOptions, cleanInitUserOptions } from "./helpers/userOptions";
import { useMediaQuery } from "@mui/material";
import ColorModeContext from "./layout/themeContext";
import { MutedItemsProvider } from './context/Tickets/MutedItems';
import { UserSettingsContext } from "./context/Settings/UserSettingsContext";
import { TicketCountsProvider } from './context/Tickets/Count';
import "@fontsource/poppins";
import api from "./services/api";
import { pdfjs } from 'react-pdf';
import usePageLifecycle from "./hooks/usePageLifecycle";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const App = () => {
    const [locale, setLocale] = useState();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? 'dark' : 'light');
    const [colorDefault, setColorDefault] = useState(null);

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = createTheme(adaptV4Theme({
        typography: {
            fontFamily: 'Poppins, sans-serif',
        },
        scrollbarStyles: {
            "&::-webkit-scrollbar": {
                width: "15px",
                height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.7)",
                backgroundColor: "#009fdd",
            },
        },
        scrollbarStylesSoft: {
            "&::-webkit-scrollbar": {
                width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: mode === 'light' ? "#DDDDDD" : '#333333',
            }
        },
        scrollbarStylesSoftBig: {
            "&::-webkit-scrollbar": {
                width: "12px",
                backgroundColor: mode === 'light' ? "#ffffff" : '#333333',
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: mode === 'light' ? "#DDDDDD" : '#cccccc',
            }
        },
        palette: {
            mode: mode,
            primary: { main: colorDefault ? colorDefault : "#009fdd" },
            softBackground: mode === 'light' ? '#c8c8c8' : '#666',
            blackWhiteBackground: mode === 'light' ? '#ffffff' : '#121212',
            textPrimary: mode === 'light' ? colorDefault ? colorDefault : "#009fdd" : '#FFFFFF',
            borderPrimary: mode === 'light' ? colorDefault ? colorDefault : "#009fdd" : '#FFFFFF',
            dark: { main: mode === 'light' ? '#333333' : '#DDDDDD' },
            light: { main: mode === 'light' ? '#DDDDDD' : '#333333' },
            lightWhite: { main: mode === 'light' ? '#ffffff' : '#333333' },
            tabHeaderBackground: mode === 'light' ? '#ffffff' : '#666',
            optionsBackground: mode === 'light' ? '#fafafa' : '#333',
            fancyBackground: mode === 'light' ? '#eeeeee' : '#0e2132',
            total: mode === 'light' ? '#fff' : '#222',
            messageIcons: mode === 'light' ? 'grey' : '#DDDDDD',
            inputBackground: mode === 'light' ? '#FFFFFF' : '#333',
            text: { main: mode === 'light' ? '#000000' : '#ffffff' }
        },
        mode
    }, locale));

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    useEffect(() => {
        setColorDefault(window.localStorage.getItem("colorDefault"));
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode, colorDefault]);

    useEffect(() => {
        const fnDebounce = setTimeout(() => {
            initUserOptions();
        }, 500);
        return () => { clearTimeout(fnDebounce); cleanInitUserOptions() };
    }, []);


   /* useEffect(() => {
        const handleBeforeUnload = (event) => {
            const isReload = performance.getEntriesByType("navigation")[0].type === "reload";
            if (!isReload) {
                localStorage.removeItem("token");
                localStorage.removeItem("public-token");
                localStorage.removeItem("user-id");
                api.defaults.headers.Authorization = ``;
            }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);*/

    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <MutedItemsProvider>
                <TicketCountsProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <Routes />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </TicketCountsProvider>
            </MutedItemsProvider>
        </ColorModeContext.Provider>
    );
};

export default App;
