import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { useTicketCounts } from '../../context/Tickets/Count';

const useTickets = ({
    searchParam,
    searchById,
    pageNumber,
    status,
    date,
    showAll,
    queueIds,
    withUnreadMessages,
    tagIds,
    userIds,
    statuses,
    connectionIds,
    dateFrom,
    dateTo,
    isGroup,
    sortDir,
    searchOnMessages,
    forceSearch,
    lastClientActivityMe,
    lastClientActivityClient,
    unreadMessagesSearchMe,
    unreadMessagesSearchClient,
    settingChange,
    mode
}) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [listStatus, setListStatus] = useState([]);
    const [count, setCount] = useState(0);
    const { handleUpdateCounts } = useTicketCounts();

    useEffect(() => {
        setLoading(true);

        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async () => {
                try {
                    const { data } = await api.get("/tickets", {
                        params: {
                            searchById,
                            searchParam,
                            pageNumber,
                            status,
                            date,
                            showAll,
                            queueIds,
                            withUnreadMessages,
                            tagIds,
                            userIds,
                            statuses,
                            connectionIds,
                            dateFrom,
                            dateTo,
                            isGroup,
                            sortDir,
                            searchOnMessages,
                            forceSearch,
                            lastClientActivityMe,
                            lastClientActivityClient,
                            unreadMessagesSearchMe,
                            unreadMessagesSearchClient,
                            mode
                        },
                    });
                    setTickets(data.tickets);
                    setHasMore(data.hasMore);
                    setCount(data.count);
                    setListStatus(data.listStatus);
                    //console.log(data, 'data useTickets')
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            fetchTickets();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [
        searchById,
        pageNumber,
        status,
        date,
        showAll,
        queueIds,
        withUnreadMessages,
        tagIds,
        userIds,
        statuses,
        connectionIds,
        dateFrom,
        dateTo,
        isGroup,
        sortDir,
        searchOnMessages,
        forceSearch,
        lastClientActivityMe,
        lastClientActivityClient,
        unreadMessagesSearchMe,
        unreadMessagesSearchClient,
        settingChange
    ]);

    return { tickets, loading, hasMore, count, listStatus };
};

export default useTickets;
