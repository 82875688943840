import React, { useEffect, useState, useReducer, useContext } from 'react';
import {
  Paper, Typography, Box, ButtonGroup, Button, Grid, useTheme,
  Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar,
  LinearProgress
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { FaClock, FaTable, FaPhone, FaTasks, FaEye, FaPencilAlt, FaTrash } from "react-icons/fa";
import { Computer, Contacts, DoneAll, Pause, Forum, Group, List, LocalOffer, People, PhonelinkRing, Queue, Schedule, Timer } from "@mui/icons-material";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import TaskBoard from '../../components/Tasks/TaskBoard';
import DashboardInfoBox from "../Dashboard/BoxDash";
import api from "../../services/api";
import getSocket from "../../helpers/socket";
import TaskBoardAddModal from '../../components/Tasks/TaskBoardModal';
import { LuLayoutGrid } from "react-icons/lu";
import { FaCheck } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import AiAssistant from '../../components/AiAssistant';

const socket = getSocket();

const useStyles = makeStyles(theme => ({
  mainContainer: {
    background: theme.palette.fancyBackground,
    width: '100%',
    padding: '0px !important'
  },
  mainWrapperModal: {
    background: theme.palette.fancyBackground,
  },
  paperInfo: {
    //padding: theme.spacing(2),
    boxShadow: "none",
    background: 'rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 3,
    overflow: 'auto',
    padding: 10
  },
  mainPaper: {
    flex: 1,
    marginTop: 40,
    borderRadius: 20,
    border: '0px !important',
    marginBottom: 40,
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    },
    ...theme.scrollbarStylesSoftBig
  },
  mainPaperTable: {
    flex: 1,
    border: 0,
    ...theme.scrollbarStylesSoftBig,
  },
  fifty: {
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: '45%'
    },
  },
  board: {
    ...theme.scrollbarStylesSoft,
  },
  buttonAdd: {
    width: '100%',
    borderRadius: 5,
    border: '1px solid rgba(255,255,255,0.5)',
    fontWeight: 'bold',
    background: 'transparent'
  },
  boxCards: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 10,
    marginTop: 10,
    marginBottom: 10,
    padding: 20
  }
}));

const reducer = (state, action) => {

  const user = action.user;

  if (action.type === "LOAD_BOARDS") {
    return [...state];
  }

  if (action.type === "ADD_BOARD") {
    const newBoard = action.payload;
    const messageIndex = state.findIndex(m => m.id === newBoard.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newBoard;
    } else {
      state.push(newBoard);
    }

    return [...state];
  }

  if (action.type === "UPDATE_BOARD") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex(t => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      if (action.status === action.payload.status) {
        state.unshift(ticket);
      }
    }
    return [...state];
  }

  if (action.type === "DELETE_BOARD") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex(t => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const Board = () => {
  const classes = useStyles();
  const { user, permissions } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [checkAll, uncheckAll] = useState(false);
  const [allSelected, setAllSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const themeApp = useTheme();
  const modeTheme = themeApp.mode;
  const [createBoard, setCreateBoard] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [boards, setBoards] = useState([]);
  const [counts, setCounts] = useState({})
  const [mode, setMode] = useState('grid');
  const history = useHistory();
  const [editedBoard, setEditedBoard] = useState(null);
  const [editBoard, setEditBoard] = useState(false);
  const hasPermissionCreateProj = user.roleId === 1 || permissions.some((permission) => permission.key === 'task:board:create');
  const hasPermissionEditProj = user.roleId === 1 || permissions.some((permission) => permission.key === 'task:board:edit');
  
  const handleOpenEditBoard = (boardId) => {
    setEditedBoard(boardId)
    setEditBoard(true);
  }

  const handleCloseEditBoard = () => {
    setEditBoard(false);
    setEditedBoard(null)
  }

  const handleSetMode = (item) => {
    item === 'grid' ? setMode('grid') : setMode('table')
  }

  const fetchBoards = async () => {
    try {
      setLoading(true);
      const response = await api.get("/tasks/boards");
      if (response.data && response.data.boards) {
        // console.log(response.data, 'te')
        setBoards(response.data.boards.boards);
        setCounts(response.data.boards.counts)
      }
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };



  const handleCreateBoard = () => {
    setCreateBoard(true);
  };

  const handleCloseCreateBoard = () => {
    setCreateBoard(false);
    fetchBoards();
  }


  const handleAllSelected = () => {
    !allSelected && checkAll();
    allSelected && uncheckAll();
    setAllSelected(!allSelected);
  }


  useEffect(() => {
    fetchBoards();
  }, [modeTheme]);

  useEffect(() => {
    const tasksEvent = data => {
      if (data.action === 'create') {
        fetchBoards();
      }

      if (data.action === "update") {
        fetchBoards();
      }

      if (data.action === "delete") {
        fetchBoards();
      }
    };

    socket.on("taskboards", tasksEvent);

    return () => {
      socket.off("taskboards", tasksEvent);
    };
  }, []);


  return (
    <>
      {editBoard && editedBoard &&
        <TaskBoardAddModal
          open={editBoard}
          boardId={editedBoard}
          onClose={handleCloseEditBoard}
        />}
      {createBoard &&
        <TaskBoardAddModal
          open={createBoard}
          onClose={handleCloseCreateBoard}
        />}
      <MainContainer>
        <Paper
          className={classes.mainPaper}
          variant="outlined"
        //  onScroll={handleScroll}
        >
          <MainHeader className={classes.mainHeaderBlock} style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
              <Title><FaTasks size={24} /> {i18n.t("Tarefas")}</Title>

              <div style={{ display: 'flex', gap: 10 }}>
                {openFilter &&
                  <Button
                    variant="outlined"
                    color="success"
                    className={classes.buttonsTicket}
                    onClick={handleAllSelected}
                    style={{ maxWidth: 200 }}
                  >
                    {!allSelected ?
                      <>
                        {i18n.t("Selecionar Todos")}
                      </> :
                      <>
                        <RemoveCircleOutline style={{ fontSize: 13 }} /> {i18n.t("Deselecionar Todos")}
                      </>
                    }
                  </Button>
                }
                {/*<Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenFilter(!openFilter)}
                  className={classes.buttonsTicket}
                  style={{ fontSize: '12px' }}
                >
                  {!openFilter ? <><AddCircleOutline style={{ fontSize: 13 }} />   {i18n.t("Abrir filtro")}</>
                    : <><RemoveCircleOutline style={{ fontSize: 13 }} />   {i18n.t("Fechar filtro")}</>
                  }
                </Button>*/}


              </div>
            </div>
          </MainHeader>
          <div className={classes.mainContainer}>
            <Paper
              className={classes.mainPaperTable}
              variant="outlined"
            >
              <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={12}>
                  <Paper className={classes.paperInfo}>
                    <Grid item xs={12} sm={2} md={2}>
                      <DashboardInfoBox className={classes.paper} loading={loading} color="#F61C05"
                        title={i18n.t("Projetos Total")} value={counts.totalProjects ? counts.totalProjects : 0} icon={<Timer fontSize="inherit" />} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <DashboardInfoBox className={classes.paper} loading={loading} color="#F1C40F"
                        title={i18n.t("Tarefas Total")} value={counts.totalTasks ? counts.totalTasks : 0} icon={<FaTasks fontSize="inherit" />} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <DashboardInfoBox className={classes.paper} loading={loading} color="#009FDD"
                        title={i18n.t("Tarefas Atribuidas")} value={counts.totalAtrTasks ? counts.totalAtrTasks : 0} icon={<Group fontSize="inherit" />} />

                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <DashboardInfoBox className={classes.paper} loading={loading} color="#2ECC71"
                        title={i18n.t("Tarefas Completadas")} value={counts.totalCompletedTasks ? counts.totalCompletedTasks : 0} icon={<DoneAll fontSize="inherit" />} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <DashboardInfoBox
                        className={classes.paper}
                        loading={loading}
                        color="#9B59B6"
                        title={i18n.t("Tarefas Atrasadas")}
                        value={counts.totalLateTasks ? counts.totalLateTasks : 0}
                        icon={<FaClock fontSize="inherit" />} />
                    </Grid>

                  </Paper>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              className={classes.mainPaperTable}
              variant="outlined"
              style={{ display: 'flex', alignItems: 'center', padding: '10px 15px', justifyContent: 'space-between' }}>
              <div>
                <h3 style={{ marginBottom: 0, paddingBottom: 0 }}>Novidades</h3>
                <small>Agora você pode organizar o seu dia com a nossa ferramenta de produtividade</small>
              </div>
              <div style={{display: 'flex', gap: 5}}>
                {hasPermissionCreateProj && 
                <Button variant="outlined" color="success" onClick={handleCreateBoard}>
                  Crie novo Projeto
                </Button>}

                <ButtonGroup variant="outlined" aria-label="Basic button group">
                  <Button variant={mode === 'table' ? 'contained' : 'outlined'} onClick={() => handleSetMode('table')}><FaTable /></Button>
                  <Button variant={mode === 'grid' ? 'contained' : 'outlined'} onClick={() => handleSetMode('grid')}><LuLayoutGrid /></Button>
                </ButtonGroup>
              </div>
            </Paper>



            <Paper
              className={classes.mainPaperTable}
              variant="outlined"
            //   onScroll={handleScroll}
            >

              {mode === 'grid' ?
                <Box className={classes.boxCards}>
                  {boards.map((board) => (
                    <Grid item xs={12} sm={6} md={4} key={board.id}>
                      <TaskBoard
                        boardId={board.id}
                        title={board.title}
                        content={board.content}
                        userId={board.userId}
                        userInfo={board.user}
                        queueInfo={board.queue}
                        queueId={board.queueId}
                        onClose={fetchBoards}
                        totalTasks={board.totalTasks}
                        totalCompletedTasks={board.totalCompletedTasks}
                        hasPermissionEdit={hasPermissionEditProj}
                      />
                    </Grid>
                  ))}
                  {hasPermissionCreateProj && 
                  <Grid item xs={12} sm={6} md={4} key={'new'} onClick={handleCreateBoard}>
                    <div
                      style={{
                        border: '2px dashed #e0e0e0',
                        borderRadius: '8px',
                        padding: '16px',
                        width: '300px',
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                        minHeight: 230,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Iniciar um novo projeto
                    </div>
                  </Grid>}
                </Box> :

                <TableContainer>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Título</TableCell>
                        <TableCell align="center">Descrição</TableCell>
                        <TableCell align="center">Progresso</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {boards.map((row, key) => (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell align="center">{row.content}</TableCell>
                          <TableCell align="right">
                            <LinearProgress variant="determinate" value={row.totalTasks > 0 ? (row.totalCompletedTasks / row.totalTasks) * 100 : 0} sx={{ height: '8px', borderRadius: '4px', marginBottom: '16px' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}><FaTasks /> {row.totalTasks} tarefas</span>
                              <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}><FaCheck color='#2ecc71' /> {row.totalCompletedTasks} concluída(s)</span>
                            </div>

                          </TableCell>

                          <TableCell align="center">
                            {(row.userId || row.queueId) &&
                              (row.user && row.user.name ?
                                <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
                                  {row.user && row.user.avatar ?
                                    <><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${row.user.avatar}`} style={{ width: 24, height: 24 }} /></>
                                    : <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${row.user.name}`} style={{ width: 24, height: 24 }} />
                                  }
                                  <Typography variant="body2" color="textSecondary" sx={{ marginLeft: '8px' }}>
                                    {row.user.name}
                                  </Typography>
                                </Box> :
                                row.queue &&
                                <Typography variant="body2" color="textSecondary" style={{ marginTop: 10 }}>
                                  Setor: {row.queue.name}
                                </Typography>)
                            }
                          </TableCell>
                          <TableCell align="center">
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>

                              <Button variant='contained' color="success" size='small'
                                style={{ display: 'flex', alignItems: 'center', gap: 2 }}
                                onClick={() => history.push(`tasks/${row.id}`)}
                              >
                                <FaEye size={9} /> Visualizar
                              </Button>
                              {hasPermissionEditProj &&
                              <Button variant='contained' color="warning" onClick={() => handleOpenEditBoard(row.id)} size='small'
                                style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <FaPencilAlt size={9} /> Editar
                              </Button>}
                            </div>

                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>}
            </Paper>

          </div>
        </Paper>
      </MainContainer>
      <AiAssistant section="boardTasks" />
    </>
  );
};

export default Board;
