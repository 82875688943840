import React, { useState } from 'react';
import { Avatar, AvatarGroup, Tooltip, Popover, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

const UserAvatarGroup = ({ userInfo }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    // Handle opening the Popover
    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Handle closing the Popover
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    // Calculate the extra users
    const maxAvatars = 4;
    const extraUsers = userInfo.slice(3);
    const displayedUsers = userInfo.slice(0, maxAvatars);

    return (
        <div>
            <AvatarGroup max={maxAvatars}
                componentsProps={{
                    additionalAvatar: {
                        sx: {
                            width: 32,
                            height: 32,
                            fontSize: 12
                        }
                    }
                }}
                renderSurplus={(surplus) => <span onClick={handleOpenPopover}>+{surplus.toString()}</span>}
            >
                {displayedUsers.map((item, index) => (
                    (item?.avatar || item?.name) && (
                        <Tooltip key={index} title={item.name}>
                            {item.avatar ? (
                                <Avatar
                                    src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${item.avatar}`}
                                    style={{ width: 32, height: 32 }}
                                />
                            ) : (
                                <Avatar
                                    src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${item.name}`}
                                    style={{ width: 32, height: 32 }}
                                />
                            )}
                        </Tooltip>
                    )
                ))}

                {/* Add an extra avatar for the "+X" */}
                {extraUsers.length > 0 && (
                    <Avatar
                        style={{ width: 32, height: 32, cursor: 'pointer', fontSize: 12 }}

                    >
                        {extraUsers.length}
                    </Avatar>
                )}
            </AvatarGroup>

            {/* Popover for showing extra users */}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List>
                    {extraUsers.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                {item.avatar ? (
                                    <Avatar
                                        src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${item.avatar}`}
                                    />
                                ) : (
                                    <Avatar
                                        src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${item.name}`}
                                    />
                                )}
                            </ListItemAvatar>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </div>
    );
};

export default UserAvatarGroup;