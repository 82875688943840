import React, { useState, useEffect, useRef} from "react";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { i18n } from "../../translate/i18n";
import { FaCalendar, FaEnvelope, FaTags, FaUser } from "react-icons/fa6";
import { FaPlayCircle, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";




const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	flexButtonsTags: {
		display: "flex",
		flexDirection: 'column',
		gap: 3,
		padding: '0px 10px 15px 15px'
	},
	tag: {
		padding: '5px 15px',
		borderRadius: 5
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));


const LogInfoModal = ({ open, onClose, log, type }) => {
	const isMounted = useRef(true);
    const history = useHistory();
	const classes = useStyles();
	const [logContent, setLogContent] = useState({});

	useEffect(() => {
		setLogContent(log)
		//console.log(log, 'passed in here')
		return () => {
			isMounted.current = false;
		};
	}, [open, log]);

	const handleClose = () => {
		onClose();
	};

	const goToTicket = () => {
		history.push(`/tickets/${log.ticketId}`);
	}


	//Tags functions
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{type === 'tickets' ? `Log de Atendimento #${log.ticketId}` : logContent?.action?.action}
				</DialogTitle>
				<Box p={2}>
					{logContent && (
						<Box className={classes.flexButtonsTags} mb={2}>
							{logContent && logContent.user && (
								<Typography variant="body2">
									<IconButton
										aria-label="account of current user"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										color="inherit"
										size="large">
										<>
											{logContent.user && logContent.user?.avatar ?
												<><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${logContent.user.avatar}`} /></>
												: logContent.user?.name ?
													<Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${logContent.user.name}`} /> :
													''
											}
										</>
									</IconButton>
									{logContent.user?.name ? logContent.user?.name : 'Anônimo'}

								</Typography>)}
							<Typography variant="body1">{logContent.desc}</Typography>

							{type === 'tickets' && log.ticketId &&
								<CardHeader
									onClick={() => goToTicket()}
									style={{ cursor: "pointer" }}
									titleTypographyProps={{ noWrap: true }}
									subheaderTypographyProps={{ noWrap: true }}
									avatar={<Avatar src={log.contact?.profilePicUrl} alt="contact_image" />}
									title={`${log.contact?.name || '(sem contato)'} #${log.ticketId}`}
								/>
							}
						</Box>
					)}
					<Box mt={2} p={2} border={1} gap={15} borderColor="grey.300" borderRadius={4}
						style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
						<Typography variant="h7" style={{ fontWeight: 'bold' }}>Detalhes do Log</Typography>
						<Typography variant="body2">
							<strong>ID:</strong> {logContent?.id}
						</Typography>
						<Typography variant="body2">
							<strong><FaCalendar color="#a6a6a6" /> Criado em: </strong> {new Date(logContent.createdAt).toLocaleString()}
						</Typography>


						<Typography variant="body2">
							<strong><FaEnvelope color="#a6a6a6" /> Email: </strong> ({logContent.user?.email})
						</Typography>
						<Typography variant="body2">
							<strong><FaPlayCircle color="#a6a6a6" /> Ação:</strong> {type === 'tickets' ? logContent.desc : logContent?.action?.action}
						</Typography>

						{logContent && logContent.tag && (
							<Typography variant="body2">
								<strong><FaTags color="#a6a6a6" /> Tag:</strong> {logContent.tag?.name}
							</Typography>)}
						{logContent && logContent.additional && (
							<Typography variant="body2">
								<strong><FaPlus color="#a6a6a6" /></strong> {logContent.additional}
							</Typography>
						)}

						{/*<Typography variant="body2">
                            <strong>Section:</strong> {logContent.action?.section}
                        </Typography>*/}
					</Box>
				</Box>
			</Dialog>
		</div >
	);
};

export default LogInfoModal;
