
import { useParams } from "react-router-dom";
import FormTask from "../../components/Tasks/FormTask";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import { Paper, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FaArrowLeft } from "react-icons/fa";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';
import AiAssistant from '../../components/AiAssistant';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        background: theme.palette.fancyBackground,
        width: '100%',
        padding: '0px 25px !important',
    },
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        [theme.breakpoints.down('md')]: {
            marginTop: 0
        },
        ...theme.scrollbarStylesSoftBig
    }

}))

const Task = () => {
    const classes = useStyles();
    const { taskId } = useParams();
    const history = useHistory();


    return (
        <div style={{width: '100%', padding: '0px 24px'}}>

            <Paper
                className={classes.mainPaper}
                variant="outlined"
            //  onScroll={handleScroll}
            >
                <MainHeader className={classes.mainHeaderBlock} style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', gap: 10 }}>
                        <Button variant="outlined" onClick={() => history.goBack()} style={{ marginTop: 5 }}>
                            <FaArrowLeft size={14} /> {i18n.t("Voltar")}
                        </Button>
                    </div>
                </MainHeader>
                <FormTask taskId={taskId} />
            </Paper>
            <AiAssistant section="view-task" id={taskId} />
        </div>
    );
};

export default Task;
