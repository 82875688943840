import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useField } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import makeStyles from '@mui/styles/makeStyles';
import { Button, useTheme } from '@mui/material';
import { GiBrain } from 'react-icons/gi';
import PromptChatAIModal from "../PromptTaskAIModal";
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
    AddMargin: {
        marginTop: 10,
        marginBottom: 10,
        paddingLeft: 0,
        marginLeft: 0
    },
}))


const QuillEditorField = ({ label, field, form, taskId, placeholder, theme, ...props }) => {
    const classes = useStyles();
    const themeApp = useTheme();
    const modeTheme = themeApp.mode;
    const [promptAIModalOpen, setPromptAIModalOpen] = useState(false);
    const quillRef = useRef(null);
    const apiURL = process.env.REACT_APP_BACKEND_URL;
    const [imageUrl, setImageUrl] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    //const { setValues } = form;

    const handleChange = (value) => {
        //setValues({ ...form, [field.name]: value });
        form.setFieldValue(field.name, value);
    };

    useEffect(() => {
        const editorElement = quillRef.current?.editor?.root;
        if (editorElement) {
            const handleClick = (event) => {
                const target = event.target;
                if (target.tagName === 'A') {
                    const linkHref = target.getAttribute('href');
                    if (linkHref && linkHref.includes('your-image-identifier')) {
                        event.preventDefault();
                        setImageUrl(linkHref);
                        setModalOpen(true);
                    }
                }
            };
            editorElement.addEventListener('click', handleClick);
            return () => editorElement.removeEventListener('click', handleClick);
        }
    }, []);

    const handleImageInserted = async (file) => {
        try {
            // const imageUrl = await handleFsImageUpload(path, file);  // image upload function
            // const imageUrl = await api.post('/task/upload/image');
            //console.log(props.taskId, taskId, 'taskId, here')
            const formData = new FormData();
            formData.append('media', file);
            taskId && formData.append('taskId', taskId);
            const response = await api.post('/task/upload/image', formData);

            // console.log(response, 'test response')
            const imageUrl = response.data?.file || null;
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            imageUrl && quill.insertText(range.index, imageUrl, 'link', `${apiURL}/public/${imageUrl}`);
        } catch (error) {
            console.error("Error handling image insertion:", error);
        }
    };

    const openImageFileDialog = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                handleImageInserted(file);
            }
        };
        input.click();
    };


    const handleAIGenerate = () => {
        setPromptAIModalOpen(!promptAIModalOpen)
    }

    const handleAIMessage = (message) => {
        if (message) {
            handleChange(message)
        }
    }

    const quillModules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    ['bold', 'italic', 'underline', 'blockquote'],
                    ['link', 'image', 'video'],
                    ['clean'],
                ],
                handlers: {
                    image: openImageFileDialog,
                },
            },
        }),
        []
    );

    return (
        <>
            <PromptChatAIModal
                open={promptAIModalOpen}
                onClose={() => setPromptAIModalOpen(false)}
                message={field.value}
                fromTicket={true}
                //contact={contact}
                handleAIMessage={handleAIMessage}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {label && <div style={{ display: 'flex', width: '100%', marginBottom: 5, justifyContent: 'space-between', alignItems: 'center' }}>
                    <label className={classes.label}>{label}</label>
                    <Button size="small" variant="outlined" style={{ gap: 5 }} onClick={handleAIGenerate}><GiBrain /> GERAR COM IA</Button>                </div>}
                <ReactQuill
                    value={field.value}
                    ref={quillRef}
                    onChange={handleChange}
                    theme={theme === "bubble" ? "bubble" : "snow"}
                    modules={quillModules}
                    className={`${classes.textareaField} ${modeTheme === 'dark' ? 'quill-dark' : 'block-text'}`}
                    placeholder={placeholder}
                    {...props}

                />
                {form.touched[field.name] && form.errors[field.name] ? (
                    <div className="error">{form.errors[field.name]}</div>
                ) : null}
            </div>
        </>
    );
};

export default QuillEditorField;