import React, { useContext, useEffect, useRef, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { GoSortAsc, GoSortDesc } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useParams } from "react-router-dom";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { FaEye, FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import { Can } from "../Can";
import Select from "@mui/material/Select";
import { IconButton, useTheme } from "@mui/material";
import AsyncSelect from "../AsyncSelect";
import SelectTags from "../SelectTags";
import SelectStatus from "../SelectStatus";
import FormControl from "@mui/material/FormControl";
import { HiOutlineUsers } from "react-icons/hi2"
import {
    Checkbox,
    ListItemText,
    Switch,
    FormControlLabel,
    InputBase,
    MenuItem,
    Box,
    Typography,
    List,
    ListItem,
    Button,
    Chip,
    Grid,
    Tooltip,
    OutlinedInput,
    InputLabel
} from "@mui/material";
import { useTicketCounts } from '../../context/Tickets/Count';
import { i18n } from "../../translate/i18n";
import chroma from "chroma-js";
import NewTicketModal from "../NewTicketModal";

const useStyles = makeStyles(theme => ({
    ticketsWrapperRadius: {
        position: "relative",
        display: "flex",
        height: "85vh",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 20,
        background: theme.palette.inputBackground,
        border: 0,
    },
    headH6: {
        padding: '5px 20px',
        fontSize: 18,
        fontWeight: 'bold',
        justifyContent: 'space-between'
    },
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        border: 0,
    },
    gridDisabled: {
        backgroundColor: '#f5f5f5',
        position: 'relative',
    },
    gridDisabledOverlay: {
        content: ' ',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 9,
    },
    tabsHeader: {
        flex: "none",
    },

    tabsInternal: {
        flex: "none",
        backgroundColor: theme.palette.tabHeaderBackground
    },

    settingsIcon: {
        alignSelf: "center",
        marginLeft: "auto",
        padding: 8,
    },

    tab: {
        minWidth: 90,
        width: 90,
        textTransform: 'capitalize',
    },

    internalTab: {
        minHeight: 70,
        minWidth: 120,
        width: 120,
        padding: 5,
        borderRight: '1px solid #f9f9f9',
        color: theme.palette.mode === 'dark' ? '#fff' : '#696969'
    },
    resetButton: {
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: '#ffffff'
    },
    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.palette.optionsBackground,
        boxShadow: 'inset 0px 0px 26px 0px rgba(0,0,0,0.05)',
        padding: theme.spacing(1),
    },

    ticketSearchLine: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.mode === 'dark' ? '#000000' : "#FFFFFF",
        borderRadius: 25,
        position: 'absolute',
        maxWidth: 600,
        margin: '10px auto',
        padding: 20,
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        boxShadow: 'inset 0px -5px 0px 2px rgba(0,0,0,0.08)',
        // boxShadow: '0px 0px 37px 0px rgba(0,0,0,0.24)'
    },


    searchInputHeader: {
        display: "flex",
        position: "relative",
        justifyContent: "center",
        borderRadius: 5,
        border: "1px solid",
        fontFamily: "Poppins, sans-serif",
        alignItems: "center",
        fontSize: 12,
        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.40)',
        width: 35,
        height: 35,
        borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.30)' : 'rgba(0, 0, 0, 0.10)',
        '&:hover': {
            borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000000',
        },
    },

    refreshIcon: {
        marginTop: 5,
        marginRight: 5,
        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.54)',
    },

    searchInputWrapper: {
        flex: 18,
        background: theme.palette.total,
        display: "flex",
        borderRadius: 40,
        padding: 4,
        height: 40,
        alignItems: "center",
    },

    searchToggleShow: {
        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.54)',
    },

    textSearch: {
        marginRight: 10,
    },

    searchToggleIcon: {
        marginTop: 5
    },

    searchIcon: {
        color: theme.palette.primary.main,
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },

    searchInput: {
        flex: 1,
        border: "none",
        borderRadius: 30,
    },

    insiderTabPanel: {
        height: '89%',
        marginTop: "0px",
        paddingTop: "0px"
    },

    insiderDoubleTabPanel: {
        display: "flex",
        flexDirection: "column",
        marginTop: "-72px",
        paddingTop: "72px",
        height: "100%"
    },

    labelContainer: {
        width: "auto",
        padding: 0
    },
    iconLabelWrapper: {
        flexDirection: "row",
        '& > *:first-child': {
            marginBottom: '3px !important',
            marginRight: 16
        }
    },
    insiderTabLabel: {
        [theme.breakpoints.down(1600)]: {
            display: 'none'
        }
    },
    smallFormControl: {
        '& .MuiOutlinedInput-input': {
            padding: "9.5px",
        },
        '& .MuiInputLabel-outlined': {
            marginTop: "-6px"
        },
    },
    FormControl: {
        border: '1px solid #bdbdbd',
        borderRadius: 4,
        padding: "5px 10px",
        width: '100%'
    },
    boxGridTickets: {
        borderRadius: 20,
        ...theme.scrollbarStylesSoft
    },
    paperBoxes: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    boxMinimized: {
        height: 40,
        overflow: 'hidden'
    },
    boxColumnButton: {
        background: 'transparent',
        fontSize: 12
    },
    buttonFilter: {
        marginTop: 7,
    },
    buttonAdd: {
        minWidth: 200,
        borderRadius: 5,
        border: theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : "1px solid rgba(0,0,0,0.1)",
        fontWeight: 'bold',
        background: 'transparent',
        minHeight: 35
    },
    label: {
        display: 'flex',
        alignItems: 'center'
    },
    smallTitle: {
        fontWeight: 'bold',
        fontSize: 12,
        textTransform: 'upppercase',
        color: theme.palette.text.main,
        paddingBottom: 5,
        borderBottom: theme.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.05)',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 5,
        paddingBottom: 5,
        cursor: 'pointer'
    },
    advancedFilters: {
        display: 'none',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '5px',
        padding: '15px',
        /* border: 1px solid rgba(255, 255, 255, 0.1); */
        paddingTop: '15px !important',
        borderRadius: '10px',
        background: theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.01)' : 'rgba(0,0,0,0.05)',
        boxShadow: 'inset 0px 5px 15px 0px rgba(0,0,0,0.1))'
    },
    visible: {
        display: 'flex !important',
    },
    hidden: {
        display: 'none !important',
    },
    OneMargin: {
        marginBottom: 20
    },
    buttonSuccess: {
        background: "#2ecc71",
        boxShadow: 'none', 
        borderRadius: 10, 
        border: 0,
        color: '#000', 
        gap: 3, 
        fontWeight: 'bold', 
        height: 30,
        transition: 'all ease 0.5s',
        '&:hover': {
            background: "#2ecc71", 
            color: '#000', 
            transform: 'scale(1.05)'
        }
    }
}));

const TicketFilters = ({
    allStatus,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    searchById,
    setSearchByTicketId,
    selectedQueueIds,
    setSelectedQueueIds,
    searchParam,
    setSearchParam,
    userIds,
    setUserIds,
    selectedStatuses,
    setSelectedStatuses,
    showAllTickets,
    setShowAllTickets,
    sortAscending,
    setSortAscending,
    selectModeList,
    setSelectModeList,
    forceSearch,
    setForceSearch,
    handleAddBlock,
    handleClean
}) => {
    const classes = useStyles();
    const { user, permissions } = useContext(AuthContext);
    const [tagIds, setTagIds] = useState([]);
    const columns = {
        NoBot: [],
        [i18n.t("columnName.pending")]: [],
        [i18n.t("columnName.open")]: [],
        [i18n.t("columnName.paused")]: [],
        [i18n.t("columnName.closed")]: [],
        [i18n.t("columnName.group")]: [],
    };

    const [showSelected, setShowSelected] = useState(null);
    const [gridOpened, setOpened] = useState({
        [i18n.t("columnName.pending")]: true,
        [i18n.t("columnName.paused")]: true,
        [i18n.t("columnName.closed")]: true,
        [i18n.t("columnName.open")]: true,
        [i18n.t("columnName.group")]: true,
        'NoBot': true,
    });
    const { countTickets } = useTicketCounts();
    const [searchOnMessages, setSearchOnMessages] = useState(false);
    const [disableControls, setDisableControls] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [connectionIds, setConnectionIds] = useState([]);
    const [isSearched, setisSearched] = useState(false);
    const [internalSelectedQueueIds, setInternalSelectedQueueIds] = useState([]);
    const [selectedUnreadMessageOption, setSelectedUnreadMessage] = useState('');
    const [unreadMessagesSearchMe, setUnreadMessagesSearchMe] = useState(false);
    const [unreadMessagesSearchClient, setUnreadMessagesSearchClient] = useState(false);
    const [lastClientActivityMe, setLastClientActivityMe] = useState(null);
    const [lastClientActivityClient, setLastClientActivityClient] = useState(null);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const inputRefDateFrom = useRef();
    const inputRefDateTo = useRef();
    const themeApp = useTheme();
    const modeTheme = themeApp.mode;
    const [listQueues, setListQueues] = useState({});
    const [listTags, setListTags] = useState({});
    const [listConnections, setListConnections] = useState({});
    const [listUsers, setListUsers] = useState({});
    const { term } = useParams();
    const history = useHistory();
    let searchTimeoutTicketId;
    const hasPermissionAddColumn = user.roleId === 1 || permissions.some((permission) => permission.key === 'tickets:kanban:add');
    const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false);
    

    const handleOpenAdvancedFilters = () => {
        setOpenAdvancedFilters(prevState => !prevState);
    }


    const handleSearch = () => {
        setSelectedQueueIds(internalSelectedQueueIds);
        setForceSearch(!forceSearch);
        setShowSearchBar(false);
        setisSearched(true);
    };



    const handleCheckboxChange = (value) => {
        if (selectModeList.includes(value)) {
            setSelectModeList(selectModeList.filter(item => item !== value));
        } else {
            setSelectModeList([...selectModeList, value]);
        }
    };

    const handleUnanswered = (event) => {
        const target = event.target.value;
        setSelectedUnreadMessage(target);
        switch (target) {
            case 'allMe':
                setUnreadMessagesSearchMe(true);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
                break;
            case 'allClient':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(true);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
                break;
            case 'customClient':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(true);
                setLastClientActivityMe(null);
                break;
            case 'customMe':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(true);
                break;
            default:
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
        }
    };

    const handleSortAscending = () => {
        setSortAscending(!sortAscending)
        localStorage.setItem('sortAscending', !sortAscending)
    }

    const handleChangeModeList = async (data) => {
        setSelectModeList(data);
        window.localStorage.setItem('selectedModeList', JSON.stringify(data));
    };

    const handleChangeTicketId = e => {
        const searchedId = e.target.value;
        clearTimeout(searchTimeoutTicketId);
        if (searchedId === "") {
            setSearchByTicketId(searchedId);
            return;
        }
        searchTimeoutTicketId = setTimeout(() => {
            setSearchByTicketId(searchedId);
        }, 500);
    };


    useEffect(() => {
        const showAllTickets = JSON.parse(window.localStorage.getItem('showAllTickets'));
        const sortAscendingItem = JSON.parse(window.localStorage.getItem('sortAscending'));
        if (showAllTickets) {
            setShowAllTickets(showAllTickets);
        }
        if (sortAscendingItem) {
            setSortAscending(sortAscendingItem);
        }

        //const hasViewAllPermission = permissions.some((permission) => permission.key === "tickets:view:all");
        //const hasBotsOptionsClearPermission = permissions.some((permission) => permission.key === "bots:options:clear");
        //const searchAllTickets = user.roleId === 1 || isActive('ignoreUserConnectionForGroups');
        //setShowAllSearch(user.roleId === 1 || hasViewAllPermission);
        //setShowBotsOptions(user.roleId === 1 || hasBotsOptionsClearPermission);
    }, []);

    const handleShowSearchBar = () => {
        setShowSearchBar(!showSearchBar);

    }




    return (
        <>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={e => setNewTicketModalOpen(false)}
            />

            <Box
                style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20, paddingTop: 5 }}
                className="filter-kanban"
            >

                <Can
                    role={user.roleId}
                    perform={"tickets:create"}
                    yes={() => (
                        <Button
                            variant="contained"
                            size="small"
                            className={`${classes.buttonSuccess} ripple-ticket`}
                            onClick={() => setNewTicketModalOpen(true)}
                        >
                            <FaPlus size={12} /> {i18n.t("ticketsManager.buttons.newTicket")}
                        </Button>
                    )}
                    no={() => <></>}
                />



                <Box className={`${classes.searchInputHeader} searchInputHeader`} onClick={handleShowSearchBar}>
                    {/*<SearchIcon />
                    <div className={classes.textSearch}>{i18n.t("search.search")}</div>*/}
                    <div className={classes.searchToggleShow}>
                        {showSearchBar ? (
                            <ArrowUpward className={classes.searchToggleIcon} />
                        ) : (
                            <ArrowDownward className={classes.searchToggleIcon} />
                        )}
                    </div>
                    {/*<div>
                        <RefreshIcon onClick={(e) => { e.stopPropagation(); handleClean(); }} className={classes.refreshIcon} />
                    </div>*/}
                </Box>

                {hasPermissionAddColumn &&
                    <button className={`${classes.buttonAdd} buttonAdd`}
                        style={{ color: modeTheme === 'dark' ? '#fff' : '#000', fontWeight: 'bold' }}
                        onClick={handleAddBlock}
                    >+ Adicionar Coluna
                    </button>}
            </Box>

            {
                <Grid container spacing={1} className={`${classes.ticketSearchLine} search-block`}
                    style={{ display: showSearchBar ? 'flex' : 'none' }}>

                    <IconButton style={{ fontSize: 14, position: 'absolute', right: 10, top: 10 }}
                        onClick={(e) => { e.stopPropagation(); setShowSearchBar(false); }}
                    >
                        <FaTimes />
                    </IconButton>

                    <Grid container spacing={1} alignItems={'center'} style={{ justifyContent: 'center' }}>

                        <Grid item xs="12" md="12" style={{ justifyContent: 'center', gap: 20, display: 'flex' }}>
                            <Tooltip placement="bottom" title={i18n.t("tickets.buttons.sortAscending")}>
                                <FormControlLabel
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    label={
                                        sortAscending ?
                                            <GoSortDesc size={28} color={sortAscending ? '#2ecc71' : '#ddd'} /> :
                                            <GoSortAsc size={28} color={sortAscending ? '#2ecc71' : '#ddd'} />
                                    }
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            checked={sortAscending}
                                            onChange={() => handleSortAscending()}
                                            name="sortAscending"
                                            color="primary"
                                        />
                                    }
                                />
                            </Tooltip>
                            <Can
                                role={user.roleId}
                                perform="tickets:view:all"
                                yes={() => (
                                    <FormControlLabel
                                        label={<HiOutlineUsers size={28} color={showAllTickets ? '#2ecc71' : '#ddd'} />}
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                size="small"
                                                checked={showAllTickets}
                                                onChange={() =>
                                                    setShowAllTickets(prevState => !prevState)
                                                }
                                                name="showAllTickets"
                                                label="Ver todos atendimentos"
                                                color="success"
                                            />
                                        }
                                    />)}
                                no={() => <></>}
                            />


                            <FormControl variant="outlined">
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectModeList}
                                    size="small"
                                    style={{ height: 35, borderRadius: 20 }}
                                    onChange={(data) => handleChangeModeList(data.target.value)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    renderValue={() => {
                                        if (!showSelected || !selectModeList || selectModeList.length === 0) {
                                            return <label className={classes.label}><FaEye style={{ paddingTop: 5 }} /> {/*<span>Colunas</span>*/}</label>;
                                        } else {
                                            return null;
                                        }
                                    }}
                                >

                                    {allStatus && allStatus.map((status, index) => (
                                        status.id === ('bot' || 'pending') ?
                                            <Can
                                                key={index}
                                                role={user.roleId}
                                                perform={status === 'bot' ? 'bots:show:page:users' : 'tickets:pending:all'}
                                                yes={() => (
                                                    <MenuItem key={status.id} value={status.id}>
                                                        <Checkbox
                                                            size="small"
                                                            color="primary"
                                                            checked={selectModeList.includes(status.id)}
                                                            onChange={() => handleCheckboxChange(status.id)}
                                                        />
                                                        <ListItemText primary={status.title} />
                                                    </MenuItem>
                                                )}
                                                no={() => null}
                                            /> :

                                            <MenuItem key={status.id} value={status.id}>
                                                <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    checked={selectModeList.includes(status.id)}
                                                    onChange={() => handleCheckboxChange(status.id)}
                                                />
                                                <ListItemText primary={status.title} />
                                            </MenuItem>


                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item xs="12" md="12">
                        </Grid>
                        {showAllTickets &&
                            <Grid item xs="6" md="6">
                                {isSearched && userIds && userIds.length > 0 ?
                                    <>
                                        <Chip
                                            label={userIds.length + i18n.t("tickets.search.selectedUsers")}
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                        <IconButton onClick={() => setUserIds(null)}>
                                            <FaTimes size={15} />
                                        </IconButton>
                                    </>
                                    : (
                                        <AsyncSelect
                                            defaultValue={userIds}
                                            listUsers={listUsers}
                                            setListUsers={setListUsers}
                                            url="/users"
                                            disabled={disableControls}
                                            isSearched={isSearched}
                                            small
                                            multiple={true} label={i18n.t("tickets.search.users")} dictKey="users" width="100%"
                                            onChange={(event, value) => { setUserIds(value.map((user) => user.id)) }}>
                                        </AsyncSelect>
                                    )}
                            </Grid>
                        }


                        <Grid item xs="6" md="6">
                            <TicketsQueueSelect
                                width={'100%'}
                                selectedQueueIds={selectedQueueIds}
                                userQueues={user?.queues}
                                onChange={values => setSelectedQueueIds(values)}
                                noMargin
                            />
                        </Grid>

                    </Grid>


                    <Grid item xs={12} md={12}>
                        <label className={classes.smallTitle} onClick={handleOpenAdvancedFilters}>
                            <span>FILTROS AVANÇADOS</span>

                            <IconButton style={{ fontSize: 14 }}>
                                {!openAdvancedFilters ? <ArrowDownward fontSize="17" /> : <ArrowUpward fontSize="17" />}
                            </IconButton>
                        </label>

                    </Grid>

                    <Grid item xs={12} md={12} className={`${classes.advancedFilters} ${openAdvancedFilters ? classes.visible : classes.hidden}`}>

                        <Grid item xs={12} md={10} className={classes.OneMargin}>
                            <InputBase
                                className={classes.FormControl}
                                placeholder={i18n.t("tickets.search.ticketId")}
                                defaultValue={searchById}
                                onChange={handleChangeTicketId}
                                InputProps={{ sx: { borderRadius: 0 } }}
                            />
                        </Grid>
                        {!disableControls && (
                            <>
                                <Grid item xs={12} md={5} className={classes.OneMargin}>
                                    {isSearched && tagIds && tagIds.length > 0 ?
                                        <Chip
                                            label={tagIds.length + i18n.t("tickets.search.selectedTags")}
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                        : (
                                            <SelectTags
                                                onChange={(value) => { setTagIds(value.map((tag) => tag.id)) }}
                                                defaultValue={tagIds}
                                                isSearched={isSearched}
                                                listTags={listTags}
                                                setListTags={setListTags}
                                                disabled={disableControls}
                                                outlined>
                                            </SelectTags>
                                        )}
                                </Grid>
                                <Grid item xs={12} md={5} className={classes.OneMargin}>
                                    {isSearched && connectionIds && connectionIds.length > 0 ?
                                        <Chip
                                            label={connectionIds.length + i18n.t("tickets.search.selectedConnections")}
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                        : (
                                            <AsyncSelect url="/whatsapp"
                                                isSearched={isSearched}
                                                listConnections={listConnections}
                                                setListConnections={setListConnections}
                                                disabled={disableControls}
                                                multiple={true} label={i18n.t("tickets.search.connections")} width="100%"
                                                dictKey="whatsapps"
                                                onChange={(event, value) => { setConnectionIds(value.map((connection) => connection.id)) }}>
                                            </AsyncSelect>
                                        )}
                                </Grid>
                                <Grid item xs={12} md={5} className={classes.OneMargin}>
                                    <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                        <InputLabel id="search-date-from-label">{i18n.t("tickets.search.from")}</InputLabel>
                                        <OutlinedInput
                                            label={i18n.t("tickets.search.from")}
                                            ref={inputRefDateFrom}
                                            onFocus={() => inputRefDateFrom.current.children[0].type = "date"}
                                            onBlur={() => inputRefDateFrom.current.children[0].type = "text"}
                                            type="text"
                                            placeholder={i18n.t("tickets.search.from")}
                                            disabled={disableControls}
                                            defaultValue={dateFrom}
                                            fullWidth
                                            onChange={(event) => { setDateFrom(event.target.value) }}></OutlinedInput>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={5} className={classes.OneMargin}>
                                    <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                        <InputLabel id="search-date-to-label">{i18n.t("tickets.search.to")}</InputLabel>
                                        <OutlinedInput
                                            label={i18n.t("tickets.search.to")}
                                            type="text"
                                            fullWidth
                                            ref={inputRefDateTo}
                                            disabled={disableControls}
                                            defaultValue={dateTo}
                                            onFocus={() => inputRefDateTo.current.children[0].type = "date"}
                                            onBlur={() => inputRefDateTo.current.children[0].type = "text"}
                                            placeholder={i18n.t("tickets.search.to")}
                                            onChange={(event) => { setDateTo(event.target.value) }}></OutlinedInput>
                                    </FormControl>
                                </Grid>
                                {/*<Grid item xs={12} md={4} style={{ marginTop: 5 }}>
                                    {isSearched && selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map((item, index) => (
                                        <Chip key={index}
                                            label={
                                                item === 'paused'
                                                    ? i18n.t("columnName.paused")
                                                    : item === 'open'
                                                        ? 'Atendimento'
                                                        : item === 'pending'
                                                            ? i18n.t("columnName.pending")
                                                            : i18n.t("columnName.closed")
                                            }
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                    )) :
                                        (
                                            <SelectStatus isSearched={isSearched} defaultValue={selectedStatuses} onChange={setSelectedStatuses} disabled={disableControls} outlined></SelectStatus>
                                        )}
                                        </Grid>*/}
                                <Grid item xs={12} md={6} style={{ marginTop: 5 }} className={classes.OneMargin}>
                                    {selectedUnreadMessageOption == 'customClient' || selectedUnreadMessageOption == 'customMe' ? (
                                        <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                            <InputLabel id="input-time-label">minutos</InputLabel>
                                            <OutlinedInput
                                                label="minutos"
                                                type="number"
                                                placeholder="   "
                                                disabled={disableControls}
                                                fullWidth
                                                onChange={(event) => {
                                                    selectedUnreadMessageOption == 'customClient' ?
                                                        setLastClientActivityClient(event.target.value) : setLastClientActivityMe(event.target.value)
                                                }}
                                            />
                                            <Button size="small" className={classes.resetButton} onClick={() => setSelectedUnreadMessage('')}>Resetar</Button>
                                        </FormControl>
                                    ) : (
                                        <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                            <InputLabel id="select-option-label">{i18n.t("tickets.search.unanswered")}</InputLabel>
                                            <Select
                                                labelId="select-option-label"
                                                value={selectedUnreadMessageOption}
                                                onChange={handleUnanswered}
                                                defaultValue={selectedUnreadMessageOption}
                                                disabled={disableControls}
                                                label={i18n.t("tickets.search.unanswered")}
                                            >
                                                <MenuItem value="">{i18n.t("tickets.search.unansweredDisabled")}</MenuItem>
                                                <MenuItem value="allClient">{i18n.t("tickets.search.unansweredClient")}</MenuItem>
                                                <MenuItem value="allMe">{i18n.t("tickets.search.unansweredMe")}</MenuItem>
                                                <MenuItem value="customClient">{i18n.t("tickets.search.unansweredTimeClient")}</MenuItem>
                                                <MenuItem value="customMe">{i18n.t("tickets.search.unansweredTimeMe")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>


                        <Grid item xs={6} md={3} className={classes.buttonFilter}>
                            <Button style={{ textAlign: 'left', alignItems: 'flex-start' }}
                                variant="text"
                                size="small"
                                onClick={handleClean}
                                fullWidth
                            >{i18n.t("tickets.buttons.clear")} pesquisa</Button>
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.buttonFilter}>
                            <Button variant="contained" className={`${classes.buttonSuccess} ripple-ticket`}
                                onClick={handleSearch} fullWidth>{i18n.t("tickets.buttons.search")}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default TicketFilters;